import { LegacyCard, TextField, Page } from '@shopify/polaris';
import { useState, useCallback } from 'react';

function WithHelpText() {
    const [textFieldValue, setTextFieldValue] = useState(
        'bernadette.lapresse@jadedpixel.com',
    );

    const handleTextFieldChange = useCallback(
        (value: string) => setTextFieldValue(value),
        [],
    );

    return (
        <Page>
            <LegacyCard sectioned>
                <TextField
                    label="Account email"
                    type="email"
                    value={textFieldValue}
                    onChange={handleTextFieldChange}
                    helpText="We’ll use this address if we need to contact you about your account."
                    autoComplete="email"
                />
            </LegacyCard>
        </Page>

    );
}

export default WithHelpText