import {Checkbox, LegacyCard, Page} from '@shopify/polaris';
import {useState, useCallback} from 'react';

function CheckboxDefault () {
  const [checked, setChecked] = useState(false);
  const handleChange = useCallback(
    (newChecked: boolean) => setChecked(newChecked),
    [],
  );

  return (
    <Page>
      <LegacyCard sectioned>
        <Checkbox
          label="Basic checkbox"
          checked={checked}
          onChange={handleChange}
        />
      </LegacyCard>
    </Page>
  );
}

export default CheckboxDefault