import {
    LegacyCard,
    ResourceList,
    Button,
    Avatar,
    ResourceItem,
    Text,
    Page,
} from '@shopify/polaris';
import React from 'react';

function WithAlternateTool() {
    const resourceName = {
        singular: 'Customer',
        plural: 'Customers',
    };

    const items = [
        {
            id: '107',
            url: '#',
            name: 'Mae Jemison',
            location: 'Decatur, USA',
        },
        {
            id: '207',
            url: '#',
            name: 'Ellen Ochoa',
            location: 'Los Angeles, USA',
        },
    ];

    return (
        <Page>
            <LegacyCard>
                <ResourceList
                    items={items}
                    renderItem={renderItem}
                    resourceName={resourceName}
                    alternateTool={<Button>Email customers</Button>}
                />
            </LegacyCard>
        </Page>

    );

    function renderItem(item: typeof items[number]) {
        const { id, url, name, location } = item;
        const media = <Avatar customer size="medium" name={name} />;

        return (
            <ResourceItem
                id={id}
                url={url}
                media={media}
                accessibilityLabel={`View details for ${name}`}
            >
                <Text variant="bodyMd" fontWeight="bold" as="h3">
                    {name}
                </Text>
                <div>{location}</div>
            </ResourceItem>
        );
    }
}
export default WithAlternateTool