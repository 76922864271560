import React from 'react'
import {Link, Page, Banner} from '@shopify/polaris';

function LinkEx() {
  return (
    <Page>
     

            {/*Default - do not use div tag*/}
        <div className='margin-top'>1) Default</div>
          <Link url="https://help.shopify.com/manual">fulfilling orders</Link>


            {/*Monochrome - do not use div tag*/}
        <div className='margin-top'>2) Monochrome</div>
            <Link monochrome url="https://help.shopify.com/manual">
             fulfilling orders
            </Link>


            {/*Monochrome in a banner - do not use div tag*/}
        <div className='margin-top'>3) Monochrome in a banner</div>
            <Banner>
                Learn more about{' '}
                <Link url="https://help.shopify.com/manual">fulfilling orders</Link>
            </Banner>


              {/*External link - do not use div tag*/}
        <div className='margin-top'>4) External link</div>
            <Link url="https://help.shopify.com/manual" external>
             Shopify Help Center
            </Link>
    </Page>
  )
}

export default LinkEx
