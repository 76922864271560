import React from 'react'
import {LegacyCard, EmptyState, Page, Link} from '@shopify/polaris';

function EmptyStateEx() {
  return (
    <Page>


        {/* Default - do not use margin-top div*/}
        <div className='margin-top'>1) Default</div>
            <LegacyCard sectioned>
                <EmptyState
                    heading="Manage your inventory transfers"
                    action={{content: 'Add transfer'}}
                    secondaryAction={{
                    content: 'Learn more',
                    url: 'https://help.shopify.com',
                    }}
                    image="https://cdn.shopify.com/s/files/1/0262/4071/2726/files/emptystate-files.png"
                >
                    <p>Track and receive your incoming inventory from suppliers.</p>
                </EmptyState>
           </LegacyCard>
        



         {/* With subdued footer context - do not use margin-top div*/}
        <div className='margin-top'>2) With subdued footer context</div>
            <LegacyCard sectioned>
                <EmptyState
                    heading="Manage your inventory transfers"
                    action={{content: 'Add transfer'}}
                    secondaryAction={{
                    content: 'Learn more',
                    url: 'https://help.shopify.com',
                    }}
                    footerContent={
                    <p>
                        If you don’t want to add a transfer, you can import your inventory
                        from{' '}
                        <Link monochrome url="/settings">
                        settings
                        </Link>
                        .
                    </p>
                    }
                    image="https://cdn.shopify.com/s/files/1/0262/4071/2726/files/emptystate-files.png"
                >
                    <p>Track and receive your incoming inventory from suppliers.</p>
                </EmptyState>
            </LegacyCard>
        

         {/* With full width layout - do not use margin-top div*/}
        <div className='margin-top'>3) With full width layout </div>
            <LegacyCard sectioned>
                <EmptyState
                    heading="Upload a file to get started"
                    action={{content: 'Upload files'}}
                    image="https://cdn.shopify.com/s/files/1/0262/4071/2726/files/emptystate-files.png"
                    fullWidth
                >
                    <p>
                    You can use the Files section to upload images, videos, and other
                    documents. This example shows the content with a centered layout and
                    full width.
                    </p>
                </EmptyState>
            </LegacyCard>
      
    </Page>
  )
}

export default EmptyStateEx
