import {LegacyStack, RadioButton, Page, LegacyCard} from '@shopify/polaris';
import {useState, useCallback} from 'react';

function RadioButtonDefault() {
  const [value, setValue] = useState('disabled');

  const handleChange = useCallback(
    (_: boolean, newValue: string) => setValue(newValue),
    [],
  );

  return (
    <Page>
        <LegacyCard sectioned>
            <LegacyStack vertical>
                <RadioButton
                    label="Accounts are disabled"
                    helpText="Customers will only be able to check out as guests."
                    checked={value === 'disabled'}
                    id="disabled"
                    name="accounts"
                    onChange={handleChange}
                />
                <RadioButton
                    label="Accounts are optional"
                    helpText="Customers will be able to check out with a customer account or as a guest."
                    id="optional"
                    name="accounts"
                    checked={value === 'optional'}
                    onChange={handleChange}
                />
            </LegacyStack>
        </LegacyCard>
    </Page>
  );
}

export default RadioButtonDefault