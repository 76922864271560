import {LegacyCard, Page, TextField} from '@shopify/polaris';
import React from 'react';

function TextFieldDisabled() {
  return (
    <Page>
        <LegacyCard sectioned>
            <TextField label="Store name" disabled autoComplete="off" />
        </LegacyCard>
    </Page>
  );
}

export default TextFieldDisabled