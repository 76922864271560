import { Button, Popover, ActionList, Page, LegacyCard } from '@shopify/polaris';
import { useState, useCallback } from 'react';

function WithActionList() {
    const [popoverActive, setPopoverActive] = useState(true);

    const togglePopoverActive = useCallback(
        () => setPopoverActive((popoverActive) => !popoverActive),
        [],
    );

    const activator = (
        <Button onClick={togglePopoverActive} disclosure>
            More actions
        </Button>
    );

    return (
        <Page>
            <LegacyCard sectioned>
                <div style={{ height: '250px' }}>
                    <Popover
                        active={popoverActive}
                        activator={activator}
                        autofocusTarget="first-node"
                        onClose={togglePopoverActive}
                    >
                        <ActionList
                            actionRole="menuitem"
                            items={[{ content: 'Import' }, { content: 'Export' }]}
                        />
                    </Popover>
                </div>
            </LegacyCard>
        </Page>
    );
}

export default WithActionList