import { LegacyCard, LegacyStack, TextField, Page } from '@shopify/polaris';
import { useState, useCallback } from 'react';

function WithRightAlignedText() {
    const [textFieldValue, setTextFieldValue] = useState('1');

    const handleTextFieldChange = useCallback(
        (value: string) => setTextFieldValue(value),
        [],
    );

    return (
        <Page>
            <LegacyCard sectioned>
                <LegacyStack>
                    <LegacyStack.Item fill>Price</LegacyStack.Item>
                    <TextField
                        label="Price"
                        labelHidden
                        value={textFieldValue}
                        onChange={handleTextFieldChange}
                        autoComplete="off"
                        align="right"
                    />
                </LegacyStack>
            </LegacyCard>
        </Page>
    );
}

export default WithRightAlignedText