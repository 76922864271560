import { LegacyCard, ResourceList, ResourceItem, Text, Page, Avatar } from '@shopify/polaris';
import { useState } from 'react';

{/* Default - do not use div tag */ }
const items = [
    {
        id: '6',
        url: 'posts/6',
        title: 'How To Get Value From Wireframes',
        author: 'Jonathan Mangrove',
    },
];

function ResourceItemEx() {

    {/* Default - do not use div tag */ }
    const [selectedItems, setSelectedItems] = useState([]);

    return (
        <Page>
            {/* Default - do not use div tag */}
            <div>1:- Default</div>
            <LegacyCard>
                <ResourceList
                    resourceName={{ singular: 'blog post', plural: 'blog posts' }}
                    items={items}
                    selectedItems={selectedItems}
                    onSelectionChange={setSelectedItems}
                    selectable
                    renderItem={(item) => {
                        const { id, url, title, author } = item;
                        const authorMarkup = author ? <div>by {author}</div> : null;
                        return (
                            <ResourceItem
                                id={id}
                                url={url}
                                accessibilityLabel={`View details for ${title}`}
                                name={title}
                            >
                                <Text variant="bodyMd" fontWeight="bold" as="h3">
                                    {title}
                                </Text>
                                {authorMarkup}
                            </ResourceItem>
                        );
                    }}
                />
            </LegacyCard>


            {/* With media - do not use div tag */}
            <div className='margin-top'>2:- With media</div>
            <LegacyCard>
                <ResourceList
                    resourceName={{ singular: 'customer', plural: 'customers' }}
                    items={[
                        {
                            id: '145',
                            url: '#',
                            avatarSource:
                                'https://burst.shopifycdn.com/photos/freelance-designer-working-on-laptop.jpg?width=746',
                            name: 'Yi So-Yeon',
                            location: 'Gwangju, South Korea',
                        },
                    ]}
                    renderItem={(item) => {
                        const { id, url, avatarSource, name, location } = item;

                        return (
                            <ResourceItem
                                id={id}
                                url={url}
                                media={
                                    <Avatar
                                        customer
                                        size="medium"
                                        name={name}
                                        source={avatarSource}
                                    />
                                }
                                accessibilityLabel={`View details for ${name}`}
                                name={name}
                            >
                                <Text variant="bodyMd" fontWeight="bold" as="h3">
                                    {name}
                                </Text>
                                <div>{location}</div>
                            </ResourceItem>
                        );
                    }}
                />
            </LegacyCard>


            {/* With shortcut actions - do not use div tag */}
            <div className='margin-top'>3:- With shortcut actions</div>
            <LegacyCard>
                <ResourceList
                    resourceName={{ singular: 'customer', plural: 'customers' }}
                    items={[
                        {
                            id: '145',
                            url: '#',
                            avatarSource:
                                'https://burst.shopifycdn.com/photos/freelance-designer-working-on-laptop.jpg?width=746',
                            name: 'Yi So-Yeon',
                            location: 'Gwangju, South Korea',
                            latestOrderUrl: '#',
                        },
                    ]}
                    renderItem={(item) => {
                        const { id, url, avatarSource, name, location, latestOrderUrl } = item;
                        const shortcutActions = latestOrderUrl
                            ? [{ content: 'View latest order', url: latestOrderUrl }]
                            : undefined;

                        return (
                            <ResourceItem
                                id={id}
                                url={url}
                                media={
                                    <Avatar
                                        customer
                                        size="medium"
                                        name={name}
                                        source={avatarSource}
                                    />
                                }
                                shortcutActions={shortcutActions}
                                accessibilityLabel={`View details for ${name}`}
                                name={name}
                            >
                                <Text variant="bodyMd" fontWeight="bold" as="h3">
                                    {name}
                                </Text>
                                <div>{location}</div>
                            </ResourceItem>
                        );
                    }}
                />
            </LegacyCard>


            {/* With vertical alignment - do not use div tag */}
            <div className='margin-top'>4:- With vertical alignment</div>
            <LegacyCard>
                <ResourceList
                    resourceName={{ singular: 'customer', plural: 'customers' }}
                    items={[
                        {
                            id: '145',
                            url: '#',
                            avatarSource:
                                'https://burst.shopifycdn.com/photos/freelance-designer-working-on-laptop.jpg?width=746',
                            name: 'Yi So-Yeon',
                            location: 'Gwangju, South Korea',
                            lastOrder: 'Emerald Silk Gown',
                        },
                    ]}
                    renderItem={(item) => {
                        const { id, url, avatarSource, name, location, lastOrder } = item;
                        return (
                            <ResourceItem
                                verticalAlignment="center"
                                id={id}
                                url={url}
                                media={
                                    <Avatar
                                        customer
                                        size="medium"
                                        name={name}
                                        source={avatarSource}
                                    />
                                }
                                accessibilityLabel={`View details for ${name}`}
                                name={name}
                            >
                                <Text variant="bodyMd" fontWeight="bold" as="h3">
                                    {name}
                                </Text>
                                <div>{location}</div>
                                <div>{lastOrder}</div>
                            </ResourceItem>
                        );
                    }}
                />
            </LegacyCard>
        </Page>
    );
}

export default ResourceItemEx