import { Button, Popover, FormLayout, Select, TextField, Page, LegacyCard } from '@shopify/polaris';
import { useState, useCallback } from 'react';

function WithFormComponents() {
    const [popoverActive, setPopoverActive] = useState(true);
    const [tagValue, setTagValue] = useState('');

    const togglePopoverActive = useCallback(
        () => setPopoverActive((popoverActive) => !popoverActive),
        [],
    );

    const handleTagValueChange = useCallback(
        (value: string) => setTagValue(value),
        [],
    );

    const activator = (
        <Button onClick={togglePopoverActive} disclosure>
            Filter
        </Button>
    );

    return (
        <Page>
            <LegacyCard sectioned>
                <div style={{ height: '280px' }}>
                    <Popover
                        active={popoverActive}
                        activator={activator}
                        onClose={togglePopoverActive}
                        ariaHaspopup={false}
                        sectioned
                    >
                        <FormLayout>
                            <Select label="Show all customers where:" options={['Tagged with']} />
                            <TextField
                                label="Tags"
                                value={tagValue}
                                onChange={handleTagValueChange}
                                autoComplete="off"
                            />
                            <Button size="slim">Add filter</Button>
                        </FormLayout>
                    </Popover>
                </div>
            </LegacyCard>
        </Page>
    );
}

export default WithFormComponents