import {Badge, LegacyCard, LegacyTabs, Page} from '@shopify/polaris';
import {useState, useCallback} from 'react';

function LegacyTabsWithBadgeContent() {
    const [selected, setSelected] = useState(0);

  const handleTabChange = useCallback(
    (selectedTabIndex: number) => setSelected(selectedTabIndex),
    [],
  );

  const tabs = [
    {
      id: 'all-customers-fitted-3',
      content: (
        <span>
          All <Badge status="new">10+</Badge>
        </span>
      ),
      accessibilityLabel: 'All customers',
      panelID: 'all-customers-fitted-content-3',
    },
    {
      id: 'accepts-marketing-fitted-3',
      content: (
        <span>
          Accepts marketing <Badge status="new">4</Badge>
        </span>
      ),
      panelID: 'accepts-marketing-fitted-content-3',
    },
  ];

  return (
    <Page>
        <LegacyCard>
            <LegacyTabs
                tabs={tabs}
                selected={selected}
                onSelect={handleTabChange}
                fitted
            >
                <LegacyCard.Section title={tabs[selected].content}>
                <p>Tab {selected} selected</p>
                </LegacyCard.Section>
            </LegacyTabs>
        </LegacyCard>
    </Page>
  );
}

export default LegacyTabsWithBadgeContent
