import {
    LegacyStack,
    Thumbnail,
    LegacyCard,
    DropZone,
    Text,
  } from '@shopify/polaris';
  import {NoteMinor} from '@shopify/polaris-icons';
  import {useState, useCallback} from 'react';
  
  function WithCustomFileDialogTrigger() {
    const [files, setFiles] = useState([]);
    const [openFileDialog, setOpenFileDialog] = useState(false);
  
    const handleDropZoneDrop = useCallback(
      (dropFiles: File[], _acceptedFiles: File[], _rejectedFiles: File[]) =>
        setFiles((files) => [...files, ...dropFiles]),
      [],
    );
    const toggleOpenFileDialog = useCallback(
      () => setOpenFileDialog((openFileDialog) => !openFileDialog),
      [],
    );
  
    const validImageTypes = ['image/gif', 'image/jpeg', 'image/png'];
  
    const uploadedFiles = files.length > 0 && (
      <LegacyStack vertical>
        {files.map((file, index) => (
          <LegacyStack alignment="center" key={index}>
            <Thumbnail
              size="small"
              alt={file.name}
              source={
                validImageTypes.indexOf(file.type) > -1
                  ? window.URL.createObjectURL(file)
                  : NoteMinor
              }
            />
            <div>
              {file.name}{' '}
              <Text variant="bodySm" as="p">
                {file.size} bytes
              </Text>
            </div>
          </LegacyStack>
        ))}
      </LegacyStack>
    );
  
    return (
      <LegacyCard
        sectioned
        title="Product Images"
        actions={[
          {
            content: 'Upload Image',
            onAction: toggleOpenFileDialog,
          },
        ]}
      >
        <DropZone
          openFileDialog={openFileDialog}
          onDrop={handleDropZoneDrop}
          onFileDialogClose={toggleOpenFileDialog}
        >
          {uploadedFiles}
        </DropZone>
      </LegacyCard>
    );
  }

  export default WithCustomFileDialogTrigger