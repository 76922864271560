import { Button, Popover, ActionList, Icon, Page, LegacyCard } from '@shopify/polaris';
import { ImportMinor, TickSmallMinor, ExportMinor } from '@shopify/polaris-icons';
import { useState, useCallback } from 'react';

function WithAnIconAndaSuffix() {
    const [active, setActive] = useState(true);

    const toggleActive = useCallback(() => setActive((active) => !active), []);

    const activator = (
        <Button onClick={toggleActive} disclosure>
            More actions
        </Button>
    );

    return (
        <Page>
            <LegacyCard sectioned>
                <div style={{ height: '200px' }}>
                    <Popover
                        active={active}
                        activator={activator}
                        autofocusTarget="first-node"
                        onClose={toggleActive}
                    >
                        <ActionList
                            actionRole="menuitem"
                            items={[
                                {
                                    active: true,
                                    content: 'Import file',
                                    icon: ImportMinor,
                                    suffix: <Icon source={TickSmallMinor} />,
                                },
                                { content: 'Export file', icon: ExportMinor },
                            ]}
                        />
                    </Popover>
                </div>
            </LegacyCard>
        </Page>

    );
}

export default WithAnIconAndaSuffix