import {
    IndexTable,
    LegacyCard,
    useIndexResourceState,
    Text,
    HorizontalStack,
    Badge,
    VerticalStack,
  } from '@shopify/polaris';
  import React from 'react';
  
  function SmallScreen() {
    const orders = [
      {
        id: '1020',
        order: '#1020',
        date: 'Jul 20 at 4:34pm',
        customer: 'Jaydon Stanton',
        total: '$969.44',
        paymentStatus: <Badge progress="complete">Paid</Badge>,
        fulfillmentStatus: <Badge progress="incomplete">Unfulfilled</Badge>,
      },
      {
        id: '1019',
        order: '#1019',
        date: 'Jul 20 at 3:46pm',
        customer: 'Ruben Westerfelt',
        total: '$701.19',
        paymentStatus: <Badge progress="partiallyComplete">Partially paid</Badge>,
        fulfillmentStatus: <Badge progress="incomplete">Unfulfilled</Badge>,
      },
      {
        id: '1018',
        order: '#1018',
        date: 'Jul 20 at 3.44pm',
        customer: 'Leo Carder',
        total: '$798.24',
        paymentStatus: <Badge progress="complete">Paid</Badge>,
        fulfillmentStatus: <Badge progress="incomplete">Unfulfilled</Badge>,
      },
    ];
    const resourceName = {
      singular: 'order',
      plural: 'orders',
    };
  
    const {selectedResources, allResourcesSelected, handleSelectionChange} =
      useIndexResourceState(orders);
  
    const rowMarkup = orders.map(
      (
        {id, order, date, customer, total, paymentStatus, fulfillmentStatus},
        index,
      ) => (
        <IndexTable.Row
          id={id}
          key={id}
          selected={selectedResources.includes(id)}
          position={index}
        >
          <div style={{padding: '12px 16px', width: '100%'}}>
            <VerticalStack gap="1">
              <Text as="span" variant="bodySm" color="subdued">
                {order} • {date}
              </Text>
              <HorizontalStack align="space-between">
                <Text as="span" variant="bodyMd" fontWeight="semibold">
                  {customer}
                </Text>
                <Text as="span" variant="bodyMd">
                  {total}
                </Text>
              </HorizontalStack>
              <HorizontalStack align="start" gap="1">
                {paymentStatus}
                {fulfillmentStatus}
              </HorizontalStack>
            </VerticalStack>
          </div>
        </IndexTable.Row>
      ),
    );
  
    return (
      <div style={{width: '430px'}}>
        <LegacyCard>
          <IndexTable
            resourceName={resourceName}
            itemCount={orders.length}
            selectedItemsCount={
              allResourcesSelected ? 'All' : selectedResources.length
            }
            condensed
            onSelectionChange={handleSelectionChange}
            headings={[
              {title: 'Order'},
              {title: 'Date'},
              {title: 'Customer'},
              {title: 'Total', alignment: 'end'},
              {title: 'Payment status'},
              {title: 'Fulfillment status'},
            ]}
          >
            {rowMarkup}
          </IndexTable>
        </LegacyCard>
      </div>
    );
  }

  export default SmallScreen