import { Text, LegacyStack, Page, LegacyCard } from '@shopify/polaris';
import React from 'react';

function TextEx() {
    return (
        <Page>
            <LegacyCard sectioned>

                {/* Default - do not use div tag */}
                <div>1:- Default</div>
                <LegacyStack vertical>
                    <Text variant="heading4xl" as="h1">
                        Online store dashboard
                    </Text>
                    <Text variant="heading3xl" as="h2">
                        Online store dashboard
                    </Text>
                    <Text variant="heading2xl" as="h3">
                        Online store dashboard
                    </Text>
                    <Text variant="headingXl" as="h4">
                        Online store dashboard
                    </Text>
                    <Text variant="headingLg" as="h5">
                        Online store dashboard
                    </Text>
                    <Text variant="headingMd" as="h6">
                        Online store dashboard
                    </Text>
                    <Text variant="headingSm" as="h6">
                        Online store dashboard
                    </Text>
                    <Text variant="headingXs" as="h6">
                        Online store dashboard
                    </Text>
                </LegacyStack>


                {/* Body - do not use div tag */}
                <div className='margin-top'>2:- Body</div>
                <LegacyStack vertical>
                    <Text variant="bodyLg" as="p">
                        Shopify POS is the easiest way to sell your products in person.
                        Available for iPad, iPhone, and Android.
                    </Text>
                    <p>
                        Shopify POS is the easiest way to sell your products in person.
                        Available for iPad, iPhone, and Android.
                    </p>
                    <Text variant="bodySm" as="p">
                        Shopify POS is the easiest way to sell your products in person.
                        Available for iPad, iPhone, and Android.
                    </Text>
                </LegacyStack>


                {/* Align - do not use div tag */}
                <div className='margin-top'>3:- Align</div>
                <LegacyStack vertical>
                    <Text variant="bodyLg" as="p" alignment="start">
                        Manage your Shopify store on-the-go with real-time notifications, access
                        to your dashboard, and order management, all from your smartphone.
                    </Text>
                    <Text variant="bodyLg" as="p" alignment="center">
                        Manage your Shopify store on-the-go with real-time notifications, access
                        to your dashboard, and order management, all from your smartphone.
                    </Text>
                    <Text variant="bodyLg" as="p" alignment="end">
                        Manage your Shopify store on-the-go with real-time notifications, access
                        to your dashboard, and order management, all from your smartphone.
                    </Text>
                    <Text variant="bodyLg" as="p" alignment="justify">
                        Manage your Shopify store on-the-go with real-time notifications, access
                        to your dashboard, and order management, all from your smartphone.
                    </Text>
                </LegacyStack>


                {/* Weight - do not use div tag */}
                <div className='margin-top'>4:- Weight</div>
                <LegacyStack vertical>
                    <Text as="p" fontWeight="bold">
                        Sales this year
                    </Text>
                    <Text as="p" fontWeight="semibold">
                        Sales this year
                    </Text>
                    <Text as="p" fontWeight="medium">
                        Sales this year
                    </Text>
                    <Text as="p" fontWeight="regular">
                        Sales this year
                    </Text>
                </LegacyStack>


                {/* Color - do not use div tag */}
                <div className='margin-top'>5:- Color</div>
                <LegacyStack vertical>
                    <Text as="p" color="subdued">
                        Use to de-emphasize a piece of text that is less important to merchants
                        than other nearby text. May also be used to indicate when normal content
                        is absent, for example, “No supplier listed”. Don’t use only for
                        aesthetic effect.
                    </Text>
                    <Text as="p" color="success">
                        Use in combination with a symbol showing an increasing value to indicate
                        an upward trend.
                    </Text>
                    <Text as="p" color="warning">
                        Use to denote something that needs attention, or that merchants need to
                        take action on.
                    </Text>
                    <Text as="p" color="critical">
                        Use in combination with a symbol showing a decreasing value to indicate
                        a downward trend.
                    </Text>
                </LegacyStack>


                {/* Inheritance - do not use div tag */}
                <div className='margin-top'>6:- Inheritance</div>
                <Text as="p" variant="heading2xl" color="warning">
                    <Text as="span">This is a 2xl heading</Text>
                    <br />
                    <Text as="span">This is also a 2xl heading</Text>
                </Text>

            </LegacyCard>
        </Page>
    );
}

export default TextEx