import React from 'react'
import {ExceptionList, Page} from '@shopify/polaris';
import {NoteMinor} from '@shopify/polaris-icons';

function ExceptionListEx() {
  return (
    <Page>
        <ExceptionList
            items={[
                {
                icon: NoteMinor,
                description:
                    'This customer is awesome. Make sure to treat them right!',
                },
            ]}
            />
   </Page>
  )
}

export default ExceptionListEx
