import { Button, Popover, ActionList, Page, LegacyCard } from '@shopify/polaris';
import { useState, useCallback } from 'react';

function InaPopover() {
    const [active, setActive] = useState(true);

    const toggleActive = useCallback(() => setActive((active) => !active), []);

    const handleImportedAction = useCallback(
        () => console.log('Imported action'),
        [],
    );

    const handleExportedAction = useCallback(
        () => console.log('Exported action'),
        [],
    );

    const activator = (
        <Button onClick={toggleActive} disclosure>
            More actions
        </Button>
    );

    return (
        <Page>
            <LegacyCard sectioned>
                <div style={{ height: '250px' }}>
                    <Popover
                        active={active}
                        activator={activator}
                        autofocusTarget="first-node"
                        onClose={toggleActive}
                    >
                        <ActionList
                            actionRole="menuitem"
                            items={[
                                {
                                    content: 'Import file',
                                    onAction: handleImportedAction,
                                },
                                {
                                    content: 'Export file',
                                    onAction: handleExportedAction,
                                },
                            ]}
                        />
                    </Popover>
                </div>
            </LegacyCard>
        </Page>
    );
}

export default InaPopover