import {LegacyCard, TextField, Page} from '@shopify/polaris';
import {useState, useCallback} from 'react';

function WithClearButton() {
  const [textFieldValue, setTextFieldValue] = useState('Jaded Pixel');

  const handleTextFieldChange = useCallback(
    (value: string) => setTextFieldValue(value),
    [],
  );

  const handleClearButtonClick = useCallback(() => setTextFieldValue(''), []);

  return (
    <Page>
        <LegacyCard sectioned>
            <TextField
                label="Store name"
                value={textFieldValue}
                onChange={handleTextFieldChange}
                clearButton
                onClearButtonClick={handleClearButtonClick}
                autoComplete="off"
            />
        </LegacyCard>
    </Page>
  );
}

export default WithClearButton