import { Autocomplete, Tag, LegacyStack, Page, LegacyCard } from '@shopify/polaris';
import { useState, useCallback } from 'react';

function WithLazyLoading() {
    const paginationInterval = 25;
    const deselectedOptions = Array.from(Array(100)).map((_, index) => ({
        value: `rustic ${index + 1}`,
        label: `Rustic ${index + 1}`,
    }));

    const [selectedOptions, setSelectedOptions] = useState([]);
    const [inputValue, setInputValue] = useState('');
    const [options, setOptions] = useState(deselectedOptions);
    const [isLoading, setIsLoading] = useState(false);
    const [willLoadMoreResults, setWillLoadMoreResults] = useState(true);
    const [visibleOptionIndex, setVisibleOptionIndex] =
        useState(paginationInterval);

    const handleLoadMoreResults = useCallback(() => {
        if (willLoadMoreResults) {
            setIsLoading(true);

            setTimeout(() => {
                const remainingOptionCount = options.length - visibleOptionIndex;
                const nextVisibleOptionIndex =
                    remainingOptionCount >= paginationInterval
                        ? visibleOptionIndex + paginationInterval
                        : visibleOptionIndex + remainingOptionCount;

                setIsLoading(false);
                setVisibleOptionIndex(nextVisibleOptionIndex);

                if (remainingOptionCount <= paginationInterval) {
                    setWillLoadMoreResults(false);
                }
            }, 1000);
        }
    }, [willLoadMoreResults, visibleOptionIndex, options.length]);

    const removeTag = useCallback(
        (tag: string) => () => {
            const options = [...selectedOptions];
            options.splice(options.indexOf(tag), 1);
            setSelectedOptions(options);
        },
        [selectedOptions],
    );

    const updateText = useCallback(
        (value: string) => {
            setInputValue(value);

            if (value === '') {
                setOptions(deselectedOptions);
                return;
            }

            const filterRegex = new RegExp(value, 'i');
            const resultOptions = deselectedOptions.filter((option) => option.label.match(filterRegex),);

            setOptions(resultOptions);
            setInputValue();
        },
        [deselectedOptions],
    );

    const textField = (
        <Autocomplete.TextField
            onChange={updateText}
            label="Tags"
            value={inputValue}
            placeholder="Vintage, cotton, summer"
            autoComplete="off"
        />
    );

    const hasSelectedOptions = selectedOptions.length > 0;

    const tagsMarkup = hasSelectedOptions
        ? selectedOptions.map((option) => {
            let tagLabel = '';
            tagLabel = option.replace('_', ' ');
            tagLabel = titleCase(tagLabel);
            return (
                <Tag key={`option${option}`} onRemove={removeTag(option)}>
                    {tagLabel}
                </Tag>
            );
        })
        : null;
    const optionList = options.slice(0, visibleOptionIndex);
    const selectedTagMarkup = hasSelectedOptions ? (
        <LegacyStack spacing="extraTight">{tagsMarkup}</LegacyStack>
    ) : null;

    return (
        <Page>
            <LegacyCard sectioned>
                <LegacyStack vertical>
                    {selectedTagMarkup}
                    <Autocomplete
                        allowMultiple
                        options={optionList}
                        selected={selectedOptions}
                        textField={textField}
                        onSelect={setSelectedOptions}
                        listTitle="Suggested Tags"
                        loading={isLoading}
                        onLoadMoreResults={handleLoadMoreResults}
                        willLoadMoreResults={willLoadMoreResults}
                    />
                </LegacyStack>
            </LegacyCard>
        </Page>
    );

    function titleCase(string: string) {
        return string
            .toLowerCase()
            .split(' ')
            .map((word) => {
                return word.replace(word[0], word[0].toUpperCase());
            })
            .join(' ');
    }
}

export default WithLazyLoading