import React from 'react'
import {Pagination, Page} from '@shopify/polaris';

function PaginationEx() {
  return (
    <Page>


    {/*Default - do not use div tag*/}
    <div>1) Default</div>
        <Pagination
            hasPrevious
            onPrevious={() => {
            console.log('Previous');
            }}
            hasNext
            onNext={() => {
            console.log('Next');
            }}
        />




     {/*With keyboard navigation - do not use div tag*/}
     <div className='margin-top'>2) With keyboard navigation</div>
        <div style={{height: '100px'}}>
            <Pagination
                hasPrevious
                previousKeys={[74]}
                previousTooltip="j"
                onPrevious={() => {
                console.log('Previous');
                }}
                hasNext
                nextKeys={[75]}
                nextTooltip="k"
                onNext={() => {
                console.log('Next');
                }}
            />
        </div>



      {/*With label - do not use div tag*/}
     <div className='margin-top'>3) With label</div>
        <Pagination
        label="Results"
        hasPrevious
        onPrevious={() => {
            console.log('Previous');
        }}
        hasNext
        onNext={() => {
            console.log('Next');
        }}
        />



    </Page>
  )
}

export default PaginationEx
