import {
  AppProvider,
  Page,
  LegacyCard,
  ResourceList,
  Avatar,
  Text,
} from '@shopify/polaris';
import React from 'react';

function AppProviderEx() {
  {/* With i18n */ }
  const CustomLinkComponent = ({
    children,
    url,
    ...rest
  }: {
    children?: React.ReactNode;
    url: string;
  }) => {
    return (
      <a
        href={url}
        onClick={() => console.log('Custom link clicked')}
        {...rest}
      >
        {children}
      </a>
    );
  };

  return (
    <Page>
      {/* Default - do not use div tag */}
      <div>1:- Default</div>
      <AppProvider
        i18n={{
          Polaris: {
            ResourceList: {
              sortingLabel: 'Sort by',
              defaultItemSingular: 'item',
              defaultItemPlural: 'items',
              showing: 'Showing {itemsCount} {resource}',
              Item: {
                viewItem: 'View details for {itemName}',
              },
            },
            Common: {
              checkbox: 'checkbox',
            },
          },
        }}
      >
        <Page>
          <LegacyCard>
            <ResourceList
              showHeader
              items={[
                {
                  id: '341',
                  url: '#',
                  name: 'Mae Jemison',
                  location: 'Decatur, USA',
                },
                {
                  id: '256',
                  url: '#',
                  name: 'Ellen Ochoa',
                  location: 'Los Angeles, USA',
                },
              ]}
              renderItem={(item) => {
                const { id, url, name, location } = item;
                const media = <Avatar customer size="medium" name={name} />;

                return (
                  <ResourceList.Item id={id} url={url} media={media}>
                    <Text variant="bodyMd" fontWeight="bold" as="h3">
                      {name}
                    </Text>
                    <div>{location}</div>
                  </ResourceList.Item>
                );
              }}
            />
          </LegacyCard>
        </Page>
      </AppProvider>


      {/* With i18n - do not use div tag */}
      <div className='margin-top'>2:- With i18n</div>
      <AppProvider
        i18n={{
          Polaris: {
            Common: {
              checkbox: 'case à cocher',
            },
            ResourceList: {
              sortingLabel: 'Trier par',
              showing: '{itemsCount} {resource} affichés',
              defaultItemPlural: 'articles',
              defaultItemSingular: 'article',
              Item: {
                viewItem: "Afficher les détails de l'{itemName}",
              },
            },
          },
        }}
      >
        <Page>
          <LegacyCard>
            <ResourceList
              showHeader
              items={[
                {
                  id: '341',
                  url: '#',
                  name: 'Mae Jemison',
                  location: 'Decatur, USA',
                },
                {
                  id: '256',
                  url: '#',
                  name: 'Ellen Ochoa',
                  location: 'Los Angeles, USA',
                },
              ]}
              renderItem={(item) => {
                const { id, url, name, location } = item;
                const media = <Avatar customer size="medium" name={name} />;

                return (
                  <ResourceList.Item id={id} url={url} media={media}>
                    <Text variant="bodyMd" fontWeight="bold" as="h3">
                      {name}
                    </Text>
                    <div>{location}</div>
                  </ResourceList.Item>
                );
              }}
            />
          </LegacyCard>
        </Page>
      </AppProvider>


      {/* With linkcomponent - do not use div tag */}
      <div className='margin-top'>3:- With linkcomponent</div>
      <AppProvider
        linkComponent={CustomLinkComponent}
        i18n={{
          Polaris: {
            Page: {
              Header: {
                rollupButton: 'Actions',
              },
            },
          },
        }}
      >
        <Page
          backAction={{ content: 'Products', url: '#' }}
          title="Jar With Lock-Lid"
          primaryAction={{ content: 'Save', disabled: true }}
          secondaryActions={[
            { content: 'Duplicate', url: '#' },
            { content: 'View on your store', url: '#' },
          ]}
        >
          <p>Page content</p>
        </Page>
      </AppProvider>
      
    </Page>
  );
}

export default AppProviderEx
