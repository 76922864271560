import {LegacyCard, TextField, Page} from '@shopify/polaris';
import {useState, useCallback} from 'react';

function TextFieldDefault() {
  const [value, setValue] = useState('Jaded Pixel');

  const handleChange = useCallback(
    (newValue: string) => setValue(newValue),
    [],
  );

  return (
    <Page>
        <LegacyCard sectioned>
            <TextField
            label="Store name"
            value={value}
            onChange={handleChange}
            autoComplete="off"
            />
        </LegacyCard>
    </Page>
  );
}

export default TextFieldDefault