import {LegacyCard, LegacyTabs, Page} from '@shopify/polaris';
import {useState, useCallback} from 'react';

function LegacyTabsWithCustomDisclosure() {
    const [selected, setSelected] = useState(0);

    const handleTabChange = useCallback(
      (selectedTabIndex: number) => setSelected(selectedTabIndex),
      [],
    );
  
    const tabs = [
      {
        id: 'all-customers-4',
        content: 'All',
        accessibilityLabel: 'All customers',
        panelID: 'all-customers-content-4',
      },
      {
        id: 'accepts-marketing-4',
        content: 'Accepts marketing',
        panelID: 'accepts-marketing-content-4',
      },
      {
        id: 'repeat-customers-4',
        content: 'Repeat customers',
        panelID: 'repeat-customers-content-4',
      },
      {
        id: 'prospects-4',
        content: 'Prospects',
        panelID: 'prospects-content-4',
      },
    ];
  
    return (
        <Page>
            <LegacyCard>
                <LegacyTabs
                tabs={tabs}
                selected={selected}
                onSelect={handleTabChange}
                disclosureText="More views"
                >
                <LegacyCard.Section title={tabs[selected].content}>
                    <p>Tab {selected} selected</p>
                </LegacyCard.Section>
                </LegacyTabs>
            </LegacyCard>
      </Page>
    );
}

export default LegacyTabsWithCustomDisclosure
