import { LegacyCard, TextField, Page } from '@shopify/polaris';
import { useState, useCallback } from 'react';

function WithLabelAction() {
    const [textFieldValue, setTextFieldValue] = useState('6201.11.0000');

    const handleTextFieldChange = useCallback(
        (value: string) => setTextFieldValue(value),
        [],
    );

    return (
        <Page>
            <LegacyCard sectioned>
                <TextField
                    label="Tariff code"
                    value={textFieldValue}
                    onChange={handleTextFieldChange}
                    labelAction={{ content: 'Look up codes' }}
                    autoComplete="off"
                />
            </LegacyCard>
        </Page>
    );
}

export default WithLabelAction