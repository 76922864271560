import { MediaCard, VideoThumbnail } from '@shopify/polaris';
import React from 'react';

function VideoThumbnailEx() {
    return (
        <>
            {/* Default - do not use div tag */}
            <div>1:- Default</div>
            <MediaCard
                title="Turn your side-project into a business"
                primaryAction={{
                    content: 'Learn more',
                    onAction: () => { },
                }}
                description={`In this course, you’ll learn how the Kular family turned their mom’s recipe book into a global business.`}
                popoverActions={[{ content: 'Dismiss', onAction: () => { } }]}
            >
                <VideoThumbnail
                    videoLength={80}
                    thumbnailUrl="https://burst.shopifycdn.com/photos/business-woman-smiling-in-office.jpg?width=1850"
                    onClick={() => console.log('clicked')}
                />
            </MediaCard>


            {/* With progress - do not use div tag */}
            <div className='margin-top'>2:- With progress</div>
            <MediaCard
                title="Turn your side-project into a business"
                primaryAction={{
                    content: 'Learn more',
                    onAction: () => { },
                }}
                description={`In this course, you’ll learn how the Kular family turned their mom’s recipe book into a global business.`}
                popoverActions={[{ content: 'Dismiss', onAction: () => { } }]}
            >
                <VideoThumbnail
                    videoLength={80}
                    videoProgress={45}
                    showVideoProgress
                    thumbnailUrl="https://burst.shopifycdn.com/photos/business-woman-smiling-in-office.jpg?width=1850"
                    onClick={() => console.log('clicked')}
                />
            </MediaCard>
        </>
    );
}

export default VideoThumbnailEx