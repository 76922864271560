import { LegacyCard, List, Page } from '@shopify/polaris';
import React from 'react';

function ListEx() {
    return (
        <Page>
            <LegacyCard sectioned>
                {/* Bulleted - do not use duv tag */}
                <div>1:- Bulleted</div>
                <List type="bullet">
                    <List.Item>Yellow shirt</List.Item>
                    <List.Item>Red shirt</List.Item>
                    <List.Item>Green shirt</List.Item>
                </List>


                {/* Numbered - do not use duv tag */}
                <div className='margin-top'>2:- Bulleted</div>
                <List type="number">
                    <List.Item>First item</List.Item>
                    <List.Item>Second item</List.Item>
                    <List.Item>Third Item</List.Item>
                </List>


                {/* Extra Tight */}
                <div className='margin-top'>3:- Extra Tight</div>
                <List spacing="extraTight">
                    <List.Item>Yellow shirt</List.Item>
                    <List.Item>Red shirt</List.Item>
                    <List.Item>Green shirt</List.Item>
                </List>
            </LegacyCard>
        </Page>

    );
}

export default ListEx