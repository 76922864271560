import { LegacyCard, LegacyStack, Page, Tag, TextField } from '@shopify/polaris';
import { useState, useCallback } from 'react';

function WithVerticalContent() {
    const tags = ['Rustic', 'Antique', 'Vinyl', 'Refurbished'];
    const [textFieldValue, setTextFieldValue] = useState('');

    const handleTextFieldChange = useCallback(
        (value: string) => setTextFieldValue(value),
        [],
    );

    const verticalContentMarkup =
        tags.length > 0 ? (
            <LegacyStack spacing="extraTight" alignment="center">
                {tags.map((tag) => (
                    <Tag key={tag}>{tag}</Tag>
                ))}
            </LegacyStack>
        ) : null;

    return (
        <Page>
            <LegacyCard sectioned>
                <TextField
                    label="Tags"
                    value={textFieldValue}
                    onChange={handleTextFieldChange}
                    placeholder="Search tags"
                    autoComplete="off"
                    verticalContent={verticalContentMarkup}
                />
            </LegacyCard>
        </Page>
    );
}

export default WithVerticalContent