import {Banner, Page, Link, List, Button, TextContainer, Modal, LegacyCard} from '@shopify/polaris';
import React, {useState, useCallback, useEffect, useRef} from 'react';

function BannerEx() {
    // In a modal
    const [active, setActive] = useState(false);
    const handleChange = useCallback(() => setActive(!active), [active]);

    // With focus
    const banner = useRef(null);

    useEffect(() => banner.current?.focus(), []);

  return (
    <Page>

           {/*Default - do not use div tag*/}
        <div className='margin-top'>1) Default</div>
            <Banner title="Order archived" onDismiss={() => {}}>
                <p>This order was archived on March 7, 2017 at 3:12pm EDT.</p>
            </Banner>


            {/*Dismissible - do not use div tag*/}
        <div className='margin-top'>2) Dismissible</div>
            <Banner onDismiss={() => {}}>
                <p>
                    Use your finance report to get detailed information about your business.{' '}
                    <Link url="">Let us know what you think</Link>
                </p>
            </Banner>



            {/*With footer call-to-action - do not use div tag*/}
        <div className='margin-top'>3) With footer call-to-action</div>
            <Banner
                title="Some of your product variants are missing weights"
                status="warning"
                action={{content: 'Edit variant weights', url: ''}}
                secondaryAction={{content: 'Learn more', url: ''}}
                onDismiss={() => {}}
                >
                <p>
                    Add weights to show accurate rates at checkout and when buying shipping
                    labels in Shopify.
                </p>
            </Banner>


              {/*Informational - do not use div tag*/}
        <div className='margin-top'>4) Informational</div>
            <Banner
                title="USPS has updated their rates"
                action={{content: 'Update rates', url: ''}}
                secondaryAction={{content: 'Learn more'}}
                status="info"
                onDismiss={() => {}}
                >
                <p>Make sure you know how these changes affect your store.</p>
            </Banner>



              {/*Success - do not use div tag*/}
        <div className='margin-top'>5) Success</div>
            <Banner
            title="Your shipping label is ready to print."
            status="success"
            action={{content: 'Print label'}}
            onDismiss={() => {}}
            />



            
              {/*Warning - do not use div tag*/}
        <div className='margin-top'>6) Warning</div>
            <Banner
                title="Before you can purchase a shipping label, this change needs to be made:"
                action={{content: 'Edit address'}}
                status="warning"
                >
                <List>
                    <List.Item>
                    The name of the city you’re shipping to has characters that aren’t
                    allowed. City name can only include spaces and hyphens.
                    </List.Item>
                </List>
            </Banner>


              {/*Critical - do not use div tag*/}
        <div className='margin-top'>7) Critical</div>
            <Banner
                title="High risk of fraud detected"
                action={{content: 'Review risk analysis'}}
                status="critical"
                >
                <p>
                    Before fulfilling this order or capturing payment, please{' '}
                    <Link url="">review the Risk Analysis</Link> and determine if this order
                    is fraudulent.
                </p>
            </Banner>



             {/*In a modal - do not use div tag*/}
        <div className='margin-top'>8) In a modal</div>
            <div style={{height: '500px'}}>
                <Button onClick={handleChange}>Open</Button>
                <Modal
                    open={active}
                    onClose={handleChange}
                    title="Reach more shoppers with Instagram product tags"
                    primaryAction={{
                    content: 'Add Instagram',
                    onAction: handleChange,
                    }}
                    secondaryActions={[
                    {
                        content: 'Learn more',
                        onAction: handleChange,
                    },
                    ]}
                >
                    <Modal.Section>
                    <TextContainer>
                        <Banner action={{content: 'Connect account'}} status="warning">
                        <p>
                            Connect your instagram account to your shop before proceeding.
                        </p>
                        </Banner>
                        <p>
                        Use Instagram posts to share your products with millions of
                        people. Let shoppers buy from your store without leaving
                        Instagram.
                        </p>
                    </TextContainer>
                    </Modal.Section>
                </Modal>
            </div>



            {/* With focus - do not use div tag */}
        <div className='margin-top'>9) With focus</div>
            <Banner
                title="High risk of fraud detected"
                onDismiss={() => {}}
                status="critical"
                ref={banner}
                >
                <p>
                    Before fulfilling this order or capturing payment, please review the
                    fraud analysis and determine if this order is fraudulent
                </p>
            </Banner>



            {/* In a card - do not use div tag */}
        <div className='margin-top'>10) In a card</div>
            <LegacyCard title="Online store dashboard" sectioned>
                <TextContainer>
                    <Banner onDismiss={() => {}}>
                    <p>
                        Use your finance report to get detailed information about your
                        business. <Link url="">Let us know what you think</Link>
                    </p>
                    </Banner>

                    <p>View a summary of your online store’s performance.</p>
                </TextContainer>
            </LegacyCard>


    </Page>
  )
}

export default BannerEx
