import {
    LegacyCard,
    ResourceList,
    Avatar,
    ResourceItem,
    Text,
    Page,
} from '@shopify/polaris';
import { useState } from 'react';

function WithSorting() {
    const [sortValue, setSortValue] = useState('DATE_MODIFIED_DESC');

    const resourceName = {
        singular: 'customer',
        plural: 'customers',
    };

    const items = [
        {
            id: '106',
            url: '#',
            name: 'Mae Jemison',
            location: 'Decatur, USA',
        },
        {
            id: '206',
            url: '#',
            name: 'Ellen Ochoa',
            location: 'Los Angeles, USA',
        },
    ];

    return (
        <Page>
            <LegacyCard>
                <ResourceList
                    resourceName={resourceName}
                    items={items}
                    renderItem={renderItem}
                    sortValue={sortValue}
                    sortOptions={[
                        { label: 'Newest update', value: 'DATE_MODIFIED_DESC' },
                        { label: 'Oldest update', value: 'DATE_MODIFIED_ASC' },
                    ]}
                    onSortChange={(selected) => {
                        setSortValue(selected);
                        console.log(`Sort option changed to ${selected}.`);
                    }}
                />
            </LegacyCard>
        </Page>
    );

    function renderItem(item: typeof items[number]) {
        const { id, url, name, location } = item;
        const media = <Avatar customer size="medium" name={name} />;

        return (
            <ResourceItem
                id={id}
                url={url}
                media={media}
                accessibilityLabel={`View details for ${name}`}
            >
                <Text variant="bodyMd" fontWeight="bold" as="h3">
                    {name}
                </Text>
                <div>{location}</div>
            </ResourceItem>
        );
    }
}

export default WithSorting