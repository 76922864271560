import {TextField, ChoiceList, LegacyCard, Page} from '@shopify/polaris';
import {useState, useCallback} from 'react';

function WithDynamicChildrenContent() {
  const [selected, setSelected] = useState(['none']);
  const [textFieldValue, setTextFieldValue] = useState('');

  const handleChoiceListChange = useCallback(
    (value: string[]) => setSelected(value),
    [],
  );

  const handleTextFieldChange = useCallback(
    (value: string) => setTextFieldValue(value),
    [],
  );

  const renderChildren = useCallback(
    (isSelected: boolean) =>
      isSelected && (
        <TextField
          label="Minimum Quantity"
          labelHidden
          onChange={handleTextFieldChange}
          value={textFieldValue}
          autoComplete="off"
        />
      ),
    [handleTextFieldChange, textFieldValue],
  );

  return (
    <Page>
        <LegacyCard sectioned>
            <div style={{height: '150px'}}>
                <ChoiceList
                    title="Discount minimum requirements"
                    choices={[
                    {label: 'None', value: 'none'},
                    {label: 'Minimum purchase', value: 'minimum_purchase'},
                    {
                        label: 'Minimum quantity',
                        value: 'minimum_quantity',
                        renderChildren,
                    },
                    ]}
                    selected={selected}
                    onChange={handleChoiceListChange}
                />
            </div>
        </LegacyCard>
    </Page>
  );
}

export default WithDynamicChildrenContent