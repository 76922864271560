import React from 'react'
import {VerticalStack, HorizontalStack, Text, Divider, Page} from '@shopify/polaris';


function VerticalInlineAlign() {
    return (
        <Page>
          <VerticalStack gap="8">
            <VerticalStack inlineAlign="start">
              <Placeholder height="48px" width="320px" label="Start" showBorder />
              <Placeholder height="48px" width="320px" showBorder />
              <Placeholder height="48px" width="320px" showBorder />
            </VerticalStack>
            <Divider />
            <VerticalStack inlineAlign="center">
              <Placeholder height="48px" width="320px" label="Center" showBorder />
              <Placeholder height="48px" width="320px" showBorder />
              <Placeholder height="48px" width="320px" showBorder />
            </VerticalStack>
            <Divider />
            <VerticalStack inlineAlign="end">
              <Placeholder height="48px" width="320px" label="End" showBorder />
              <Placeholder height="48px" width="320px" showBorder />
              <Placeholder height="48px" width="320px" showBorder />
            </VerticalStack>
          </VerticalStack>
        </Page>
      );
    }
    
    const Placeholder = ({
      label = '',
      height = 'auto',
      width = 'auto',
      showBorder = false,
    }) => {
      return (
        <div
          style={{
            background: 'var(--p-color-text-info)',
            padding: '14px var(--p-space-2)',
            height: height,
            width: width,
            borderBlockEnd: showBorder
              ? '1px dashed var(--p-color-bg-success-subdued)'
              : 'none',
          }}
        >
          <HorizontalStack align="center">
            <div
              style={{
                color: 'var(--p-color-text-on-color)',
              }}
            >
              <Text as="h2" variant="bodyMd" fontWeight="regular">
                {label}
              </Text>
            </div>
          </HorizontalStack>
        </div>
      );
    };

export default VerticalInlineAlign
