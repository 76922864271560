import { Avatar, LegacyCard, Page, Button, Popover, ActionList } from '@shopify/polaris';
import {useState, useCallback} from 'react';

function AvatarEx() {

    // Extra small
    const [active, setActive] = useState(true);
    const toggleActive = useCallback(() => setActive((active) => !active), []);
    const activator = (
        <Button onClick={toggleActive} disclosure>
        Manage staff
        </Button>
    );

    return (
        <Page>
            <LegacyCard sectioned>

                {/* Default - do not use div tag */}
                <div>1:- Default</div>
                <Avatar customer name="Farrah" />


                {/* Initials - do not use div tag */}
                <div className='margin-top'>2:- Initials</div>
                <Avatar initials="WW" name="Woluwayemisi Weun-Jung" />


                {/* Extra small - do not use first div tag */}
                <div className='margin-top'>3:- Extra small</div>
                <div style={{ height: '250px' }}>
                    <Popover active={active} activator={activator} onClose={toggleActive}>
                        <ActionList
                            items={[
                                {
                                    content: 'Chet Baker',
                                    prefix: <Avatar customer size="extraSmall" name="Chet Baker" />,
                                },
                                {
                                    content: 'Farrah Fawcett',
                                    prefix: (
                                        <Avatar customer size="extraSmall" name="Farrah Fawcett" />
                                    ),
                                },
                            ]}
                        />
                    </Popover>
                </div>


                {/* Square - do not use div tag */}
                <div className='margin-top'>4:- Square</div>
                <Avatar name="Shop One" shape="square" />

            </LegacyCard>
        </Page>
    );
}

export default AvatarEx