import React from 'react'
import {Page, Badge, LegacyCard, Button, LegacyStack, PageActions } from '@shopify/polaris';
import {ArrowDownMinor, ExternalMinor, PlusMinor} from '@shopify/polaris-icons';

const PageEx = () => {
  return (

    <>


    {/*Default - do not use div tag*/}
    <div className='margin-top'>1) Default</div>
        <Page
            backAction={{content: 'Products', url: '#'}}
            title="3/4 inch Leather pet collar"
            titleMetadata={<Badge status="success">Paid</Badge>}
            subtitle="Perfect for any pet"
            compactTitle
            primaryAction={{content: 'Save', disabled: true}}
            secondaryActions={[
                {
                content: 'Duplicate',
                accessibilityLabel: 'Secondary action label',
                onAction: () => alert('Duplicate action'),
                },
                {
                content: 'View on your store',
                onAction: () => alert('View on your store action'),
                },
            ]}
            actionGroups={[
                {
                title: 'Promote',
                actions: [
                    {
                    content: 'Share on Facebook',
                    accessibilityLabel: 'Individual action label',
                    onAction: () => alert('Share on Facebook action'),
                    },
                ],
                },
            ]}
            pagination={{
                hasPrevious: true,
                hasNext: true,
            }}
            >
            <LegacyCard title="Credit card" sectioned>
                <p>Credit card information</p>
            </LegacyCard>
        </Page>



    {/*With custom primary action - do not use div tag*/}
    <div className='margin-top'>2) With custom primary action</div>
        <Page
            backAction={{content: 'Settings', url: '#'}}
            title="General"
            primaryAction={
                <Button
                primary
                connectedDisclosure={{
                    accessibilityLabel: 'Other save actions',
                    actions: [{content: 'Save as new'}],
                }}
                >
                Save
                </Button>
            }
            >
            <LegacyCard title="Credit card" sectioned>
                <p>Credit card information</p>
            </LegacyCard>
        </Page>



    {/*Without primary action in header - do not use div tag*/}
    <div className='margin-top'>3) Without primary action in header</div>
        <Page
            backAction={{content: 'Orders', url: '#'}}
            title="#1085"
            secondaryActions={[
                {content: 'Print'},
                {content: 'Unarchive'},
                {content: 'Cancel order'},
            ]}
            pagination={{
                hasPrevious: true,
                hasNext: true,
            }}
            >
            <LegacyCard sectioned title="Fulfill order">
                <LegacyStack alignment="center">
                <LegacyStack.Item fill>
                    <p>Buy postage and ship remaining 2 items</p>
                </LegacyStack.Item>
                <Button primary>Continue</Button>
                </LegacyStack>
            </LegacyCard>
        </Page>


    {/*With destructive secondary action - do not use div tag*/}
    <div className='margin-top'>4) With destructive secondary action</div>
        <Page
        title="General"
        secondaryActions={[{content: 'Delete', destructive: true}]}
        >
        <p>Page content</p>
        </Page>



    {/*With custom secondary action - do not use div tag*/}
    <div className='margin-top'>5) With custom secondary action</div>
        <Page
        title="General"
        secondaryActions={
            <Button
            connectedDisclosure={{
                accessibilityLabel: 'Other save actions',
                actions: [{content: 'Save as new'}],
            }}
            >
            Save
            </Button>
        }
        >
        <p>Page content</p>
        </Page> 


    {/*With tooltip action - do not use div tag*/}
    <div className='margin-top'>6) With tooltip action</div>
        <Page
            title="Product"
            primaryAction={{
                content: 'Save',
            }}
            secondaryActions={[
                {
                content: 'Import',
                disabled: true,
                helpText: 'You need permission to import products.',
                },
            ]}
            />



    {/*With subtitle - do not use div tag*/}
    <div className='margin-top'>7) With subtitle</div>
        <Page
        backAction={{content: 'Products', url: '#'}}
        title="Invoice"
        subtitle="Statement period: May 3, 2019 to June 2, 2019"
        secondaryActions={[{content: 'Download', icon: ArrowDownMinor}]}
        >
        <LegacyCard title="Credit card" sectioned>
            <p>Credit card information</p>
        </LegacyCard>
        </Page>



    {/*With external link - do not use div tag*/}
    <div className='margin-top'>8) With external link</div>
        <Page
        title="Jar With Lock-Lid"
        primaryAction={{content: 'Save', disabled: true}}
        secondaryActions={[
            {
            content: 'Promote',
            external: true,
            icon: ExternalMinor,
            url: 'https://www.facebook.com/business/learn/facebook-page-build-audience',
            },
        ]}
        >
        <LegacyCard title="Credit card" sectioned>
            <p>Credit card information</p>
        </LegacyCard>
        </Page>



    {/*Without pagination - do not use div tag*/}
    <div className='margin-top'>9) Without pagination</div>
        <Page
        backAction={{content: 'Settings', url: '#'}}
        title="General"
        primaryAction={{content: 'Save'}}
        >
        <LegacyCard title="Credit card" sectioned>
            <p>Credit card information</p>
        </LegacyCard>
        </Page>



    {/*Full-width - do not use div tag*/}
    <div className='margin-top'>10) Full-width</div>
        <Page
        fullWidth
        title="Orders"
        primaryAction={{content: 'Create order', icon: PlusMinor}}
        secondaryActions={[{content: 'Export'}]}
        pagination={{
            hasNext: true,
        }}
        >
        <LegacyCard title="Credit card" sectioned>
            <p>Credit card information</p>
        </LegacyCard>
        </Page> 



    {/*Narrow width - do not use div tag*/}
    <div className='margin-top'>11) Narrow width</div> 
        <Page
        narrowWidth
        backAction={{content: 'Orders', url: '#'}}
        title="Add payment method"
        primaryAction={{content: 'Save', disabled: true}}
        >
        <LegacyCard title="Credit card" sectioned>
            <p>Credit card information</p>
        </LegacyCard>
        <PageActions
            primaryAction={{content: 'Save', disabled: true}}
            secondaryActions={[{content: 'Delete'}]}
        />
        </Page>



    {/*With action groups - do not use div tag*/}
    <div className='margin-top'>12) With action groups</div>
        <Page
        title="Products"
        actionGroups={[
            {
            title: 'Copy',
            onClick: (openActions) => {
                alert('Copy action');
                openActions();
            },
            actions: [{content: 'Copy to clipboard'}],
            },
            {
            title: 'Promote',
            disabled: true,
            actions: [{content: 'Share on Facebook'}],
            },
            {
            title: 'More actions',
            actions: [
                {content: 'Duplicate'},
                {content: 'Print'},
                {content: 'Unarchive'},
                {content: 'Cancel order'},
            ],
            },
        ]}
        >
        <LegacyCard title="Credit card" sectioned>
            <p>Credit card information</p>
        </LegacyCard>
        </Page> 



    {/*With content after title - do not use div tag*/}
    <div className='margin-top'>13) With content after title</div>
        <Page
        backAction={{content: 'Products', url: '#'}}
        title="Jar With Lock-Lid"
        titleMetadata={<Badge status="attention">Verified</Badge>}
        primaryAction={{content: 'Save', disabled: true}}
        secondaryActions={[
            {content: 'Duplicate'},
            {content: 'View on your store'},
        ]}
        pagination={{
            hasPrevious: true,
            hasNext: true,
        }}
        >
        <LegacyCard title="Credit card" sectioned>
            <p>Credit card information</p>
        </LegacyCard>
        </Page>


    {/*With divider - do not use div tag*/}
    <div className='margin-top'>14) With divider</div>
        <Page backAction={{content: 'Settings', url: '#'}} title="General" divider>
        <LegacyCard title="Credit card" sectioned>
            <p>Credit card information</p>
        </LegacyCard>
        </Page>                                         


    </>
  )
}

export default PageEx
