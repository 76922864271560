import React from 'react'
import {ProgressBar, Page} from '@shopify/polaris';

function ProgressBarEx() {
  return (
    <Page>

    {/*Default - do not use div tag*/}
    <div className='margin-top'>1) Default</div>  
        <div style={{width: 225}}>
            <ProgressBar progress={75} />
        </div>



    {/*Small - do not use div tag*/}
    <div className='margin-top'>2) Small</div>  
        <div style={{width: 225}}>
            <ProgressBar progress={40} size="small" />
        </div>



    {/*Colored - do not use div tag*/}
    <div className='margin-top'>3) Colored</div>  
        <div style={{width: 225}}>
            <ProgressBar progress={70} color="primary" />
        <br />
            <ProgressBar progress={30} color="success" />
        </div> 



    {/*Non-animated - do not use div tag*/}
    <div className='margin-top'>4) Non-animated</div>  
        <div style={{width: 225}}>
        <ProgressBar progress={80} animated={false} />
        </div>   
    

    </Page>
  )
}

export default ProgressBarEx
