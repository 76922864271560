import React, { Component } from "react";
import enTranslations from "@shopify/polaris/locales/en.json";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import "./App.css";

import AccountConnectionExample from "./component/actions/AccountConnectionExample";
import ButtonEx from "./component/actions/ButtonEx";
import ButtonGroupEx from "./component/actions/ButtonGroupEx";
import AutocompleteDefault from "./component/selection-and-input/autocomplete/AutocompleteDefault";
import WithMultipleTags from "./component/selection-and-input/autocomplete/WithMultipleTags";
import WithMultipleSections from "./component/selection-and-input/autocomplete/WithMultipleSections";
import PageActionsEx from "./component/actions/PageActionsEx";
import Horizontal from "./component/Layout-and-structure/Bleed/Horizontal";
import Vertical from "./component/Layout-and-structure/Bleed/Vertical";
import SpecificDirection from "./component/Layout-and-structure/Bleed/SpecificDirection";
import Color from "./component/Layout-and-structure/Box/Color";
import BorderWidth from "./component/Layout-and-structure/Box/BorderWidth";
import BorderRadius from "./component/Layout-and-structure/Box/BorderRadius";
import Padding from "./component/Layout-and-structure/Box/Padding";
import Shadow from "./component/Layout-and-structure/Box/Shadow";
import CalloutCardEx from "./component/Layout-and-structure/Callout-card/CalloutCardEx";
import CardEx from "./component/Layout-and-structure/Card/CardEx";
import DividerEx from "./component/Layout-and-structure/Divider/DividerEx";
import EmptyStateEx from "./component/Layout-and-structure/Empty-state/EmptyStateEx";
import FromLayoutEx from "./component/Layout-and-structure/From-layout/FromLayoutEx";
import GridEx from "./component/Layout-and-structure/Grid/GridEx";
import WithLoading from "./component/selection-and-input/autocomplete/WithLoading";
import WithLazyLoading from "./component/selection-and-input/autocomplete/WithLazyLoading";
import WithEmptyState from "./component/selection-and-input/autocomplete/WithEmptyState";
import WithAction from "./component/selection-and-input/autocomplete/WithAction";
import WithWrappingAction from "./component/selection-and-input/autocomplete/WithWrappingAction";
import WithDestructiveAction from "./component/selection-and-input/autocomplete/WithDestructiveAction";
import CheckboxDefault from "./component/selection-and-input/checkbox/CheckboxDefault";
import ChoiceDefault from "./component/selection-and-input/choice_list/ChoiceDefault";
import ChoiceWithError from "./component/selection-and-input/choice_list/ChoiceWithError";
import WithMultiChoice from "./component/selection-and-input/choice_list/WithMultiChoice";
import WithChildrenContent from "./component/selection-and-input/choice_list/WithChildrenContent";
import WithDynamicChildrenContent from "./component/selection-and-input/choice_list/WithDynamicChildrenContent";
import ColorDefault from "./component/selection-and-input/color_picker/ColorDefault";
import WithTransparentValue from "./component/selection-and-input/color_picker/WithTransparentValue";
import WithTransparentValueFullWidth from "./component/selection-and-input/color_picker/WithTransparentValueFullWidth";
import ComboboxDefault from "./component/selection-and-input/combobox/ComboboxDefault";
import WithManualSelection from "./component/selection-and-input/combobox/WithManualSelection";
import WithMultiSelect from "./component/selection-and-input/combobox/WithMultiSelect";
import WithMultiSelectAndManualSelection from "./component/selection-and-input/combobox/WithMultiSelectAndManualSelection";
import WithMultiSelectAndVerticalContent from "./component/selection-and-input/combobox/WithMultiSelectAndVerticalContent";
import ComboboxWithLoading from "./component/selection-and-input/combobox/ComboboxWithLoading";
import Gap from "./component/Layout-and-structure/Horizontal-grid/Gap";
import ColumnWidth from "./component/Layout-and-structure/Horizontal-grid/ColumnWidth";
import NumberOfColumns from "./component/Layout-and-structure/Horizontal-grid/NumberOfColumns";
import NonWrapping from "./component/Layout-and-structure/Horizontal-stack/NonWrapping";
import HorizontalGap from "./component/Layout-and-structure/Horizontal-stack/HorizontalGap";
import BlockAlign from "./component/Layout-and-structure/Horizontal-stack/BlockAlign";
import Align from "./component/Layout-and-structure/Horizontal-stack/Align";
import LayoutEx from "./component/Layout-and-structure/Layout/LayoutEx";
import LegacyCardEx from "./component/Layout-and-structure/Legacy-card/LegacyCardEx";
import LegacyStackEx from "./component/Layout-and-structure/Legacy-stack/LegacyStackEx";
import MediaCardEx from "./component/Layout-and-structure/Media-card/MediaCardEx";
import PageEx from "./component/Layout-and-structure/Page/PageEx";
import VerticalGap from "./component/Layout-and-structure/Vertical-Stack/VerticalGap";
import VerticalAlign from "./component/Layout-and-structure/Vertical-Stack/VerticalAlign";
import VerticalInlineAlign from "./component/Layout-and-structure/Vertical-Stack/VerticalInlineAlign";
import ContextualSaveBarEx from "./component/selection-and-input/contextual_save_bar/ContextualSaveBarEx";
import DateDefault from "./component/selection-and-input/date_picker/DateDefault";
import Ranged from "./component/selection-and-input/date_picker/Ranged";
import MultiMonthRanged from "./component/selection-and-input/date_picker/MultiMonthRanged";
import WithDisabledDateRanges from "./component/selection-and-input/date_picker/WithDisabledDateRanges";
import WithSpecificDisabledDates from "./component/selection-and-input/date_picker/WithSpecificDisabledDates";
import DropZoneDefault from "./component/selection-and-input/drop_zone/DropZoneDefault";
import WithaLabel from "./component/selection-and-input/drop_zone/WithaLabel";
import WithImageFileUpload from "./component/selection-and-input/drop_zone/WithImageFileUpload";
import WithSingleFileUpload from "./component/selection-and-input/drop_zone/WithSingleFileUpload";
import AcceptsOnlySvgFiles from "./component/selection-and-input/drop_zone/AcceptsOnlySvgFiles";
import Nested from "./component/selection-and-input/drop_zone/Nested";
import MediumSized from "./component/selection-and-input/drop_zone/MediumSized";
import SmallSized from "./component/selection-and-input/drop_zone/SmallSized";
import WithCustomFileUploadText from "./component/selection-and-input/drop_zone/WithCustomFileUploadText";
import WithCustomFileDialogTrigger from "./component/selection-and-input/drop_zone/WithCustomFileDialogTrigger";
import WithaResourceList from "./component/selection-and-input/filters/WithaResourceList";
import WithaDataTable from "./component/selection-and-input/filters/WithaDataTable";
import FiltersWithChildrenContent from "./component/selection-and-input/filters/FiltersWithChildrenContent";
import FiltersDisabled from "./component/selection-and-input/filters/FiltersDisabled";
import WithSomeDisabled from "./component/selection-and-input/filters/WithSomeDisabled";
import WithQueryFieldHidden from "./component/selection-and-input/filters/WithQueryFieldHidden";
import WithQueryFieldDisabled from "./component/selection-and-input/filters/WithQueryFieldDisabled";
import CustomOnSubmit from "./component/selection-and-input/form/CustomOnSubmit";
import WithoutNativeValidation from "./component/selection-and-input/form/WithoutNativeValidation";
import IndexFiltersDefault from "./component/selection-and-input/index_filters/IndexFiltersDefault";
import WithPinnedFilters from "./component/selection-and-input/index_filters/WithPinnedFilters";
import FilteringModeDefault from "./component/selection-and-input/index_filters/FilteringModeDefault";
import IndexFiltersDisabled from "./component/selection-and-input/index_filters/IndexFiltersDisabled";
import WithNoFilters from "./component/selection-and-input/index_filters/WithNoFilters";
import WithNoSearchOrFilters from "./component/selection-and-input/index_filters/WithNoSearchOrFilters";
import InlineErrorDefault from "./component/selection-and-input/inline_error/InlineErrorDefault";
import RadioButtonDefault from "./component/selection-and-input/radio_button/RadioButtonDefault";
import RangeSliderDefault from "./component/selection-and-input/range_slider/RangeSliderDefault";
import WithMinAndMax from "./component/selection-and-input/range_slider/WithMinAndMax";
import WithSteps from "./component/selection-and-input/range_slider/WithSteps";
import WithPrefixAndSuffix from "./component/selection-and-input/range_slider/WithPrefixAndSuffix";
import WithDualThumb from "./component/selection-and-input/range_slider/WithDualThumb";
import SelectDefault from "./component/selection-and-input/select/SelectDefault";
import WithInlineLabel from "./component/selection-and-input/select/WithInlineLabel";
import SelectDisabled from "./component/selection-and-input/select/SelectDisabled";
import WithPrefix from "./component/selection-and-input/select/WithPrefix";
import WithValidationError from "./component/selection-and-input/select/WithValidationError";
import WithSeparateValidationError from "./component/selection-and-input/select/WithSeparateValidationError";
import TextFieldDefault from "./component/selection-and-input/text_field/TextFieldDefault";
import Number from "./component/selection-and-input/text_field/Number";
import Email from "./component/selection-and-input/text_field/Email";
import Multiline from "./component/selection-and-input/text_field/Multiline";
import WithHiddenLabel from "./component/selection-and-input/text_field/WithHiddenLabel";
import WithLabelAction from "./component/selection-and-input/text_field/WithLabelAction";
import WithRightAlignedText from "./component/selection-and-input/text_field/WithRightAlignedText";
import WithPlaceholderText from "./component/selection-and-input/text_field/WithPlaceholderText";
import WithHelpText from "./component/selection-and-input/text_field/WithHelpText";
import WithPrefixOrSuffix from "./component/selection-and-input/text_field/WithPrefixOrSuffix";
import WithVerticalContent from "./component/selection-and-input/text_field/WithVerticalContent";
import WithConnectedFields from "./component/selection-and-input/text_field/WithConnectedFields";
import TextFieldWithValidationError from "./component/selection-and-input/text_field/TextFieldWithValidationError";
import TextFieldWithSeparateValidationError from "./component/selection-and-input/text_field/TextFieldWithSeparateValidationError";
import TextFieldDisabled from "./component/selection-and-input/text_field/TextFieldDisabled";
import WithCharacterCount from "./component/selection-and-input/text_field/WithCharacterCount";
import WithClearButton from "./component/selection-and-input/text_field/WithClearButton";
import WithMonospacedFont from "./component/selection-and-input/text_field/WithMonospacedFont";
import WithValueSelectedOnFocus from "./component/selection-and-input/text_field/WithValueSelectedOnFocus";
import WithInlineSuggestion from "./component/selection-and-input/text_field/WithInlineSuggestion";
import TagEx from "./component/selection-and-input/tag/TagEx";
import Removable from "./component/selection-and-input/tag/Removable";
import RemovableWithLink from "./component/selection-and-input/tag/RemovableWithLink";
import AvatarEx from "./component/images-and-icons/avatar/AvatarEx";
import IconEx from "./component/images-and-icons/icon/IconEx";
import KeyboardKeyEx from "./component/images-and-icons/keyboard_key/KeyboardKeyEx";
import ThumbnailEx from "./component/images-and-icons/thumbnail/ThumbnailEx";
import VideoThumbnailEx from "./component/images-and-icons/video_thumbnail/VideoThumbnailEx";
import TextEx from "./component/typography/text/TextEx";
import BadgeEx from "./component/Feedback-indicators/BadgeEx";
import DataTableDefault from "./component/tables/data_table/DataTableDefault";
import Sortable from "./component/tables/data_table/Sortable";
import WithFooter from "./component/tables/data_table/WithFooter";
import WithCustomTotalsHeading from "./component/tables/data_table/WithCustomTotalsHeading";
import WithTotalsInFooter from "./component/tables/data_table/WithTotalsInFooter";
import WithRowHeadingLinks from "./component/tables/data_table/WithRowHeadingLinks";
import WithAllOfItsElements from "./component/tables/data_table/WithAllOfItsElements";
import WithFixedFirstColumns from "./component/tables/data_table/WithFixedFirstColumns";
import WithIncreasedDensityAndZebraStriping from "./component/tables/data_table/WithIncreasedDensityAndZebraStriping";
import WithStickyHeaderEnabled from "./component/tables/data_table/WithStickyHeaderEnabled";
import IndexTableDefault from "./component/tables/index_table/IndexTableDefault";
import WithSavedViewsSearchFilteringAndSorting from "./component/tables/index_table/WithSavedViewsSearchFilteringAndSorting";
import WithDisabledRows from "./component/tables/index_table/WithDisabledRows";
import SmallScreen from "./component/tables/index_table/SmallScreen";
import SmallScreenWithSavedViewsSearchFilteringAndSorting from "./component/tables/index_table/SmallScreenWithSavedViewsSearchFilteringAndSorting";
import IndexTableWithEmptyState from "./component/tables/index_table/IndexTableWithEmptyState";
import WithBulkActions from "./component/tables/index_table/WithBulkActions";
import WithMultiplePromotedBulkActions from "./component/tables/index_table/WithMultiplePromotedBulkActions";
import BannerEx from "./component/Feedback-indicators/BannerEx";
import WithBulkActionsAndSelectionAcrossPages from "./component/tables/index_table/WithBulkActionsAndSelectionAcrossPages";
import WithLoadingState from "./component/tables/index_table/WithLoadingState";
import WithFiltering from "./component/tables/index_table/WithFiltering";
import WithStickyLastColumn from "./component/tables/index_table/WithStickyLastColumn";
import WithRowNavigationLink from "./component/tables/index_table/WithRowNavigationLink";
import WithoutCheckboxes from "./component/tables/index_table/WithoutCheckboxes";
import ExceptionListEx from "./component/Feedback-indicators/ExceptionListEx";
import Loadingex from "./component/Feedback-indicators/Loadingex";
import ProgressBarEx from "./component/Feedback-indicators/ProgressBarEx";
import SkeletonBodyTextEx from "./component/Feedback-indicators/SkeletonBodyTextEx";
import SkeletonDisplayTextEx from "./component/Feedback-indicators/SkeletonDisplayTextEx";
import SkeletonPageEx from "./component/Feedback-indicators/SkeletonPageEx";
import SkeletonTabsEx from "./component/Feedback-indicators/SkeletonTabsEx";
import SkeletonThumbnailEx from "./component/Feedback-indicators/SkeletonThumbnailEx";
import SpinnerEx from "./component/Feedback-indicators/SpinnerEx";
import ToastDefault from "./component/Feedback-indicators/Toast/ToastDefault";
import ToastMultipleMessages from "./component/Feedback-indicators/Toast/ToastMultipleMessages";
import ToastWithCustomDuration from "./component/Feedback-indicators/Toast/ToastWithCustomDuration";
import ToastWithAction from "./component/Feedback-indicators/Toast/ToastWithAction";
import ToastError from "./component/Feedback-indicators/Toast/ToastError";
import InaPopover from "./component/lists/action_list/InaPopover";
import WithIconsOrImage from "./component/lists/action_list/WithIconsOrImage";
import WithAnIconAndaSuffix from "./component/lists/action_list/WithAnIconAndaSuffix";
import WithSections from "./component/lists/action_list/WithSections";
import WithDestructiveItem from "./component/lists/action_list/WithDestructiveItem";
import ActionListWithHelpText from "./component/lists/action_list/ActionListWithHelpText";
import WithaPrefixAndaSuffix from "./component/lists/action_list/WithaPrefixAndaSuffix";
import DescriptionListDefault from "./component/lists/description_list/DescriptionListDefault";
import ListEx from "./component/lists/list/ListEx";
import ListBoxEx from "./component/lists/listbox/ListBoxEx";
import WithSearch from "./component/lists/listbox/WithSearch";
import OptionListEx from "./component/lists/option_list/OptionListEx";
import OptionListInaPopover from "./component/lists/option_list/OptionListInaPopover";
import ResourceItemEx from "./component/lists/resource_item/ResourceItemEx";
import ResourceListDefault from "./component/lists/resource_list/ResourceListDefault";
import ResourceListWithEmptyState from "./component/lists/resource_list/ResourceListWithEmptyState";
import WithSelectionAndNoBulkActions from "./component/lists/resource_list/WithSelectionAndNoBulkActions";
import ResourceListWithBulkActions from "./component/lists/resource_list/ResourceListWithBulkActions";
import ResourceListWithLoadingState from "./component/lists/resource_list/ResourceListWithLoadingState";
import WithTotalCount from "./component/lists/resource_list/WithTotalCount";
import WithSorting from "./component/lists/resource_list/WithSorting";
import WithAlternateTool from "./component/lists/resource_list/WithAlternateTool";
import ResourceListWithFiltering from "./component/lists/resource_list/ResourceListWithFiltering";
import WithaCustomEmptySearchResultState from "./component/lists/resource_list/WithaCustomEmptySearchResultState";
import FullscreenBarWithChildren from "./component/Navigation/Fullscreen-bar/FullscreenBarWithChildren";
import FullscreenBarNoChildren from "./component/Navigation/Fullscreen-bar/FullscreenBarNoChildren";
import LegacyTabsDefault from "./component/Navigation/Legacy-tabs/LegacyTabsDefault";
import LegacyTabsFitted from "./component/Navigation/Legacy-tabs/LegacyTabsFitted";
import LegacyTabsWithBadgeContent from "./component/Navigation/Legacy-tabs/LegacyTabsWithBadgeContent";
import LegacyTabsWithCustomDisclosure from "./component/Navigation/Legacy-tabs/LegacyTabsWithCustomDisclosure";
import LinkEx from "./component/Navigation/Link/LinkEx";
import FooterHelpEx from "./component/Navigation/Footer-help/FooterHelpEx";
import NavigationEx from "./component/Navigation/Navigation/NavigationEx";
import PaginationEx from "./component/Navigation/pagination/PaginationEx";
import TabsDefault from "./component/Navigation/Tabs/TabsDefault";
import TabsInsideOfCard from "./component/Navigation/Tabs/TabsInsideOfCard";
import TabsFitted from "./component/Navigation/Tabs/TabsFitted";
import TabsWithActions from "./component/Navigation/Tabs/TabsWithActions";
import TabsWithBadgeContent from "./component/Navigation/Tabs/TabsWithBadgeContent";
import TabsWithCustomDisclosure from "./component/Navigation/Tabs/TabsWithCustomDisclosure";
import TopBarEx from "./component/Navigation/Top-bar/TopBarEx";
import WithItemShortcutActions from "./component/lists/resource_list/WithItemShortcutActions";
import ResourceListWithAllOfItsElements from "./component/lists/resource_list/ResourceListWithAllOfItsElements";
import ModalDefault from "./component/Overlays/Modal/ModalDefault";
import WithPrimaryAction from "./component/Overlays/Modal/WithPrimaryAction";
import WithPrimaryAndSecondaryActions from "./component/Overlays/Modal/WithPrimaryAndSecondaryActions";
import Large from "./component/Overlays/Modal/Large";
import Small from "./component/Overlays/Modal/Small";
import WithoutaTitle from "./component/Overlays/Modal/WithoutaTitle";
import WithScrollListener from "./component/Overlays/Modal/WithScrollListener";
import WithActivatorRef from "./component/Overlays/Modal/WithActivatorRef";
import WithoutAnActivatorProp from "./component/Overlays/Modal/WithoutAnActivatorProp";
import WithActionList from "./component/Overlays/Popover/WithActionList";
import WithContentAndActions from "./component/Overlays/Popover/WithContentAndActions";
import WithFormComponents from "./component/Overlays/Popover/WithFormComponents";
import WithLazyLoadedList from "./component/Overlays/Popover/WithLazyLoadedList";
import WithSearchableListbox from "./component/Overlays/Popover/WithSearchableListbox";
import TooltipEx from "./component/Overlays/Tooltip/TooltipEx";
import AppProviderEx from "./component/Utilities/App_provider/AppProviderEx";
import CollapsibleEx from "./component/Utilities/Collapsible/CollapsibleEx";
import InAnApplication from "./component/Utilities/Frame/InAnApplication";
import WithAnOffset from "./component/Utilities/Frame/WithAnOffset";
import ScrollableEx from "./component/Utilities/Scrollable/ScrollableEx";
import LegacyFiltersWithaResourceList from "./component/Deprecated/Legacy_filters/LegacyFiltersWithaResourceList";
import LegacyFiltersWithaDataTable from "./component/Deprecated/Legacy_filters/LegacyFiltersWithaDataTable";
import LegacyFiltersWithChildrenContent from "./component/Deprecated/Legacy_filters/LegacyFiltersWithChildrenContent";
import LegacyFiltersDisabled from "./component/Deprecated/Legacy_filters/LegacyFiltersDisabled";
import SomeDisabled from "./component/Deprecated/Legacy_filters/SomeDisabled";
import WithoutClearButton from "./component/Deprecated/Legacy_filters/WithoutClearButton";
import LegacyFiltersWithHelpText from "./component/Deprecated/Legacy_filters/LegacyFiltersWithHelpText";
import LegacyFiltersWithQueryFieldHidden from "./component/Deprecated/Legacy_filters/LegacyFiltersWithQueryFieldHidden";
import LegacyFiltersWithQueryFieldDisabled from "./component/Deprecated/Legacy_filters/LegacyFiltersWithQueryFieldDisabled";
import WithPrimitiveComponents from "./component/Deprecated/Setting_toggle/WithPrimitiveComponents";
import WithDeprecatedComponent from "./component/Deprecated/Setting_toggle/WithDeprecatedComponent";
import SheetDefault from "./component/Deprecated/Sheet/SheetDefault";
import SheetWithSearchableListbox from "./component/Deprecated/Sheet/SheetWithSearchableListbox";
import TextContainerEx from "./component/Deprecated/Text_container/TextContainerEx";
import WithDropOnPage from "./component/selection-and-input/drop_zone/WithDropOnPage";
import WithPersistentItemShortcutActions from "./component/lists/resource_list/WithPersistentItemShortcutActions";
import ResourceListWithMultiselect from "./component/lists/resource_list/ResourceListWithMultiselect";
import { AppProvider, Page } from "@shopify/polaris";
import Sidebar from "./Sidebar";
class App extends Component {
  render() {
    return (
      <AppProvider i18n={enTranslations}>
        <Router>
          <div className="main">
            <Sidebar />
            <div className="components-container">
              <Routes>
          {/* Actions */}
                <Route
                  exact
                  path="/"
                  element={<AccountConnectionExample />}
                ></Route>
                <Route path="/actions/button" element={<ButtonEx />}></Route>
                <Route
                  path="/actions/button-group"
                  element={<ButtonGroupEx />}
                ></Route>
                <Route
                  path="/actions/page-actions"
                  element={<PageActionsEx />}
                ></Route>
          {/* layout-and-structure */}
                <Route
                  path="/layout-and-structure/bleed/horizontal"
                  element={<Horizontal />}
                ></Route>
                <Route
                  path="/layout-and-structure/bleed/vertical"
                  element={<Vertical />}
                ></Route>
                <Route
                  path="/layout-and-structure/bleed/specific-direction"
                  element={<SpecificDirection />}
                ></Route>
                <Route
                  path="/layout-and-structure/box/color"
                  element={<Color />}
                ></Route>
                <Route
                  path="/layout-and-structure/box/border-width"
                  element={<BorderWidth />}
                ></Route>
                <Route
                  path="/layout-and-structure/box/border-radius"
                  element={<BorderRadius />}
                ></Route>
                <Route
                  path="/layout-and-structure/box/padding"
                  element={<Padding />}
                ></Route>
                <Route
                  path="/layout-and-structure/box/shadow"
                  element={<Shadow />}
                ></Route>
                <Route
                  path="/layout-and-structure/callout-card"
                  element={<CalloutCardEx />}
                ></Route>
                <Route
                  path="/layout-and-structure/card"
                  element={<CardEx />}
                ></Route>
                <Route
                  path="/layout-and-structure/divider"
                  element={<DividerEx />}
                ></Route>
                <Route
                  path="/layout-and-structure/empty-state"
                  element={<EmptyStateEx />}
                ></Route>
                <Route
                  path="/layout-and-structure/form-layout"
                  element={<FromLayoutEx />}
                ></Route>
                <Route
                  path="/layout-and-structure/grid"
                  element={<GridEx />}
                ></Route>
                <Route
                  path="/layout-and-structure/horizontal-grid/gap"
                  element={<Gap />}
                ></Route>
                <Route
                  path="/layout-and-structure/horizontal-grid/column-width"
                  element={<ColumnWidth />}
                ></Route>
                <Route
                  path="/layout-and-structure/horizontal-grid/number-of-columns"
                  element={<NumberOfColumns />}
                ></Route>
                <Route
                  path="/layout-and-structure/horizontal-stack/non-wrapping"
                  element={<NonWrapping />}
                ></Route>
                <Route
                  path="/layout-and-structure/horizontal-stack/gap"
                  element={<HorizontalGap />}
                ></Route>
                <Route
                  path="/layout-and-structure/horizontal-stack/block-align"
                  element={<BlockAlign />}
                ></Route>
                <Route
                  path="/layout-and-structure/horizontal-stack/align"
                  element={<Align />}
                ></Route>
                <Route
                  path="/layout-and-structure/layout"
                  element={<LayoutEx />}
                ></Route>
                <Route
                  path="/layout-and-structure/legacy-card"
                  element={<LegacyCardEx />}
                ></Route>
                <Route
                  path="/layout-and-structure/legacy-stack"
                  element={<LegacyStackEx />}
                ></Route>
                <Route
                  path="/layout-and-structure/media-card"
                  element={<MediaCardEx />}
                ></Route>
                <Route
                  path="/layout-and-structure/page"
                  element={<PageEx />}
                ></Route>
                <Route
                  path="/layout-and-structure/vertical-stack/gap"
                  element={<VerticalGap />}
                ></Route>
                <Route
                  path="/layout-and-structure/vertical-stack/align"
                  element={<VerticalAlign />}
                ></Route>
                <Route
                  path="/layout-and-structure/vertical-stack/inline-align"
                  element={<VerticalInlineAlign />}
                ></Route>
          {/* selection and input */}
                <Route
                  path="/selection-and-input/autocomplete/default"
                  element={<AutocompleteDefault />}
                ></Route>
                <Route
                  path="/selection-and-input/autocomplete/with-multiple-tags"
                  element={<WithMultipleTags />}
                ></Route>
                <Route
                  path="/selection-and-input/autocomplete/with-multiple-sections"
                  element={<WithMultipleSections />}
                ></Route>
                <Route
                  path="/selection-and-input/autocomplete/with-loading"
                  element={<WithLoading />}
                ></Route>
                <Route
                  path="/selection-and-input/autocomplete/with-lazy-loading"
                  element={<WithLazyLoading />}
                ></Route>
                <Route
                  path="/selection-and-input/autocomplete/with-empty-state"
                  element={<WithEmptyState />}
                ></Route>
                <Route
                  path="/selection-and-input/autocomplete/with-action"
                  element={<WithAction />}
                ></Route>
                <Route
                  path="/selection-and-input/autocomplete/with-wrapping-action"
                  element={<WithWrappingAction />}
                ></Route>
                <Route
                  path="/selection-and-input/autocomplete/with-destructive-action"
                  element={<WithDestructiveAction />}
                ></Route>
                <Route
                  path="/selection-and-input/checkbox"
                  element={<CheckboxDefault />}
                ></Route>
                <Route
                  path="/selection-and-input/choice-list/default"
                  element={<ChoiceDefault />}
                ></Route>
                <Route
                  path="/selection-and-input/choice-list/with-error"
                  element={<ChoiceWithError />}
                ></Route>
                <Route
                  path="/selection-and-input/choice-list/with-multi-choice"
                  element={<WithMultiChoice />}
                ></Route>
                <Route
                  path="/selection-and-input/choice-list/with-children-content"
                  element={<WithChildrenContent />}
                ></Route>
                <Route
                  path="/selection-and-input/choice-list/with-dynamic-children-content"
                  element={<WithDynamicChildrenContent />}
                ></Route>
                <Route
                  path="/selection-and-input/color-picker/default"
                  element={<ColorDefault />}
                ></Route>
                <Route
                  path="/selection-and-input/color-picker/with-transparent-value"
                  element={<WithTransparentValue />}
                ></Route>
                <Route
                  path="/selection-and-input/color-picker/with-transparent-value-full-width"
                  element={<WithTransparentValueFullWidth />}
                ></Route>
                <Route
                  path="/selection-and-input/combobox/default"
                  element={<ComboboxDefault />}
                ></Route>
                <Route
                  path="/selection-and-input/combobox/with-manual-selection"
                  element={<WithManualSelection />}
                ></Route>
                <Route
                  path="/selection-and-input/combobox/with-multi-select"
                  element={<WithMultiSelect />}
                ></Route>
                <Route
                  path="/selection-and-input/combobox/with-multi-select-and-manual-selection"
                  element={<WithMultiSelectAndManualSelection />}
                ></Route>
                <Route
                  path="/selection-and-input/combobox/with-multi-select-and-vertical-content"
                  element={<WithMultiSelectAndVerticalContent />}
                ></Route>
                <Route
                  path="/selection-and-input/combobox/with-loading"
                  element={<ComboboxWithLoading />}
                ></Route>
                <Route
                  path="/selection-and-input/contextual-save-bar"
                  element={<ContextualSaveBarEx />}
                ></Route>
                <Route
                  path="/selection-and-input/date-picker/default"
                  element={<DateDefault />}
                ></Route>
                <Route
                  path="/selection-and-input/date-picker/ranged"
                  element={<Ranged />}
                ></Route>
                <Route
                  path="/selection-and-input/date-picker/multi-month-ranged"
                  element={<MultiMonthRanged />}
                ></Route>
                <Route
                  path="/selection-and-input/date-picker/with-disabled-date-ranges"
                  element={<WithDisabledDateRanges />}
                ></Route>
                <Route
                  path="/selection-and-input/date-picker/with-specific-disabled-dates"
                  element={<WithSpecificDisabledDates />}
                ></Route>
                <Route
                  path="/selection-and-input/drop-zone/default"
                  element={<DropZoneDefault />}
                ></Route>
                <Route
                  path="/selection-and-input/drop-zone/with-a-label"
                  element={<WithaLabel />}
                ></Route>
                <Route
                  path="/selection-and-input/drop-zone/with-image-file-upload"
                  element={<WithImageFileUpload />}
                ></Route>
                <Route
                  path="/selection-and-input/drop-zone/with-single-file-upload"
                  element={<WithSingleFileUpload />}
                ></Route>
                <Route
                  path="/selection-and-input/drop-zone/with-drop-on-page"
                  element={<WithDropOnPage />}
                ></Route>
                <Route
                  path="/selection-and-input/drop-zone/accepts-only-SVG-files"
                  element={<AcceptsOnlySvgFiles />}
                ></Route>
                <Route
                  path="/selection-and-input/drop-zone/nested"
                  element={<Nested />}
                ></Route>
                <Route
                  path="/selection-and-input/drop-zone/medium-sized"
                  element={<MediumSized />}
                ></Route>
                <Route
                  path="/selection-and-input/drop-zone/small-sized"
                  element={<SmallSized />}
                ></Route>
                <Route
                  path="/selection-and-input/drop-zone/with-custom-FileUpload-text"
                  element={<WithCustomFileUploadText />}
                ></Route>
                <Route
                  path="/selection-and-input/drop-zone/with-custom-file-dialog-trigger"
                  element={<WithCustomFileDialogTrigger />}
                ></Route>
                <Route
                  path="/selection-and-input/filters/with-a-resource-list"
                  element={<WithaResourceList />}
                ></Route>
                <Route
                  path="/selection-and-input/filters/with-a-data-table"
                  element={<WithaDataTable />}
                ></Route>
                <Route
                  path="/selection-and-input/filters/with-children-content"
                  element={<FiltersWithChildrenContent />}
                ></Route>
                <Route
                  path="/selection-and-input/filters/disabled"
                  element={<FiltersDisabled />}
                ></Route>
                <Route
                  path="/selection-and-input/filters/with-some-disabled"
                  element={<WithSomeDisabled />}
                ></Route>
                <Route
                  path="/selection-and-input/filters/with-query-field-hidden"
                  element={<WithQueryFieldHidden />}
                ></Route>
                <Route
                  path="/selection-and-input/filters/with-query-field-disabled"
                  element={<WithQueryFieldDisabled />}
                ></Route>
                <Route
                  path="/selection-and-input/form/custom-onsubmit"
                  element={<CustomOnSubmit />}
                ></Route>
                <Route
                  path="/selection-and-input/form/without-native-validation"
                  element={<WithoutNativeValidation />}
                ></Route>
                <Route
                  path="/selection-and-input/index-filters/default"
                  element={<IndexFiltersDefault />}
                ></Route>
                <Route
                  path="/selection-and-input/index-filters/with-pinned-filters"
                  element={<WithPinnedFilters />}
                ></Route>
                <Route
                  path="/selection-and-input/index-filters/filtering-mode-default"
                  element={<FilteringModeDefault />}
                ></Route>
                <Route
                  path="/selection-and-input/index-filters/disabled"
                  element={<IndexFiltersDisabled />}
                ></Route>
                <Route
                  path="/selection-and-input/index-filters/with-no-filters"
                  element={<WithNoFilters />}
                ></Route>
                <Route
                  path="/selection-and-input/index-filters/with-no-search-or-filters"
                  element={<WithNoSearchOrFilters />}
                ></Route>
                <Route
                  path="/selection-and-input/inline-error"
                  element={<InlineErrorDefault />}
                ></Route>
                <Route
                  path="/selection-and-input/radio-button"
                  element={<RadioButtonDefault />}
                ></Route>
                <Route
                  path="/selection-and-input/range-slider/default"
                  element={<RangeSliderDefault />}
                ></Route>
                <Route
                  path="/selection-and-input/range-slider/with-min-and-max"
                  element={<WithMinAndMax />}
                ></Route>
                <Route
                  path="/selection-and-input/range-slider/with-steps"
                  element={<WithSteps />}
                ></Route>
                <Route
                  path="/selection-and-input/range-slider/with-prefix-and-suffix"
                  element={<WithPrefixAndSuffix />}
                ></Route>
                <Route
                  path="/selection-and-input/range-slider/with-dual-thumb"
                  element={<WithDualThumb />}
                ></Route>
                <Route
                  path="/selection-and-input/select/default"
                  element={<SelectDefault />}
                ></Route>
                <Route
                  path="/selection-and-input/select/with-inline-label"
                  element={<WithInlineLabel />}
                ></Route>
                <Route
                  path="/selection-and-input/select/disabled"
                  element={<SelectDisabled />}
                ></Route>
                <Route
                  path="/selection-and-input/select/with-prefix"
                  element={<WithPrefix />}
                ></Route>
                <Route
                  path="/selection-and-input/select/with-validation-error"
                  element={<WithValidationError />}
                ></Route>
                <Route
                  path="/selection-and-input/select/with-separate-validation-error"
                  element={<WithSeparateValidationError />}
                ></Route>
                <Route
                  path="/selection-and-input/tag/tag-example"
                  element={<TagEx />}
                ></Route>
                <Route
                  path="/selection-and-input/tag/removable"
                  element={<Removable />}
                ></Route>
                <Route
                  path="/selection-and-input/tag/removable-with-link"
                  element={<RemovableWithLink />}
                ></Route>
                <Route
                  path="/selection-and-input/text-field/default"
                  element={<TextFieldDefault />}
                ></Route>
                <Route
                  path="/selection-and-input/text-field/number"
                  element={<Number />}
                ></Route>
                <Route
                  path="/selection-and-input/text-field/email"
                  element={<Email />}
                ></Route>
                <Route
                  path="/selection-and-input/text-field/multiline"
                  element={<Multiline />}
                ></Route>
                <Route
                  path="/selection-and-input/text-field/with-hidden-label"
                  element={<WithHiddenLabel />}
                ></Route>
                <Route
                  path="/selection-and-input/text-field/with-label-action"
                  element={<WithLabelAction />}
                ></Route>
                <Route
                  path="/selection-and-input/text-field/with-right-aligned-text"
                  element={<WithRightAlignedText />}
                ></Route>
                <Route
                  path="/selection-and-input/text-field/with-placeholder-text"
                  element={<WithPlaceholderText />}
                ></Route>
                <Route
                  path="/selection-and-input/text-field/with-help-text"
                  element={<WithHelpText />}
                ></Route>
                <Route
                  path="/selection-and-input/text-field/with-prefix-or-suffix"
                  element={<WithPrefixOrSuffix />}
                ></Route>
                <Route
                  path="/selection-and-input/text-field/with-vertical-content"
                  element={<WithVerticalContent />}
                ></Route>
                <Route
                  path="/selection-and-input/text-field/with-connected-fields"
                  element={<WithConnectedFields />}
                ></Route>
                <Route
                  path="/selection-and-input/text-field/with-validation-error"
                  element={<TextFieldWithValidationError />}
                ></Route>
                <Route
                  path="/selection-and-input/text-field/with-separate-validation-error"
                  element={<TextFieldWithSeparateValidationError />}
                ></Route>
                <Route
                  path="/selection-and-input/text-field/disabled"
                  element={<TextFieldDisabled />}
                ></Route>
                <Route
                  path="/selection-and-input/text-field/with-character-count"
                  element={<WithCharacterCount />}
                ></Route>
                <Route
                  path="/selection-and-input/text-field/with-clear-button"
                  element={<WithClearButton />}
                ></Route>
                <Route
                  path="/selection-and-input/text-field/with-monospaced-font"
                  element={<WithMonospacedFont />}
                ></Route>
                <Route
                  path="/selection-and-input/text-field/with-value-selected-on-focus"
                  element={<WithValueSelectedOnFocus />}
                ></Route>
                <Route
                  path="/selection-and-input/text-field/with-inline-suggestion"
                  element={<WithInlineSuggestion />}
                ></Route>
          {/* Images and icons */}
                <Route
                  path="/images-and-icons/avatar"
                  element={<AvatarEx />}
                ></Route>
                <Route
                  path="/images-and-icons/icon"
                  element={<IconEx />}
                ></Route>
                <Route
                  path="/images-and-icons/keyboard-key"
                  element={<KeyboardKeyEx />}
                ></Route>
                <Route
                  path="/images-and-icons/thumbnail"
                  element={<ThumbnailEx />}
                ></Route>
                <Route
                  path="/images-and-icons/video-thumbnail"
                  element={<VideoThumbnailEx />}
                ></Route>
          {/* Feedback indicators */}
                <Route
                  path="/feedback-indicators/badge"
                  element={<BadgeEx />}
                ></Route>
                <Route
                  path="/feedback-indicators/banner"
                  element={<BannerEx />}
                ></Route>
                <Route
                  path="/feedback-indicators/exception-list"
                  element={<ExceptionListEx />}
                ></Route>
                <Route
                  path="/feedback-indicators/loading"
                  element={<Loadingex />}
                ></Route>
                <Route
                  path="/feedback-indicators/progress-bar"
                  element={<ProgressBarEx />}
                ></Route>
                <Route
                  path="/feedback-indicators/skeleton-body-text"
                  element={<SkeletonBodyTextEx />}
                ></Route>
                <Route
                  path="/feedback-indicators/skeleton-display-text"
                  element={<SkeletonDisplayTextEx />}
                ></Route>
                <Route
                  path="/feedback-indicators/skeleton-page"
                  element={<SkeletonPageEx />}
                ></Route>
                <Route
                  path="/feedback-indicators/skeleton-tabs"
                  element={<SkeletonTabsEx />}
                ></Route>
                <Route
                  path="/feedback-indicators/skeleton-thumbnail"
                  element={<SkeletonThumbnailEx />}
                ></Route>
                <Route
                  path="/feedback-indicators/spinner"
                  element={<SpinnerEx />}
                ></Route>
                <Route
                  path="/feedback-indicators/toast/default"
                  element={<ToastDefault />}
                ></Route>
                <Route
                  path="/feedback-indicators/toast/multiple-messages"
                  element={<ToastMultipleMessages />}
                ></Route>
                <Route
                  path="/feedback-indicators/toast/with-custom-duration"
                  element={<ToastWithCustomDuration />}
                ></Route>
                <Route
                  path="/feedback-indicators/toast/with-action"
                  element={<ToastWithAction />}
                ></Route>
                <Route
                  path="/feedback-indicators/toast/error"
                  element={<ToastError />}
                ></Route>
          {/* Typography */}
                <Route path="/typography/text" element={<TextEx />}></Route>
          {/* Tables */}
                <Route
                  path="/tables/data-table/default"
                  element={<DataTableDefault />}
                ></Route>
                <Route
                  path="/tables/data-table/sortable"
                  element={<Sortable />}
                ></Route>
                <Route
                  path="/tables/data-table/with-footer"
                  element={<WithFooter />}
                ></Route>
                <Route
                  path="/tables/data-table/with-custom-totals-heading"
                  element={<WithCustomTotalsHeading />}
                ></Route>
                <Route
                  path="/tables/data-table/with-totals-in-footer"
                  element={<WithTotalsInFooter />}
                ></Route>
                <Route
                  path="/tables/data-table/with-row-heading-links"
                  element={<WithRowHeadingLinks />}
                ></Route>
                <Route
                  path="/tables/data-table/with-all-of-its-elements"
                  element={<WithAllOfItsElements />}
                ></Route>
                <Route
                  path="/tables/data-table/with-fixed-first-columns"
                  element={<WithFixedFirstColumns />}
                ></Route>
                <Route
                  path="/tables/data-table/with-increased-density-and-zebra-striping"
                  element={<WithIncreasedDensityAndZebraStriping />}
                ></Route>
                <Route
                  path="/tables/data-table/with-sticky-header-enabled"
                  element={<WithStickyHeaderEnabled />}
                ></Route>
                <Route
                  path="/tables/index-table/default"
                  element={<IndexTableDefault />}
                ></Route>
                <Route
                  path="/tables/index-table/with-saved-views-search-filtering-and-sorting"
                  element={<WithSavedViewsSearchFilteringAndSorting />}
                ></Route>
                <Route
                  path="/tables/index-table/with-disabled-rows"
                  element={<WithDisabledRows />}
                ></Route>
                <Route
                  path="/tables/index-table/small-screen"
                  element={<SmallScreen />}
                ></Route>
                <Route
                  path="/tables/index-table/small-screen-with-saved-views-search-filtering-and-sorting"
                  element={
                    <SmallScreenWithSavedViewsSearchFilteringAndSorting />
                  }
                ></Route>
                <Route
                  path="/tables/index-table/with-empty-state"
                  element={<IndexTableWithEmptyState />}
                ></Route>
                <Route
                  path="/tables/index-table/with-bulk-actions"
                  element={<WithBulkActions />}
                ></Route>
                <Route
                  path="/tables/index-table/with-multiple-promoted-bulk-actions"
                  element={<WithMultiplePromotedBulkActions />}
                ></Route>
                <Route
                  path="/tables/index-table/with-bulk-actions-and-selection-across-pages"
                  element={<WithBulkActionsAndSelectionAcrossPages />}
                ></Route>
                <Route
                  path="/tables/index-table/with-loading-state"
                  element={<WithLoadingState />}
                ></Route>
                <Route
                  path="/tables/index-table/with-filtering"
                  element={<WithFiltering />}
                ></Route>
                <Route
                  path="/tables/index-table/with-sticky-last-column"
                  element={<WithStickyLastColumn />}
                ></Route>
                <Route
                  path="/tables/index-table/with-row-navigation-link"
                  element={<WithRowNavigationLink />}
                ></Route>
                <Route
                  path="/tables/index-table/without-checkboxes"
                  element={<WithoutCheckboxes />}
                ></Route>
          {/* Lists */}
                <Route
                  path="/lists/action-list/in-a-popover"
                  element={<InaPopover />}
                ></Route>
                <Route
                  path="/lists/action-list/with-icons-or-image"
                  element={<WithIconsOrImage />}
                ></Route>
                <Route
                  path="/lists/action-list/with-an-icon-and-a-suffix"
                  element={<WithAnIconAndaSuffix />}
                ></Route>
                <Route
                  path="/lists/action-list/with-sections"
                  element={<WithSections />}
                ></Route>
                <Route
                  path="/lists/action-list/with-destructive-item"
                  element={<WithDestructiveItem />}
                ></Route>
                <Route
                  path="/lists/action-list/with-help-text"
                  element={<ActionListWithHelpText />}
                ></Route>
                <Route
                  path="/lists/action-list/with-a-prefix-and-a-suffix"
                  element={<WithaPrefixAndaSuffix />}
                ></Route>
                <Route
                  path="/lists/action-list/with-a-prefix-and-a-suffix"
                  element={<WithaPrefixAndaSuffix />}
                ></Route>
                <Route
                  path="/lists/description-list"
                  element={<DescriptionListDefault />}
                ></Route>
                <Route path="/lists/list" element={<ListEx />}></Route>
                <Route
                  path="/lists/listbox/listbox-example"
                  element={<ListBoxEx />}
                ></Route>
                <Route
                  path="/lists/listbox/with-search"
                  element={<WithSearch />}
                ></Route>
                <Route
                  path="/lists/option-list/option-list-example"
                  element={<OptionListEx />}
                ></Route>
                <Route
                  path="/lists/option-list/in-a-popover"
                  element={<OptionListInaPopover />}
                ></Route>
                <Route
                  path="/lists/resource-item"
                  element={<ResourceItemEx />}
                ></Route>
                <Route
                  path="/lists/resource-list/default"
                  element={<ResourceListDefault />}
                ></Route>
                <Route
                  path="/lists/resource-list/with-empty-state"
                  element={<ResourceListWithEmptyState />}
                ></Route>
                <Route
                  path="/lists/resource-list/with-selection-and-no-bulk-actions"
                  element={<WithSelectionAndNoBulkActions />}
                ></Route>
                <Route
                  path="/lists/resource-list/with-bulk-actions"
                  element={<ResourceListWithBulkActions />}
                ></Route>
                <Route
                  path="/lists/resource-list/with-loading-state"
                  element={<ResourceListWithLoadingState />}
                ></Route>
                <Route
                  path="/lists/resource-list/with-total-count"
                  element={<WithTotalCount />}
                ></Route>
                <Route
                  path="/lists/resource-list/with-sorting"
                  element={<WithSorting />}
                ></Route>
                <Route
                  path="/lists/resource-list/with-alternate-tool"
                  element={<WithAlternateTool />}
                ></Route>
                <Route
                  path="/lists/resource-list/with-filtering"
                  element={<ResourceListWithFiltering />}
                ></Route>
                <Route
                  path="/lists/resource-list/with-a-custom-empty-search-result-state"
                  element={<WithaCustomEmptySearchResultState />}
                ></Route>
                <Route
                  path="/lists/resource-list/with-item-shortcut-actions"
                  element={<WithItemShortcutActions />}
                ></Route>
                <Route
                  path="/lists/resource-list/with-persistent-item-shortcut-actions"
                  element={<WithPersistentItemShortcutActions />}
                ></Route>
                <Route
                  path="/lists/resource-list/with-multiselect"
                  element={<ResourceListWithMultiselect />}
                ></Route>
                <Route
                  path="/lists/resource-list/with-all-of-its-elements"
                  element={<ResourceListWithAllOfItsElements />}
                ></Route>
          {/* Navigation */}
                <Route
                  path="/navigation/footer-help"
                  element={<FooterHelpEx />}
                ></Route>
                <Route
                  path="/navigation/fullscreen-bar/with-children"
                  element={<FullscreenBarWithChildren />}
                ></Route>
                <Route
                  path="/navigation/fullscreen-bar/no-children"
                  element={<FullscreenBarNoChildren />}
                ></Route>
                <Route
                  path="/navigation/legacy-tabs/default"
                  element={<LegacyTabsDefault />}
                ></Route>
                <Route
                  path="/navigation/legacy-tabs/fitted"
                  element={<LegacyTabsFitted />}
                ></Route>
                <Route
                  path="/navigation/legacy-tabs/with-badge-content"
                  element={<LegacyTabsWithBadgeContent />}
                ></Route>
                <Route
                  path="/navigation/legacy-tabs/with-custom-disclosure"
                  element={<LegacyTabsWithCustomDisclosure />}
                ></Route>
                <Route path="/navigation/link" element={<LinkEx />}></Route>
                <Route
                  path="/navigation/navigations"
                  element={<NavigationEx />}
                ></Route>
                <Route
                  path="/navigation/pagination"
                  element={<PaginationEx />}
                ></Route>
                <Route
                  path="/navigation/tabs/default"
                  element={<TabsDefault />}
                ></Route>
                <Route
                  path="/navigation/tabs/inside-of-a-Card"
                  element={<TabsInsideOfCard />}
                ></Route>
                <Route
                  path="/navigation/tabs/fitted"
                  element={<TabsFitted />}
                ></Route>
                <Route
                  path="/navigation/tabs/with-actions"
                  element={<TabsWithActions />}
                ></Route>
                <Route
                  path="/navigation/tabs/with-badge-content"
                  element={<TabsWithBadgeContent />}
                ></Route>
                <Route
                  path="/navigation/tabs/with-custom-disclosure"
                  element={<TabsWithCustomDisclosure />}
                ></Route>
                <Route
                  path="/navigation/top-bar"
                  element={<TopBarEx />}
                ></Route>
          {/* Overlays */}
                <Route
                  path="/overlays/modal/default"
                  element={<ModalDefault />}
                ></Route>
                <Route
                  path="/overlays/modal/with-primary-action"
                  element={<WithPrimaryAction />}
                ></Route>
                <Route
                  path="/overlays/modal/with-primary-and-secondary-actions"
                  element={<WithPrimaryAndSecondaryActions />}
                ></Route>
                <Route path="/overlays/modal/large" element={<Large />}></Route>
                <Route path="/overlays/modal/small" element={<Small />}></Route>
                <Route
                  path="/overlays/modal/without-a-title"
                  element={<WithoutaTitle />}
                ></Route>
                <Route
                  path="/overlays/modal/with-scroll-listener"
                  element={<WithScrollListener />}
                ></Route>
                <Route
                  path="/overlays/modal/with-activator-ref"
                  element={<WithActivatorRef />}
                ></Route>
                <Route
                  path="/overlays/modal/without-an-activator-prop"
                  element={<WithoutAnActivatorProp />}
                ></Route>
                <Route
                  path="/overlays/popover/with-action-list"
                  element={<WithActionList />}
                ></Route>
                <Route
                  path="/overlays/popover/with-content-and-actions"
                  element={<WithContentAndActions />}
                ></Route>
                <Route
                  path="/overlays/popover/with-form-components"
                  element={<WithFormComponents />}
                ></Route>
                <Route
                  path="/overlays/popover/with-lazy-loaded-list"
                  element={<WithLazyLoadedList />}
                ></Route>
                <Route
                  path="/overlays/popover/with-searchable-listbox"
                  element={<WithSearchableListbox />}
                ></Route>
                <Route path="/overlays/tooltip" element={<TooltipEx />}></Route>
          {/* Utilities */}
                <Route
                  path="/utilities/app-provider"
                  element={<AppProviderEx />}
                ></Route>
                <Route
                  path="/utilities/collapsible"
                  element={<CollapsibleEx />}
                ></Route>
                <Route
                  path="/utilities/frame/in-an-application"
                  element={<InAnApplication />}
                ></Route>
                <Route
                  path="/utilities/frame/with-an-offset"
                  element={<WithAnOffset />}
                ></Route>
                <Route
                  path="/utilities/scrollable"
                  element={<ScrollableEx />}
                ></Route>
          {/* Deprecated */}
                <Route
                  path="/deprecated/legacy-filters/with-a-resource-list"
                  element={<LegacyFiltersWithaResourceList />}
                ></Route>
                <Route
                  path="/deprecated/legacy-filters/with-a-data-table"
                  element={<LegacyFiltersWithaDataTable />}
                ></Route>
                <Route
                  path="/deprecated/legacy-filters/with-children-content"
                  element={<LegacyFiltersWithChildrenContent />}
                ></Route>
                <Route
                  path="/deprecated/legacy-filters/disabled"
                  element={<LegacyFiltersDisabled />}
                ></Route>
                <Route
                  path="/deprecated/legacy-filters/some-disabled"
                  element={<SomeDisabled />}
                ></Route>
                <Route
                  path="/deprecated/legacy-filters/without-clear-button"
                  element={<WithoutClearButton />}
                ></Route>
                <Route
                  path="/deprecated/legacy-filters/with-help-text"
                  element={<LegacyFiltersWithHelpText />}
                ></Route>
                <Route
                  path="/deprecated/legacy-filters/with-query-field-hidden"
                  element={<LegacyFiltersWithQueryFieldHidden />}
                ></Route>
                <Route
                  path="/deprecated/legacy-filters/with-query-field-disabled"
                  element={<LegacyFiltersWithQueryFieldDisabled />}
                ></Route>
                <Route
                  path="/deprecated/setting-toggle/with-primitive-components"
                  element={<WithPrimitiveComponents />}
                ></Route>
                <Route
                  path="/deprecated/setting-toggle/with-deprecated-component"
                  element={<WithDeprecatedComponent />}
                ></Route>
                <Route
                  path="/deprecated/sheet/default"
                  element={<SheetDefault />}
                ></Route>
                <Route
                  path="/deprecated/sheet/with-searchable-listbox"
                  element={<SheetWithSearchableListbox />}
                ></Route>
                <Route
                  path="/deprecated/text-container"
                  element={<TextContainerEx />}
                ></Route>
              </Routes>
            </div>
          </div>
        </Router>
      </AppProvider>
    );
  }
}
export default App;
