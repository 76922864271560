import React from 'react'
import {Page, Grid, LegacyCard} from '@shopify/polaris';

function GridEx() {
  return (
    <Page fullWidth>


        {/* Two column - do not use margin-top div*/}
        <div className='margin-top'>Two column
            <Grid>
                <Grid.Cell columnSpan={{xs: 6, sm: 3, md: 3, lg: 6, xl: 6}}>
                <LegacyCard title="Sales" sectioned>
                    <p>View a summary of your online store’s sales.</p>
                </LegacyCard>
                </Grid.Cell>
                <Grid.Cell columnSpan={{xs: 6, sm: 3, md: 3, lg: 6, xl: 6}}>
                <LegacyCard title="Orders" sectioned>
                    <p>View a summary of your online store’s orders.</p>
                </LegacyCard>
                </Grid.Cell>
            </Grid>
        </div>



        {/* Two-thirds and one-third column - do not use margin-top div*/}
        <div className='margin-top'>Two-thirds and one-third column
            <Grid columns={{sm: 3}}>
                <Grid.Cell columnSpan={{xs: 6, sm: 4, md: 4, lg: 8, xl: 8}}>
                <LegacyCard title="Sales" sectioned>
                    <p>View a summary of your online store’s sales.</p>
                </LegacyCard>
                </Grid.Cell>
                <Grid.Cell columnSpan={{xs: 6, sm: 2, md: 2, lg: 4, xl: 4}}>
                <LegacyCard title="Orders" sectioned>
                    <p>View a summary of your online store’s orders.</p>
                </LegacyCard>
                </Grid.Cell>
           </Grid>
        </div>


        {/* Three one-third column - do not use margin-top div*/}
        <div className='margin-top'>Three one-third column
            <Grid columns={{sm: 3}}>
                <Grid.Cell columnSpan={{xs: 6, sm: 4, md: 4, lg: 8, xl: 8}}>
                <LegacyCard title="Sales" sectioned>
                    <p>View a summary of your online store’s sales.</p>
                </LegacyCard>
                </Grid.Cell>
                <Grid.Cell columnSpan={{xs: 6, sm: 2, md: 2, lg: 4, xl: 4}}>
                <LegacyCard title="Orders" sectioned>
                    <p>View a summary of your online store’s orders.</p>
                </LegacyCard>
                </Grid.Cell>
            </Grid>
        </div>


        {/* Custom layout - do not use margin-top div*/}
        <div className='margin-top'>Custom layout
            <LegacyCard sectioned>
                <Grid
                columns={{xs: 1, sm: 4, md: 4, lg: 6, xl: 6}}
                areas={{
                    xs: ['product', 'sales', 'orders'],
                    sm: [
                    'product product product product',
                    'sales sales orders orders',
                    ],
                    md: ['sales product product orders'],
                    lg: ['product product product product sales orders'],
                    xl: ['product product sales sales orders orders'],
                }}
                >
                <Grid.Cell area="product">
                    <Placeholder height="60px" />
                </Grid.Cell>
                <Grid.Cell area="sales">
                    <Placeholder height="60px" />
                </Grid.Cell>
                <Grid.Cell area="orders">
                    <Placeholder height="60px" />
                </Grid.Cell>
                </Grid>
            </LegacyCard>
        </div>
    </Page>
  )
}


//Custom layout 
const Placeholder = ({height = 'auto', width = 'auto'}) => {
    return (
      <div
        style={{
          background: 'var(--p-color-text-info)',
          height: height,
          width: width,
        }}
      />
    )
  }

export default GridEx
