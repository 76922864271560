import React from 'react'
import {Card, Page, Text, VerticalStack} from '@shopify/polaris';

function CardEx() {
  return (
    <Page>


        {/* Default - do not use margin-top div*/}
        <div className='margin-top'>1) Default</div>
            <Card>
                <Text as="h2" variant="bodyMd">
                    Content inside a card
                </Text>
            </Card>
        


        {/*With subdued background  - do not use margin-top div*/}
        <div className='margin-top'>2) With subdued background</div>
            <Card background="bg-subdued">
                <Text as="h2" variant="bodyMd">
                    Content inside a card
                </Text>
            </Card>
        



        {/* With varying padding - do not use margin-top div*/}
        <div className='margin-top'>3) With varying padding</div>
            <VerticalStack gap="4">
                <Card>
                    <Placeholder label="Content inside a card" />
                </Card>
                <Card padding="4">
                    <Placeholder label="Content inside a card" />
                </Card>
                <Card padding="2">
                    <Placeholder label="Content inside a card" />
                </Card>
                <Card padding="0">
                    <Placeholder label="Content inside a card" />
                </Card>
            </VerticalStack>
        


        {/* Rounded corners - do not use margin-top div*/}
        <div className='margin-top'>4) Rounded corners</div>
            <Card roundedAbove="md" background="bg-subdued">
                <Text as="h2" variant="bodyMd">
                    Content inside a card
                </Text>
           </Card>
           
    </Page>

      
  )
}


// with-varying-padding
const Placeholder = ({label = '', height = 'auto', width = 'auto'}) => {
    return (
      <div
        style={{
          background: 'var(--p-color-text-info)',
          height: height,
          width: width,
        }}
      >
        <div
          style={{
            color: 'var(--p-color-text-on-color)',
          }}
        >
          <Text as="h2" variant="bodyMd">
            {label}
          </Text>
        </div>
      </div>
    );
  };

export default CardEx
