import React from 'react'
import {Page, Layout, LegacyCard,  ResourceList, Thumbnail, Text, FormLayout, TextField, TextContainer, Banner} from '@shopify/polaris'

function LayoutEx() {
    return (
        <>
        {/* One-column - do not use span tag */}
        <span>1) One-column </span>
        <Page fullWidth>
          <Layout>
            <Layout.Section>
              <LegacyCard title="Online store dashboard" sectioned>
                <p>View a summary of your online store’s performance.</p>
              </LegacyCard>
            </Layout.Section>
          </Layout>
        </Page>
         

        {/* Two columns with primary and secondary widths - do not use span tag*/} 
        <span> 2) Two columns with primary and secondary widths</span>
         <Page fullWidth>
            <Layout>
                <Layout.Section>
                <LegacyCard title="Order details" sectioned>
                    <p>
                    Use to follow a normal section with a secondary section to create
                    a 2/3 + 1/3 layout on detail pages (such as individual product or
                    order pages). Can also be used on any page that needs to structure
                    a lot of content. This layout stacks the columns on small screens.
                    </p>
                </LegacyCard>
                </Layout.Section>
                <Layout.Section secondary>
                <LegacyCard title="Tags" sectioned>
                    <p>Add tags to your order.</p>
                </LegacyCard>
                </Layout.Section>
            </Layout>
        </Page>


    {/* Two columns with equal width - do not use span tag*/}
    <span> 3) Two columns with equal width</span>
    <Page fullWidth>
        <Layout>
            <Layout.Section oneHalf>
                <LegacyCard title="Florida" actions={[{content: 'Manage'}]}>
                    <LegacyCard.Section>
                    <Text color="subdued" as="span">
                        455 units available
                    </Text>
                    </LegacyCard.Section>
                    <LegacyCard.Section title="Items">
                    <ResourceList
                        resourceName={{singular: 'product', plural: 'products'}}
                        items={[
                        {
                            id: '341',
                            url: '#',
                            name: 'Black & orange scarf',
                            sku: '9234194023',
                            quantity: '254',
                            media: (
                            <Thumbnail
                                source="https://burst.shopifycdn.com/photos/black-orange-stripes_373x@2x.jpg"
                                alt="Black orange scarf"
                            />
                            ),
                        },
                        {
                            id: '256',
                            url: '#',
                            name: 'Tucan scarf',
                            sku: '9234194010',
                            quantity: '201',
                            media: (
                            <Thumbnail
                                source="https://burst.shopifycdn.com/photos/tucan-scarf_373x@2x.jpg"
                                alt="Tucan scarf"
                            />
                            ),
                        },
                        ]}
                        renderItem={(item) => {
                        const {id, url, name, sku, media, quantity} = item;

                        return (
                            <ResourceList.Item
                            id={id}
                            url={url}
                            media={media}
                            accessibilityLabel={`View details for ${name}`}
                            >
                            <Text variant="bodyMd" fontWeight="bold" as="h3">
                                {name}
                            </Text>
                            <div>SKU: {sku}</div>
                            <div>{quantity} available</div>
                            </ResourceList.Item>
                        );
                        }}
                    />
                    </LegacyCard.Section>
                </LegacyCard>
                </Layout.Section>
                <Layout.Section oneHalf>
                <LegacyCard title="Nevada" actions={[{content: 'Manage'}]}>
                    <LegacyCard.Section>
                    <Text color="subdued" as="span">
                        301 units available
                    </Text>
                    </LegacyCard.Section>
                    <LegacyCard.Section title="Items">
                    <ResourceList
                        resourceName={{singular: 'product', plural: 'products'}}
                        items={[
                        {
                            id: '342',
                            url: '#',
                            name: 'Black & orange scarf',
                            sku: '9234194023',
                            quantity: '100',
                            media: (
                            <Thumbnail
                                source="https://burst.shopifycdn.com/photos/black-orange-stripes_373x@2x.jpg"
                                alt="Black orange scarf"
                            />
                            ),
                        },
                        {
                            id: '257',
                            url: '#',
                            name: 'Tucan scarf',
                            sku: '9234194010',
                            quantity: '201',
                            media: (
                            <Thumbnail
                                source="https://burst.shopifycdn.com/photos/tucan-scarf_373x@2x.jpg"
                                alt="Tucan scarf"
                            />
                            ),
                        },
                        ]}
                        renderItem={(item) => {
                        const {id, url, name, sku, media, quantity} = item;

                        return (
                            <ResourceList.Item
                            id={id}
                            url={url}
                            media={media}
                            accessibilityLabel={`View details for ${name}`}
                            >
                            <Text variant="bodyMd" fontWeight="bold" as="h3">
                                {name}
                            </Text>
                            <div>SKU: {sku}</div>
                            <div>{quantity} available</div>
                            </ResourceList.Item>
                        );
                        }}
                    />
                    </LegacyCard.Section>
                </LegacyCard>
            </Layout.Section>
      </Layout>
    </Page>


    {/* Three columns with equal width - do not use span tag*/}
    <span> 4) Three columns with equal width</span>
    <Page fullWidth>
      <Layout>
        <Layout.Section oneThird>
          <LegacyCard title="Florida" actions={[{content: 'Manage'}]}>
            <LegacyCard.Section>
              <Text color="subdued" as="span">
                455 units available
              </Text>
            </LegacyCard.Section>
            <LegacyCard.Section title="Items">
              <ResourceList
                resourceName={{singular: 'product', plural: 'products'}}
                items={[
                  {
                    id: '343',
                    url: '#',
                    name: 'Black & orange scarf',
                    sku: '9234194023',
                    quantity: '254',
                    media: (
                      <Thumbnail
                        source="https://burst.shopifycdn.com/photos/black-orange-stripes_373x@2x.jpg"
                        alt="Black orange scarf"
                      />
                    ),
                  },
                  {
                    id: '258',
                    url: '#',
                    name: 'Tucan scarf',
                    sku: '9234194010',
                    quantity: '201',
                    media: (
                      <Thumbnail
                        source="https://burst.shopifycdn.com/photos/tucan-scarf_373x@2x.jpg"
                        alt="Tucan scarf"
                      />
                    ),
                  },
                ]}
                renderItem={(item) => {
                  const {id, url, name, sku, media, quantity} = item;

                  return (
                    <ResourceList.Item
                      id={id}
                      url={url}
                      media={media}
                      accessibilityLabel={`View details for ${name}`}
                    >
                      <Text variant="bodyMd" fontWeight="bold" as="h3">
                        {name}
                      </Text>
                      <div>SKU: {sku}</div>
                      <div>{quantity} available</div>
                    </ResourceList.Item>
                  );
                }}
              />
            </LegacyCard.Section>
          </LegacyCard>
        </Layout.Section>
        <Layout.Section oneThird>
          <LegacyCard title="Nevada" actions={[{content: 'Manage'}]}>
            <LegacyCard.Section>
              <Text color="subdued" as="span">
                301 units available
              </Text>
            </LegacyCard.Section>
            <LegacyCard.Section title="Items">
              <ResourceList
                resourceName={{singular: 'product', plural: 'products'}}
                items={[
                  {
                    id: '344',
                    url: '#',
                    name: 'Black & orange scarf',
                    sku: '9234194023',
                    quantity: '100',
                    media: (
                      <Thumbnail
                        source="https://burst.shopifycdn.com/photos/black-orange-stripes_373x@2x.jpg"
                        alt="Black orange scarf"
                      />
                    ),
                  },
                  {
                    id: '259',
                    url: '#',
                    name: 'Tucan scarf',
                    sku: '9234194010',
                    quantity: '201',
                    media: (
                      <Thumbnail
                        source="https://burst.shopifycdn.com/photos/tucan-scarf_373x@2x.jpg"
                        alt="Tucan scarf"
                      />
                    ),
                  },
                ]}
                renderItem={(item) => {
                  const {id, url, name, sku, media, quantity} = item;

                  return (
                    <ResourceList.Item
                      id={id}
                      url={url}
                      media={media}
                      accessibilityLabel={`View details for ${name}`}
                    >
                      <Text variant="bodyMd" fontWeight="bold" as="h3">
                        {name}
                      </Text>
                      <div>SKU: {sku}</div>
                      <div>{quantity} available</div>
                    </ResourceList.Item>
                  );
                }}
              />
            </LegacyCard.Section>
          </LegacyCard>
        </Layout.Section>
        <Layout.Section oneThird>
          <LegacyCard title="Minneapolis" actions={[{content: 'Manage'}]}>
            <LegacyCard.Section>
              <Text color="subdued" as="span">
                1931 units available
              </Text>
            </LegacyCard.Section>
            <LegacyCard.Section title="Items">
              <ResourceList
                resourceName={{singular: 'product', plural: 'products'}}
                items={[
                  {
                    id: '345',
                    url: '#',
                    name: 'Black & orange scarf',
                    sku: '9234194023',
                    quantity: '1230',
                    media: (
                      <Thumbnail
                        source="https://burst.shopifycdn.com/photos/black-orange-stripes_373x@2x.jpg"
                        alt="Black orange scarf"
                      />
                    ),
                  },
                  {
                    id: '260',
                    url: '#',
                    name: 'Tucan scarf',
                    sku: '9234194010',
                    quantity: '701',
                    media: (
                      <Thumbnail
                        source="https://burst.shopifycdn.com/photos/tucan-scarf_373x@2x.jpg"
                        alt="Tucan scarf"
                      />
                    ),
                  },
                ]}
                renderItem={(item) => {
                  const {id, url, name, sku, media, quantity} = item;

                  return (
                    <ResourceList.Item
                      id={id}
                      url={url}
                      media={media}
                      accessibilityLabel={`View details for ${name}`}
                    >
                      <Text variant="bodyMd" fontWeight="bold" as="h3">
                        {name}
                      </Text>
                      <div>SKU: {sku}</div>
                      <div>{quantity} available</div>
                    </ResourceList.Item>
                  );
                }}
              />
            </LegacyCard.Section>
          </LegacyCard>
        </Layout.Section>
      </Layout>
    </Page>


    {/*Annotated - do not use span tag*/}
    <span> 5) Annotated</span>
    <Page fullWidth>
      <Layout>
        <Layout.AnnotatedSection
          id="storeDetails"
          title="Store details"
          description="Shopify and your customers will use this information to contact you."
        >
          <LegacyCard sectioned>
            <FormLayout>
              <TextField
                label="Store name"
                onChange={() => {}}
                autoComplete="off"
              />
              <TextField
                type="email"
                label="Account email"
                onChange={() => {}}
                autoComplete="email"
              />
            </FormLayout>
          </LegacyCard>
        </Layout.AnnotatedSection>
      </Layout>
    </Page>



    {/* Annotated with sections - do not use span tag*/}
    <span> 6) Annotated with sections</span>
    <Page fullWidth>
      <Layout>
        <Layout.Section oneThird>
          <div style={{marginTop: 'var(--p-space-5)'}}>
            <TextContainer>
              <Text id="storeDetails" variant="headingMd" as="h2">
                Store details
              </Text>
              <Text color="subdued" as="p">
                Shopify and your customers will use this information to contact
                you.
              </Text>
            </TextContainer>
          </div>
        </Layout.Section>
        <Layout.Section>
          <LegacyCard sectioned>
            <FormLayout>
              <TextField
                label="Store name"
                onChange={() => {}}
                autoComplete="off"
              />
              <TextField
                type="email"
                label="Account email"
                onChange={() => {}}
                autoComplete="email"
              />
            </FormLayout>
          </LegacyCard>
        </Layout.Section>
      </Layout>
    </Page>


    {/* Annotated with Banner at the top - do not use span tag*/}
    <span> 7) Annotated with Banner at the top</span>
    <Page fullWidth>
      <Layout>
        <Layout.Section>
          <Banner title="Order archived" onDismiss={() => {}}>
            <p>This order was archived on March 7, 2017 at 3:12pm EDT.</p>
          </Banner>
        </Layout.Section>
        <Layout.AnnotatedSection
          id="storeDetails"
          title="Store details"
          description="Shopify and your customers will use this information to contact you."
        >
          <LegacyCard sectioned>
            <FormLayout>
              <TextField
                label="Store name"
                onChange={() => {}}
                autoComplete="off"
              />
              <TextField
                type="email"
                label="Account email"
                onChange={() => {}}
                autoComplete="email"
              />
            </FormLayout>
          </LegacyCard>
        </Layout.AnnotatedSection>
      </Layout>
    </Page>

    </>
      );
    }
export default LayoutEx
