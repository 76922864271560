import {
    ChoiceList,
    TextField,
    RangeSlider,
    LegacyCard,
    ResourceList,
    LegacyFilters,
    Avatar,
    Text,
    Page
} from '@shopify/polaris';
import { useState, useCallback } from 'react';

function LegacyFiltersWithQueryFieldDisabled() {
    const [accountStatus, setAccountStatus] = useState(
        undefined,
    );
    const [moneySpent, setMoneySpent] = useState(
        undefined,
    );
    const [taggedWith, setTaggedWith] = useState(undefined);
    const [queryValue, setQueryValue] = useState(undefined);

    const handleAccountStatusChange = useCallback(
        (value: string[]) => setAccountStatus(value),
        [],
    );
    const handleMoneySpentChange = useCallback(
        (value: [number, number]) => setMoneySpent(value),
        [],
    );
    const handleTaggedWithChange = useCallback(
        (value: string) => setTaggedWith(value),
        [],
    );
    const handleFiltersQueryChange = useCallback(
        (value: string) => setQueryValue(value),
        [],
    );
    const handleAccountStatusRemove = useCallback(
        () => setAccountStatus(undefined),
        [],
    );
    const handleMoneySpentRemove = useCallback(
        () => setMoneySpent(undefined),
        [],
    );
    const handleTaggedWithRemove = useCallback(
        () => setTaggedWith(undefined),
        [],
    );
    const handleQueryValueRemove = useCallback(
        () => setQueryValue(undefined),
        [],
    );
    const handleFiltersClearAll = useCallback(() => {
        handleAccountStatusRemove();
        handleMoneySpentRemove();
        handleTaggedWithRemove();
        handleQueryValueRemove();
    }, [
        handleAccountStatusRemove,
        handleMoneySpentRemove,
        handleQueryValueRemove,
        handleTaggedWithRemove,
    ]);

    const filters = [
        {
            key: 'accountStatus',
            label: 'Account status',
            filter: (
                <ChoiceList
                    title="Account status"
                    titleHidden
                    choices={[
                        { label: 'Enabled', value: 'enabled' },
                        { label: 'Not invited', value: 'not invited' },
                        { label: 'Invited', value: 'invited' },
                        { label: 'Declined', value: 'declined' },
                    ]}
                    selected={accountStatus || []}
                    onChange={handleAccountStatusChange}
                    allowMultiple
                />
            ),
            shortcut: true,
        },
        {
            key: 'taggedWith',
            label: 'Tagged with',
            filter: (
                <TextField
                    label="Tagged with"
                    value={taggedWith}
                    onChange={handleTaggedWithChange}
                    autoComplete="off"
                    labelHidden
                />
            ),
            shortcut: true,
        },
        {
            key: 'moneySpent',
            label: 'Money spent',
            filter: (
                <RangeSlider
                    label="Money spent is between"
                    labelHidden
                    value={moneySpent || [0, 500]}
                    prefix="$"
                    output
                    min={0}
                    max={2000}
                    step={1}
                    onChange={handleMoneySpentChange}
                />
            ),
        },
    ];

    const appliedFilters = [];
    if (accountStatus && accountStatus.length > 0) {
        appliedFilters.push({
            key: 'accountStatus',
            label: accountStatus.map((val) => `Customer ${val}`).join(', '),
            onRemove: handleAccountStatusRemove,
        });
    }
    if (moneySpent) {
        appliedFilters.push({
            key: 'moneySpent',
            label: `Money spent is between $${moneySpent[0]} and $${moneySpent[1]}`,
            onRemove: handleMoneySpentRemove,
        });
    }
    if (taggedWith && !isEmpty(taggedWith)) {
        appliedFilters.push({
            key: 'taggedWith',
            label: `Tagged with ${taggedWith}`,
            onRemove: handleTaggedWithRemove,
        });
    }

    return (
        <Page>
            <div style={{ height: '568px' }}>
                <LegacyCard>
                    <ResourceList
                        resourceName={{ singular: 'customer', plural: 'customers' }}
                        filterControl={
                            <LegacyFilters
                                queryValue={queryValue}
                                filters={filters}
                                appliedFilters={appliedFilters}
                                onQueryChange={handleFiltersQueryChange}
                                onQueryClear={handleQueryValueRemove}
                                onClearAll={handleFiltersClearAll}
                                disableQueryField
                            />
                        }
                        items={[
                            {
                                id: '341',
                                url: '#',
                                name: 'Mae Jemison',
                                location: 'Decatur, USA',
                            },
                            {
                                id: '256',
                                url: '#',
                                name: 'Ellen Ochoa',
                                location: 'Los Angeles, USA',
                            },
                        ]}
                        renderItem={(item) => {
                            const { id, url, name, location } = item;
                            const media = <Avatar customer size="medium" name={name} />;

                            return (
                                <ResourceList.Item
                                    id={id}
                                    url={url}
                                    media={media}
                                    accessibilityLabel={`View details for ${name}`}
                                >
                                    <Text as="h3" variant="bodyMd" fontWeight="bold">
                                        {name}
                                    </Text>
                                    <div>{location}</div>
                                </ResourceList.Item>
                            );
                        }}
                    />
                </LegacyCard>
            </div>
        </Page>
    );

    function isEmpty(value: string): boolean {
        if (Array.isArray(value)) {
            return value.length === 0;
        } else {
            return value === '' || value == null;
        }
    }
}

export default LegacyFiltersWithQueryFieldDisabled