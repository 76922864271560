import {LegacyCard, Tabs} from '@shopify/polaris';
import {useState, useCallback} from 'react';


function TabsFitted() {
    const [selected, setSelected] = useState(0);

    const handleTabChange = useCallback(
      (selectedTabIndex: number) => setSelected(selectedTabIndex),
      [],
    );
  
    const tabs = [
      {
        id: 'all-customers-fitted-2',
        content: 'All',
        accessibilityLabel: 'All customers',
        panelID: 'all-customers-fitted-content-2',
      },
      {
        id: 'accepts-marketing-fitted-2',
        content: 'Accepts marketing',
        panelID: 'accepts-marketing-fitted-Ccontent-2',
      },
    ];
  
    return (
      <LegacyCard>
        <Tabs tabs={tabs} selected={selected} onSelect={handleTabChange} fitted>
          <LegacyCard.Section title={tabs[selected].content}>
            <p>Tab {selected} selected</p>
          </LegacyCard.Section>
        </Tabs>
      </LegacyCard>
    );
}

export default TabsFitted
