import React from 'react'
import {FooterHelp, Link, Page} from '@shopify/polaris';

function FooterHelpEx() {
  return (
    <Page>
        <FooterHelp>
        Learn more about{' '}
        <Link url="https://help.shopify.com/manual/orders/fulfill-orders">
        fulfilling orders
        </Link>
    </FooterHelp>
  </Page>
  )
}

export default FooterHelpEx
