import { ButtonGroup, Button, Page, LegacyCard } from '@shopify/polaris';
import { useCallback, useState } from 'react';

function ButtonGroupEx() {

    // pressed with segmented buttons - do not use margin-top div
    const [activeButtonIndex, setActiveButtonIndex] = useState(0);

    const handleButtonClick = useCallback(
        (index: number) => {
        if (activeButtonIndex === index) return;
        setActiveButtonIndex(index);
        },
        [activeButtonIndex],
    );

    return (
        <Page>
            <LegacyCard sectioned>
                {/* default - do not use margin-top div */}
                <div>1) Default</div>
                    <ButtonGroup>
                        <Button>Cancel</Button>
                        <Button primary>Save</Button>
                    </ButtonGroup>
                



                {/* with segmented - do not use margin-top div */}
                <div className='margin-top'>2) with segmented</div>
                    <ButtonGroup segmented>
                        <Button>Bold</Button>
                        <Button>Italic</Button>
                        <Button>Underline</Button>
                    </ButtonGroup>
                



                {/* outline with segmented - do not use margin-top div */}
                <div className='margin-top'>3) outline with segmented</div>
                    <ButtonGroup segmented>
                        <Button outline>Bold</Button>
                        <Button outline>Italic</Button>
                        <Button outline>Underline</Button>
                    </ButtonGroup>
                



                {/* pressed with segmented - do not use margin-top div */}
                <div className='margin-top'>4)  pressed with segmented</div>
                    <ButtonGroup segmented>
                        <Button
                            pressed={activeButtonIndex === 0}
                            onClick={() => handleButtonClick(0)}
                        >
                            Bold
                        </Button>
                        <Button
                            pressed={activeButtonIndex === 1}
                            onClick={() => handleButtonClick(1)}
                        >
                            Italic
                        </Button>
                        <Button
                            pressed={activeButtonIndex === 2}
                            onClick={() => handleButtonClick(2)}
                        >
                            Underline
                        </Button>
                    </ButtonGroup>
               
            </LegacyCard>
        </Page>
    );
}
export default ButtonGroupEx