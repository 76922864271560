import React from 'react'
import {LegacyCard, Page, List, LegacyStack, ButtonGroup, Button, Popover, ActionList, Icon, Text, TextContainer, ResourceList, Image} from '@shopify/polaris';
import {ProductsMajor} from '@shopify/polaris-icons';

function LegacyCardEx() {
  return (
    <>
    
   {/* Default - do not use div tag*/}
   <Page>
        <LegacyCard title="Online store dashboard" sectioned>
            <p>View a summary of your online store’s performance.</p>
        </LegacyCard>
  


    {/* Default - do not use div tag*/}
    <div className='margin-top'>2) With header actions</div>
   
        <LegacyCard sectioned title="Variants" actions={[{content: 'Add variant'}]}>
            <p>
                Add variants if this product comes in multiple versions, like different
                sizes or colors.
            </p>
        </LegacyCard>
   



    {/* With footer actions - do not use div tag*/}
    <div className='margin-top'>3) With footer actions</div>
   
        <LegacyCard
            title="Shipment 1234"
            secondaryFooterActions={[{content: 'Edit shipment'}]}
            primaryFooterAction={{content: 'Add tracking number'}}
            >
            <LegacyCard.Section title="Items">
                <List>
                <List.Item>1 × Oasis Glass, 4-Pack</List.Item>
                <List.Item>1 × Anubis Cup, 2-Pack</List.Item>
                </List>
            </LegacyCard.Section>
        </LegacyCard>
  




    {/* With multiple footer actions - do not use div tag*/}
    <div className='margin-top'>4) With multiple footer actions</div>
   
        <LegacyCard
            title="Shipment 1234"
            secondaryFooterActions={[
                {content: 'Cancel shipment', destructive: true},
                {content: 'Add another shipment', disabled: true},
            ]}
            primaryFooterAction={{content: 'Add tracking number'}}
            >
            <LegacyCard.Section title="Items">
                <List>
                <List.Item>1 × Oasis Glass, 4-Pack</List.Item>
                <List.Item>1 × Anubis Cup, 2-Pack</List.Item>
                </List>
            </LegacyCard.Section>
        </LegacyCard>





    {/* With custom footer actions - do not use div tag*/}
    <div className='margin-top'>5) With custom footer actions</div>
   
        <LegacyCard title="Secure your account with 2-step authentication">
            <LegacyCard.Section>
                <LegacyStack spacing="loose" vertical>
                <p>
                    Two-step authentication adds an extra layer of security when logging
                    in to your account. A special code will be required each time you
                    log in, ensuring only you can access your account.
                </p>
                <LegacyStack distribution="trailing">
                    <ButtonGroup>
                    <Button>Enable two-step authentication</Button>
                    <Button plain>Learn more</Button>
                    </ButtonGroup>
                </LegacyStack>
                </LegacyStack>
            </LegacyCard.Section>
        </LegacyCard>
    



    {/*With destructive footer action - do not use div tag*/}
    <div className='margin-top'>6) With destructive footer action</div>
   
        <LegacyCard
            title="Shipment 1234"
            secondaryFooterActions={[{content: 'Cancel shipment', destructive: true}]}
            primaryFooterAction={{content: 'Add tracking number'}}
            >
            <LegacyCard.Section title="Items">
                <List>
                <List.Item>1 × Oasis Glass, 4-Pack</List.Item>
                <List.Item>1 × Anubis Cup, 2-Pack</List.Item>
                </List>
            </LegacyCard.Section>
        </LegacyCard>



    {/*With multiple sections - do not use div tag*/}
    <div className='margin-top'>7) With multiple sections</div>
   
        <LegacyCard title="Online store dashboard">
        <LegacyCard.Section>
            <p>View a summary of your online store’s performance.</p>
        </LegacyCard.Section>

        <LegacyCard.Section>
            <p>
            View a summary of your online store’s performance, including sales,
            visitors, top products, and referrals.
            </p>
        </LegacyCard.Section>
        </LegacyCard>




    {/*With multiple titled sections - do not use div tag*/}
    <div className='margin-top'>8) With multiple titled sections</div>
   
        <LegacyCard title="Online store dashboard">
            <LegacyCard.Section title="Reports">
                <p>View a summary of your online store’s performance.</p>
            </LegacyCard.Section>

            <LegacyCard.Section title="Summary">
                <p>
                View a summary of your online store’s performance, including sales,
                visitors, top products, and referrals.
                </p>
            </LegacyCard.Section>
        </LegacyCard>




    {/*With sections and actions - do not use div tag*/}
    <div className='margin-top'>9) With sections and actions</div>
   
        <LegacyCard title="Customer">
            <LegacyCard.Section>
                <p>John Smith</p>
            </LegacyCard.Section>
            <LegacyCard.Section
                title="Contact Information"
                actions={[{content: 'Edit'}]}
            >
                <p>john.smith@example.com</p>
            </LegacyCard.Section>
        </LegacyCard>



    {/*With subsection - do not use div tag*/}
    <div className='margin-top'>10) With subsection</div>
   
        <LegacyCard title="Customer">
            <LegacyCard.Section>
                <p>John Smith</p>
            </LegacyCard.Section>
            <LegacyCard.Section title="Addresses">
                <LegacyCard.Subsection>
                123 First St
                <br />
                Somewhere
                <br />
                The Universe
                </LegacyCard.Subsection>
                <LegacyCard.Subsection>
                123 Second St
                <br />
                Somewhere
                <br />
                The Universe
                </LegacyCard.Subsection>
            </LegacyCard.Section>
            <LegacyCard.Section>
                <LegacyCard.Subsection>
                A single subsection without a sibling has no visual appearance
                </LegacyCard.Subsection>
            </LegacyCard.Section>
        </LegacyCard>



    {/*With destructive action - do not use div tag*/}
    <div className='margin-top'>11) With destructive action</div>
   
        <LegacyCard title="Customer">
            <LegacyCard.Section>
                <p>John Smith</p>
            </LegacyCard.Section>
            <LegacyCard.Section
                title="Contact Information"
                actions={[{content: 'Delete', destructive: true}, {content: 'Edit'}]}
            >
                <p>john.smith@example.com</p>
            </LegacyCard.Section>
        </LegacyCard>



    {/*With a subdued section - do not use div tag*/}
    <div className='margin-top'>12) With a subdued section</div>
   
        <LegacyCard title="Staff accounts">
            <LegacyCard.Section>
                <List>
                <List.Item>Felix Crafford</List.Item>
                <List.Item>Ezequiel Manno</List.Item>
                </List>
            </LegacyCard.Section>

            <LegacyCard.Section subdued title="Deactivated staff accounts">
                <List>
                <List.Item>Felix Crafford</List.Item>
                <List.Item>Ezequiel Manno</List.Item>
                </List>
            </LegacyCard.Section>
        </LegacyCard>



    {/*With subdued for secondary content - do not use div tag*/}
    <div className='margin-top'>13) With subdued for secondary content</div>
   
        <LegacyCard title="Deactivated staff accounts" sectioned subdued>
        <List>
            <List.Item>Felix Crafford</List.Item>
            <List.Item>Ezequiel Manno</List.Item>
        </List>
        </LegacyCard>



    {/*With separate header - do not use div tag*/}
    <div className='margin-top'>14) With separate header</div>
   
        <LegacyCard>
            <LegacyCard.Header
                actions={[
                {
                    content: 'Preview',
                },
                ]}
                title="Staff accounts"
            >
                <Popover
                active
                activator={
                    <Button disclosure plain>
                    Add account
                    </Button>
                }
                onClose={() => {}}
                >
                <ActionList items={[{content: 'Member'}, {content: 'Admin'}]} />
                </Popover>
            </LegacyCard.Header>
            <LegacyCard.Section>
                <List>
                <List.Item>Felix Crafford</List.Item>
                <List.Item>Ezequiel Manno</List.Item>
                </List>
            </LegacyCard.Section>
        </LegacyCard>



    {/*With custom React Node title - do not use div tag*/}
    <div className='margin-top'>15) With custom React Node title</div>
   
        <LegacyCard title="Products">
            <LegacyCard.Section
                title={
                <LegacyStack>
                    <Icon source={ProductsMajor} />
                    <Text variant="headingXs" as="h3">
                    New Products
                    </Text>
                </LegacyStack>
                }
            >
                <List>
                <List.Item>Socks</List.Item>
                <List.Item>Super Shoes</List.Item>
                </List>
            </LegacyCard.Section>
        </LegacyCard> 



    {/*With all elements - do not use div tag*/}
    <div className='margin-top'>16) With all elements</div>
   
        <LegacyCard
        secondaryFooterActions={[{content: 'Dismiss'}]}
        primaryFooterAction={{content: 'Export Report'}}
        >
        <LegacyCard.Header
            actions={[
            {
                content: 'Total Sales',
            },
            ]}
            title="Sales"
        >
            <Popover
            active={false}
            activator={
                <Button disclosure plain>
                View Sales
                </Button>
            }
            onClose={() => {}}
            >
            <ActionList
                items={[{content: 'Gross Sales'}, {content: 'Net Sales'}]}
            />
            </Popover>
        </LegacyCard.Header>
        <LegacyCard.Section>
            <TextContainer>
            You can use sales reports to see information about your customers’
            orders based on criteria such as sales over time, by channel, or by
            staff.
            </TextContainer>
        </LegacyCard.Section>
        <LegacyCard.Section title="Total Sales Breakdown">
            <ResourceList
            resourceName={{singular: 'sale', plural: 'sales'}}
            items={[
                {
                sales: 'Orders',
                amount: 'USD$0.00',
                url: '#',
                },
                {
                sales: 'Returns',
                amount: '-USD$250.00',
                url: '#',
                },
            ]}
            renderItem={(item) => {
                const {sales, amount, url} = item;
                return (
                <ResourceList.Item
                    id={sales.toLocaleLowerCase()}
                    url={url}
                    accessibilityLabel={`View Sales for ${sales}`}
                    onClick={() => console.log('clicked item', item)}
                >
                    <LegacyStack>
                    <LegacyStack.Item fill>{sales}</LegacyStack.Item>
                    <LegacyStack.Item>{amount}</LegacyStack.Item>
                    </LegacyStack>
                </ResourceList.Item>
                );
            }}
            />
        </LegacyCard.Section>
        <LegacyCard.Section title="Deactivated reports" subdued>
            <List>
            <List.Item>Payouts</List.Item>
            <List.Item>Total Sales By Channel</List.Item>
            </List>
        </LegacyCard.Section>
        <LegacyCard.Section title="Note">
            <TextContainer>
            The sales reports are available only if your store is on the Shopify
            plan or higher.
            </TextContainer>
        </LegacyCard.Section>
        </LegacyCard>



    {/*With flushed sections - do not use div tag*/}
    <div className='margin-top'>17) With flushed sections</div>
   
        <LegacyCard>
            <LegacyCard.Section flush>
                <Image
                source="https://burst.shopifycdn.com/photos/black-orange-stripes_373x@2x.jpg"
                alt="a sheet with purple and orange stripes"
                />
            </LegacyCard.Section>
            <LegacyCard.Section subdued>
                <TextContainer>
                You can use sales reports to see information about your customers’
                orders based on criteria such as sales over time, by channel, or by
                staff.
                </TextContainer>
            </LegacyCard.Section>
        </LegacyCard>


    </Page>
   </>

   )
}

export default LegacyCardEx
