import { LegacyCard, TextField, Page } from '@shopify/polaris';
import { useState, useCallback } from 'react';

function Multiline() {
    const [value, setValue] = useState('1776 Barnes Street\nOrlando, FL 32801');

    const handleChange = useCallback(
        (newValue: string) => setValue(newValue),
        [],
    );

    return (
        <Page>
            <LegacyCard sectioned>
                <TextField
                    label="Shipping address"
                    value={value}
                    onChange={handleChange}
                    multiline={4}
                    autoComplete="off"
                />
            </LegacyCard>
        </Page>
    );
}

export default Multiline