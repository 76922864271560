import React from 'react'
import {Spinner, Page, Form, FormLayout, TextField, Button, LegacyCard, Tabs} from '@shopify/polaris';
import {useState, useEffect, useCallback, useRef} from 'react';


function SpinnerEx() {

    // With focus management
    const tabs = useRef([
        {
          id: 'all-customers',
          content: 'All',
          accessibilityLabel: 'All customers',
          panelID: 'all-customers-content',
        },
        {
          id: 'accepts-marketing',
          content: 'Accepts marketing',
          panelID: 'accepts-marketing-content',
        },
      ]);
    
      const [selected, setSelected] = useState(0);
      const [loading, setLoading] = useState(false);
      const [value, setValue] = useState('');
      const [textFieldFocused, setTextFieldFocused] = useState(false);
    
      useEffect(() => {
        setTextFieldFocused(!loading);
      }, [loading]);
    
      const handleTabChange = useCallback((selectedTab: number) => {
        setLoading(true);
        setSelected(selectedTab);
        setTimeout(() => {
          setValue('');
          return setLoading(false);
        }, 1500);
      }, []);
    
      const handleUrlChange = useCallback((value: string) => setValue(value), []);
    
      const handleSubmit = useCallback(() => setValue(''), []);
    
      const label = selected ? 'Marketing' : 'Customers';
      const sectionMarkup = loading ? (
        <Spinner
          accessibilityLabel="Loading form field"
          hasFocusableParent={false}
        />
      ) : (
        <Form noValidate onSubmit={handleSubmit}>
          <FormLayout>
            <TextField
              value={value}
              focused={textFieldFocused}
              onChange={handleUrlChange}
              label={label}
              autoComplete="off"
            />
            <Button submit>Submit</Button>
          </FormLayout>
        </Form>
      );



  return (
    <Page>


    {/*Default - do not use div tag*/}
    <div className='margin-top'>1) Default</div>  
        <Spinner accessibilityLabel="Spinner example" size="large" />



    {/*Small - do not use div tag*/}
    <div className='margin-top'>2) Small</div>  
        <Spinner accessibilityLabel="Small Spinner example" size="small" />   



    {/*With focus management - do not use div tag*/}
    <div className='margin-top'>3) With focus management</div>  
        <LegacyCard>
            <Tabs tabs={tabs.current} selected={selected} onSelect={handleTabChange}>
                <LegacyCard.Section title={tabs.current[selected].content}>
                {sectionMarkup}
                </LegacyCard.Section>
            </Tabs>
        </LegacyCard>      


    </Page>
  )
}

export default SpinnerEx
