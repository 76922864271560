import { LegacyCard, Page, PageActions, Button } from '@shopify/polaris'
import React from 'react'

function PageActionsEx() {
  return (
    <Page>
        <LegacyCard sectioned>

        {/* Default - do not use margin-top div*/}
          <div className='margin-top'>
              <PageActions
                  primaryAction={{
                    content: 'Save',
                  }}
                  secondaryActions={[
                    {
                      content: 'Delete',
                      destructive: true,
                    },
                  ]}
                />
          </div>


          {/* Primary action only - do not use margin-top div*/}
          <div className='margin-top'>
              <PageActions
                primaryAction={{
                  content: 'Save',
                }}
              />
            </div>


          {/*With custom primary action - do not use margin-top div*/}
          <div className='margin-top'>
             <PageActions
                primaryAction={
                  <Button
                    primary
                    connectedDisclosure={{
                      accessibilityLabel: 'Other save actions',
                      actions: [{content: 'Save as draft'}],
                    }}
                  >
                    Save
                  </Button>
                }
                secondaryActions={[
                  {
                    content: 'Delete',
                    destructive: true,
                  },
                ]}
              />
          </div>


          {/* With custom secondary action - do not use margin-top div*/}
          <div className='margin-top'>
             <PageActions
                primaryAction={{
                  content: 'Save',
                }}
                secondaryActions={
                  <Button
                    connectedDisclosure={{
                      accessibilityLabel: 'Other save actions',
                      actions: [{content: 'Save as draft'}],
                    }}
                  >
                    Save
                  </Button>
                }
              />
          </div>
           
        </LegacyCard>
    </Page>
  )
}

export default PageActionsEx
