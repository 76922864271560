import React from 'react'
import {CalloutCard, Page} from '@shopify/polaris';

function CalloutCardEx() {
  return (
    <Page>
            {/* default - do not use margin-top div*/}
            <div className='margin-top'>1) default </div>
                <CalloutCard
                    title="Customize the style of your checkout"
                    illustration="https://cdn.shopify.com/s/assets/admin/checkout/settings-customizecart-705f57c725ac05be5a34ec20c05b94298cb8afd10aac7bd9c7ad02030f48cfa0.svg"
                    primaryAction={{
                        content: 'Customize checkout',
                        url: '#',
                    }}
                    >
                    <p>Upload your store’s logo, change colors and fonts, and more.</p>
                </CalloutCard>
            
            



            {/* With secondary action - do not use margin-top div*/}
            <div className='margin-top'>2) With secondary action</div>
                <CalloutCard
                    title="Customize the style of your checkout"
                    illustration="https://cdn.shopify.com/s/assets/admin/checkout/settings-customizecart-705f57c725ac05be5a34ec20c05b94298cb8afd10aac7bd9c7ad02030f48cfa0.svg"
                    primaryAction={{content: 'Customize checkout'}}
                    secondaryAction={{content: 'Learn more about customizing checkout'}}
                    >
                    <p>Upload your store’s logo, change colors and fonts, and more.</p>
                </CalloutCard>
            



            {/* Dismissable - do not use margin-top div*/}
            <div className='margin-top'>3) Dismissable</div>
                <CalloutCard
                    title="Customize the style of your checkout"
                    illustration="https://cdn.shopify.com/s/assets/admin/checkout/settings-customizecart-705f57c725ac05be5a34ec20c05b94298cb8afd10aac7bd9c7ad02030f48cfa0.svg"
                    primaryAction={{content: 'Customize checkout'}}
                    onDismiss={() => {}}
                    >
                    <p>Upload your store’s logo, change colors and fonts, and more.</p>
                </CalloutCard>
            
   
    </Page>
  )
}

export default CalloutCardEx
