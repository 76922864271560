import {DropZone} from '@shopify/polaris';
import React from 'react';

function WithaLabel() {
  return (
    <DropZone label="Theme files">
      <DropZone.FileUpload />
    </DropZone>
  );
}

export default WithaLabel