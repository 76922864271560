import {DropZone} from '@shopify/polaris';
import React from 'react';

function MediumSized() {
  return (
    <div style={{width: 114, height: 114}}>
      <DropZone>
        <DropZone.FileUpload />
      </DropZone>
    </div>
  );
}

export default MediumSized