import React from 'react'
import {LegacyStack, Badge, Page, Text} from '@shopify/polaris';

function LegacyStackEx() {
  return (
    <Page>

        {/*Default - do not use div tag*/}
        <div className='margin-top'>1) Default</div>
            <LegacyStack>
                <Badge>Paid</Badge>
                <Badge>Processing</Badge>
                <Badge>Fulfilled</Badge>
                <Badge>Completed</Badge>
            </LegacyStack>



        {/*Non-wrapping - do not use div tag*/}
        <div className='margin-top'>2) Non-wrapping</div>
            <LegacyStack wrap={false}>
                <Badge>Paid</Badge>
                <Badge>Processing</Badge>
                <Badge>Fulfilled</Badge>
                <Badge>Completed</Badge>
            </LegacyStack>



        {/*Spacing - do not use div tag*/}
        <div className='margin-top'>3) Spacing</div>
            <LegacyStack spacing="loose">
                <Badge>Paid</Badge>
                <Badge>Fulfilled</Badge>
            </LegacyStack>



        {/*Vertical centering - do not use div tag*/}
        <div className='margin-top'>4) Vertical centering</div>
            <LegacyStack alignment="center">
                <Text variant="headingMd" as="h2">
                    Order
                    <br />
                    #1136
                    <br />
                    was paid
                </Text>
                <Badge>Paid</Badge>
                <Badge>Fulfilled</Badge>
            </LegacyStack> 




        {/*Fill available space proportionally - do not use div tag*/}
        <div className='margin-top'>5) Fill available space proportionally</div>
            <LegacyStack distribution="fill">
                <Text variant="headingMd" as="h2">
                    Order #1136
                </Text>
                <Badge>Paid</Badge>
                <Badge>Fulfilled</Badge>
            </LegacyStack> 




        {/*Where items fill space evenly - do not use div tag*/}
        <div className='margin-top'>6) Where items fill space evenly</div>
            <LegacyStack distribution="fillEvenly">
                <Text variant="headingMd" as="h2">
                    Order #1136
                </Text>
                <Badge>Paid</Badge>
                <Badge>Fulfilled</Badge>
            </LegacyStack>



        {/*Where a single item fills the remaining space - do not use div tag*/}
        <div className='margin-top'>7) Where a single item fills the remaining space</div>
            <LegacyStack>
                <LegacyStack.Item fill>
                    <Text variant="headingMd" as="h2">
                    Order #1136
                    </Text>
                </LegacyStack.Item>
                <LegacyStack.Item>
                    <Badge>Paid</Badge>
                </LegacyStack.Item>
                <LegacyStack.Item>
                    <Badge>Fulfilled</Badge>
                </LegacyStack.Item>
            </LegacyStack>         
        
        
    </Page>
  )
}

export default LegacyStackEx
