import {
    IndexTable,
    LegacyCard,
    IndexFilters,
    useSetIndexFiltersMode,
    useIndexResourceState,
    Text,
    Badge,
  } from '@shopify/polaris';
  import type {IndexFiltersProps, TabProps} from '@shopify/polaris';
  import {useState, useCallback} from 'react';
  
  function WithNoFilters() {
    const sleep = (ms: number) =>
      new Promise((resolve) => setTimeout(resolve, ms));
    const [itemStrings, setItemStrings] = useState([
      'All',
      'Unpaid',
      'Open',
      'Closed',
      'Local delivery',
      'Local pickup',
    ]);
    const deleteView = (index: number) => {
      const newItemStrings = [...itemStrings];
      newItemStrings.splice(index, 1);
      setItemStrings(newItemStrings);
      setSelected(0);
    };
  
    const duplicateView = async (name: string) => {
      setItemStrings([...itemStrings, name]);
      setSelected(itemStrings.length);
      await sleep(1);
      return true;
    };
  
    const tabs: TabProps[] = itemStrings.map((item, index) => ({
      content: item,
      index,
      onAction: () => {},
      id: `${item}-${index}`,
      isLocked: index === 0,
      actions:
        index === 0
          ? []
          : [
              {
                type: 'rename',
                onAction: () => {},
                onPrimaryAction: async (value: string): Promise<boolean> => {
                  const newItemsStrings = tabs.map((item, idx) => {
                    if (idx === index) {
                      return value;
                    }
                    return item.content;
                  });
                  await sleep(1);
                  setItemStrings(newItemsStrings);
                  return true;
                },
              },
              {
                type: 'duplicate',
                onPrimaryAction: async (value: string): Promise<boolean> => {
                  await sleep(1);
                  duplicateView(value);
                  return true;
                },
              },
              {
                type: 'edit',
              },
              {
                type: 'delete',
                onPrimaryAction: async () => {
                  await sleep(1);
                  deleteView(index);
                  return true;
                },
              },
            ],
    }));
    const [selected, setSelected] = useState(0);
    const onCreateNewView = async (value: string) => {
      await sleep(500);
      setItemStrings([...itemStrings, value]);
      setSelected(itemStrings.length);
      return true;
    };
    const sortOptions: IndexFiltersProps['sortOptions'] = [
      {label: 'Order', value: 'order asc', directionLabel: 'Ascending'},
      {label: 'Order', value: 'order desc', directionLabel: 'Descending'},
      {label: 'Customer', value: 'customer asc', directionLabel: 'A-Z'},
      {label: 'Customer', value: 'customer desc', directionLabel: 'Z-A'},
      {label: 'Date', value: 'date asc', directionLabel: 'A-Z'},
      {label: 'Date', value: 'date desc', directionLabel: 'Z-A'},
      {label: 'Total', value: 'total asc', directionLabel: 'Ascending'},
      {label: 'Total', value: 'total desc', directionLabel: 'Descending'},
    ];
    const [sortSelected, setSortSelected] = useState(['order asc']);
    const {mode, setMode} = useSetIndexFiltersMode();
    const onHandleCancel = () => {};
  
    const onHandleSave = async () => {
      await sleep(1);
      return true;
    };
  
    const primaryAction: IndexFiltersProps['primaryAction'] =
      selected === 0
        ? {
            type: 'save-as',
            onAction: onCreateNewView,
            disabled: false,
            loading: false,
          }
        : {
            type: 'save',
            onAction: onHandleSave,
            disabled: false,
            loading: false,
          };
    const [queryValue, setQueryValue] = useState('');
  
    const handleFiltersQueryChange = useCallback(
      (value: string) => setQueryValue(value),
      [],
    );
  
    const orders = [
      {
        id: '1020',
        order: (
          <Text as="span" variant="bodyMd" fontWeight="semibold">
            #1020
          </Text>
        ),
        date: 'Jul 20 at 4:34pm',
        customer: 'Jaydon Stanton',
        total: '$969.44',
        paymentStatus: <Badge progress="complete">Paid</Badge>,
        fulfillmentStatus: <Badge progress="incomplete">Unfulfilled</Badge>,
      },
      {
        id: '1019',
        order: (
          <Text as="span" variant="bodyMd" fontWeight="semibold">
            #1019
          </Text>
        ),
        date: 'Jul 20 at 3:46pm',
        customer: 'Ruben Westerfelt',
        total: '$701.19',
        paymentStatus: <Badge progress="partiallyComplete">Partially paid</Badge>,
        fulfillmentStatus: <Badge progress="incomplete">Unfulfilled</Badge>,
      },
      {
        id: '1018',
        order: (
          <Text as="span" variant="bodyMd" fontWeight="semibold">
            #1018
          </Text>
        ),
        date: 'Jul 20 at 3.44pm',
        customer: 'Leo Carder',
        total: '$798.24',
        paymentStatus: <Badge progress="complete">Paid</Badge>,
        fulfillmentStatus: <Badge progress="incomplete">Unfulfilled</Badge>,
      },
    ];
    const resourceName = {
      singular: 'order',
      plural: 'orders',
    };
  
    const {selectedResources, allResourcesSelected, handleSelectionChange} =
      useIndexResourceState(orders);
  
    const rowMarkup = orders.map(
      (
        {id, order, date, customer, total, paymentStatus, fulfillmentStatus},
        index,
      ) => (
        <IndexTable.Row
          id={id}
          key={id}
          selected={selectedResources.includes(id)}
          position={index}
        >
          <IndexTable.Cell>
            <Text variant="bodyMd" fontWeight="bold" as="span">
              {order}
            </Text>
          </IndexTable.Cell>
          <IndexTable.Cell>{date}</IndexTable.Cell>
          <IndexTable.Cell>{customer}</IndexTable.Cell>
          <IndexTable.Cell>{total}</IndexTable.Cell>
          <IndexTable.Cell>{paymentStatus}</IndexTable.Cell>
          <IndexTable.Cell>{fulfillmentStatus}</IndexTable.Cell>
        </IndexTable.Row>
      ),
    );
  
    return (
      <LegacyCard>
        <IndexFilters
          sortOptions={sortOptions}
          sortSelected={sortSelected}
          queryValue={queryValue}
          queryPlaceholder="Searching in all"
          onQueryChange={handleFiltersQueryChange}
          onQueryClear={() => {}}
          onSort={setSortSelected}
          primaryAction={primaryAction}
          cancelAction={{
            onAction: onHandleCancel,
            disabled: false,
            loading: false,
          }}
          tabs={tabs}
          selected={selected}
          onSelect={setSelected}
          canCreateNewView
          onCreateNewView={onCreateNewView}
          filters={[]}
          appliedFilters={[]}
          onClearAll={() => {}}
          mode={mode}
          setMode={setMode}
          hideFilters
          filteringAccessibilityTooltip="Search (F)"
        />
        <IndexTable
          resourceName={resourceName}
          itemCount={orders.length}
          selectedItemsCount={
            allResourcesSelected ? 'All' : selectedResources.length
          }
          onSelectionChange={handleSelectionChange}
          headings={[
            {title: 'Order'},
            {title: 'Date'},
            {title: 'Customer'},
            {title: 'Total', alignment: 'end'},
            {title: 'Payment status'},
            {title: 'Fulfillment status'},
          ]}
        >
          {rowMarkup}
        </IndexTable>
      </LegacyCard>
    );
  }

  export default WithNoFilters