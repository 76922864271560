import React from 'react'
import {LegacyCard, SkeletonTabs, Page} from '@shopify/polaris';

function SkeletonTabsEx() {
  return (
    <Page>


    {/*Default - do not use div tag*/}
    <div className='margin-top'>1) Default</div>  
         <LegacyCard>
            <SkeletonTabs />
        </LegacyCard>


    {/*With a custom count - do not use div tag*/}
    <div className='margin-top'>2) With a custom count</div>  
         <LegacyCard>
            <SkeletonTabs count={4}/>
        </LegacyCard>    

    </Page>
  )
}

export default SkeletonTabsEx
