import {useState, useCallback}  from 'react'
import {LegacyCard, Button, Page, ButtonGroup} from '@shopify/polaris'

function ButtonEx() {
// Right-aligned disclosurebutton
  // const [expanded, setExpanded] = useState(false);

// Plain disclosure button
  const [expanded, setExpanded] = useState(false);

//  pressed button
  const [isFirstButtonActive, setIsFirstButtonActive] = useState(true);

  const handleFirstButtonClick = useCallback(() => {
    if (isFirstButtonActive) return;
    setIsFirstButtonActive(true);
  }, [isFirstButtonActive]);

  const handleSecondButtonClick = useCallback(() => {
    if (!isFirstButtonActive) return;
    setIsFirstButtonActive(false);
  }, [isFirstButtonActive]);


 
return (
    <Page>
        <LegacyCard sectioned>
           {/* default - do not use margin-top div*/}
            <div>
                <Button>Add product</Button>
            </div>


            {/* Outline - do not use margin-top div*/}
            <div className='margin-top'>
                <Button outline>Add product</Button>
            </div>


            {/* Outline monochrome - do not use margin-top div*/}
            <div className='margin-top'>
              <div style={{color: '#bf0711'}}>
                <Button monochrome outline>
                  Retry
                </Button>
              </div>
            </div>


            {/* Plain - do not use margin-top div*/}
            <div className='margin-top'>
               <Button plain>View shipping settings</Button>
            </div>


            {/* Plain monochrome - do not use margin-top div*/}
            <div className='margin-top'>
              <div>
                Could not retrieve data.{' '}
                <Button plain monochrome>
                  Try again
                </Button>
              </div>
            </div>


            {/* Plain destructive - do not use margin-top div*/}
            <div className='margin-top'>
               <Button plain destructive>Remove</Button>
            </div>


            {/* Primary - do not use margin-top div*/}
            <div className='margin-top'>
                <Button primary>Save theme</Button>
            </div>


            {/* Destructive - do not use margin-top div*/}
            <div className='margin-top'>
                 <Button destructive>Delete theme</Button>
            </div>


            {/* Slim - do not use margin-top div*/}
            <div className='margin-top'>
               <Button size="slim">Save variant</Button>
            </div>


            {/* Large - do not use margin-top div*/}
            <div className='margin-top'>
                <Button size="large">Create store</Button>
            </div>


            {/* Full-width - do not use margin-top div*/}
            <div className='margin-top'>
                <Button fullWidth>Add customer</Button>
            </div>


            {/* Text-aligned - do not use margin-top div*/}
            <div className='margin-top'>
                <Button plain textAlign="left">
                  This is a really long string of text that overflows onto the next line we
                  need to put in a lot of words now you can see the alignment. It is very
                  long but a customer could potentially name something this long.
                </Button>
            </div>


            {/* Pressed - do not use margin-top div*/}
            <div className='margin-top'>
              <ButtonGroup segmented>
                  <Button pressed={isFirstButtonActive} onClick={handleFirstButtonClick}>
                    First button
                  </Button>
                  <Button pressed={!isFirstButtonActive} onClick={handleSecondButtonClick}>
                    Second button
                  </Button>
              </ButtonGroup>
            </div>


            {/* Plain disclosure - do not use margin-top div*/}
            <div className='margin-top'>
              <Button
                  plain
                  disclosure={expanded ? 'up' : 'down'}
                  onClick={() => {
                    setExpanded(!expanded);
                  }}
                >
                  {expanded ? 'Show less' : 'Show more'}
              </Button>
            </div>


            {/* Right-aligned disclosure - do not use margin-top div*/}
            <div className='margin-top'>
              <div style={{width: '200px'}}>
                  <Button
                    fullWidth
                    textAlign="left"
                    disclosure={expanded ? 'up' : 'down'}
                    onClick={() => setExpanded(!expanded)}
                  >
                    {expanded ? 'Show less' : 'Show more'}
                  </Button>
              </div>
            </div>


            {/* Select disclosure - do not use margin-top div*/}
            <div className='margin-top'>
                <div style={{height: '100px'}}>
                  <Button disclosure="select" onClick={() => console.log('Open Popover')}>
                    Select options
                  </Button>
                </div>
            </div>


            {/* Split - do not use margin-top div*/}
            <div className='margin-top'>
              <div style={{height: '100px'}}>
                <Button
                  primary
                  connectedDisclosure={{
                    accessibilityLabel: 'Other save actions',
                    actions: [{content: 'Save as draft'}],
                  }}
                >
                  Save
                </Button>
              </div>
            </div>


            {/* Disabled state - do not use margin-top div*/}
            <div className='margin-top'>
              <ButtonGroup>
                  <Button disabled>Buy shipping label</Button>
                  <Button primary disabled>
                    Buy shipping label
                  </Button>
                  <Button destructive disabled>
                    Buy shipping label
                  </Button>
                  <Button outline disabled>
                    Buy shipping label
                  </Button>
                  <span style={{color: '#bf0711'}}>
                    <Button outline monochrome disabled>
                      Buy shipping label
                    </Button>
                  </span>
                  <Button plain disabled>
                    Buy shipping label
                  </Button>
                  <Button plain destructive disabled>
                    Buy shipping label
                  </Button>
              </ButtonGroup>
            </div>



            {/* Loading state - do not use margin-top div*/}
            <div className='margin-top'>
                 <Button loading>Save product</Button>
            </div>

        </LegacyCard>
    </Page>
  )
}

export default ButtonEx
