import { Button, Popover, OptionList, Page, LegacyCard } from '@shopify/polaris';
import { useState, useCallback } from 'react';

function OptionListInaPopover() {
    const [selected, setSelected] = useState([]);
    const [popoverActive, setPopoverActive] = useState(true);

    const togglePopoverActive = useCallback(
        () => setPopoverActive((popoverActive) => !popoverActive),
        [],
    );

    const activator = (
        <Button onClick={togglePopoverActive} disclosure>
            Options
        </Button>
    );

    return (
        <Page>
            <LegacyCard sectioned>
                <div style={{ height: '275px' }}>
                    <Popover
                        active={popoverActive}
                        activator={activator}
                        onClose={togglePopoverActive}
                    >
                        <OptionList
                            title="Inventory Location"
                            onChange={setSelected}
                            options={[
                                {
                                    value: 'byward_market',
                                    label: 'Byward Market',
                                    active: true,
                                },
                                { value: 'centretown', label: 'Centretown' },
                                {
                                    value: 'hintonburg',
                                    label: 'Hintonburg',
                                    active: true,
                                },
                                { value: 'westboro', label: 'Westboro' },
                                { value: 'downtown', label: 'Downtown' },
                            ]}
                            selected={selected}
                        />
                    </Popover>
                </div>
            </LegacyCard>
        </Page>
    );
}

export default OptionListInaPopover