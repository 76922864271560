import React from 'react'
import {SkeletonThumbnail, Page} from '@shopify/polaris';

function SkeletonThumbnailEx() {
  return (
   <Page>


    {/*Medium - do not use div tag*/}
    <div className='margin-top'>1) Medium</div>  
         <SkeletonThumbnail size="medium" />


    {/*Large - do not use div tag*/}
    <div className='margin-top'>2) Large</div>  
         <SkeletonThumbnail size="large" />  


    {/*Small - do not use div tag*/}
    <div className='margin-top'>3) Small</div>  
         <SkeletonThumbnail size="small" />  


    {/*Extra small - do not use div tag*/}
    <div className='margin-top'>4) Extra small</div>  
         <SkeletonThumbnail size="extraSmall" />                   
   </Page>
  )
}

export default SkeletonThumbnailEx
