import {LegacyStack, Thumbnail, DropZone, Page, Text} from '@shopify/polaris';
import {NoteMinor} from '@shopify/polaris-icons';
import {useState, useCallback} from 'react';



function WithDropOnPage() {
    const [files, setFiles] = useState([]);

    const handleDropZoneDrop = useCallback(
      (dropFiles: [], _acceptedFiles: [], _rejectedFiles: []) =>
        setFiles((files) => [...files, ...dropFiles]),
      [],
    );
  
    const validImageTypes = ['image/gif', 'image/jpeg', 'image/png'];
  
    const uploadedFiles = files.length > 0 && (
      <LegacyStack vertical>
        {files.map((file, index) => (
          <LegacyStack alignment="center" key={index}>
            <Thumbnail
              size="small"
              alt={file.name}
              source={
                validImageTypes.includes(file.type)
                  ? window.URL.createObjectURL(file)
                  : NoteMinor
              }
            />
            <div>
              {file.name}{' '}
              <Text variant="bodySm" as="p">
                {file.size} bytes
              </Text>
            </div>
          </LegacyStack>
        ))}
      </LegacyStack>
    );
  
    const uploadMessage = !uploadedFiles && <DropZone.FileUpload />;
  
    return (
      <Page
        backAction={{content: 'Products', url: '/products'}}
        title="Jar With Lock-Lid"
        primaryAction={{content: 'Save', disabled: true}}
        secondaryActions={[
          {content: 'Duplicate'},
          {content: 'View on your store View on your store View on your store '},
          {content: 'View on your store'},
          {content: 'View on your store'},
          {content: 'View on your store'},
          {content: 'View on your store'},
          {content: 'View on your store'},
          {content: 'View on your store'},
          {content: 'View on your store'},
        ]}
        pagination={{
          hasPrevious: true,
          hasNext: true,
        }}
      >
        <DropZone dropOnPage onDrop={handleDropZoneDrop}>
          {uploadedFiles}
          {uploadMessage}
        </DropZone>
      </Page>
    );
}

export default WithDropOnPage
