import React from 'react'
import {FormLayout, TextField, Page} from '@shopify/polaris'

function FromLayoutEx() {
  return (
    <Page>


        {/* Default - do not use margin-top div*/}
        <div className='margin-top'>1) Default
            <FormLayout>
                <TextField label="Store name" onChange={() => {}} autoComplete="off" />
                <TextField
                    type="email"
                    label="Account email"
                    onChange={() => {}}
                    autoComplete="email"
                />
            </FormLayout>
        </div>



        {/* Field group - do not use margin-top div*/}
        <div className='margin-top'>2) Field group
            <FormLayout>
                <FormLayout.Group>
                    <TextField
                    type="number"
                    label="Minimum order"
                    onChange={() => {}}
                    autoComplete="off"
                    />
                    <TextField
                    type="number"
                    label="Maximum order"
                    onChange={() => {}}
                    autoComplete="off"
                    />
                </FormLayout.Group>
            </FormLayout>
        </div>


        {/* Condensed field group - do not use margin-top div*/}
        <div className='margin-top'>3) Condensed field group
            <FormLayout>
                <FormLayout.Group condensed>
                    <TextField label="Length" onChange={() => {}} autoComplete="off" />
                    <TextField label="Width" onChange={() => {}} autoComplete="off" />
                    <TextField label="Height" onChange={() => {}} autoComplete="off" />
                    <TextField label="Unit" onChange={() => {}} autoComplete="off" />
                </FormLayout.Group>
           </FormLayout>
        </div>
    </Page>
  )
}

export default FromLayoutEx
