import { LegacyCard, Listbox, Page, LegacyStack, Icon } from '@shopify/polaris';
import { CirclePlusMinor } from '@shopify/polaris-icons';
import React from 'react';

function ListBoxEx() {
    return (
        <Page>
            <LegacyCard sectioned>
                {/* Default - do not use div tag */}
                <div>1:- Default</div>
                <Listbox accessibilityLabel="Basic Listbox example">
                    <Listbox.Option value="UniqueValue-1">Item 1</Listbox.Option>
                    <Listbox.Option value="UniqueValue-2">Item 2</Listbox.Option>
                    <Listbox.Option value="UniqueValue-3">Item 3</Listbox.Option>
                </Listbox>


                {/* With Loading - do not use div tag */}
                <div className='margin-top'>2:- With Loading</div>
                <Listbox>
                    <Listbox.Option value="UniqueValue-1">Item 1</Listbox.Option>
                    <Listbox.Option value="UniqueValue-2">Item 2</Listbox.Option>
                    <Listbox.Option value="UniqueValue-3">Item 3</Listbox.Option>
                    <Listbox.Loading accessibilityLabel="Loading" />
                </Listbox>


                {/* With Action - do not use div tag */}
                <div className='margin-top'>3:- With Action</div>
                <Listbox accessibilityLabel="Listbox with Action example">
                    <Listbox.Option value="UniqueValue-1">Item 1</Listbox.Option>
                    <Listbox.Option value="UniqueValue-2" divider>
                        Item 2
                    </Listbox.Option>
                    <Listbox.Action value="ActionValue">
                        <LegacyStack spacing="tight">
                            <Icon source={CirclePlusMinor} color="base" />
                            <div>Add item</div>
                        </LegacyStack>
                    </Listbox.Action>
                </Listbox>


                {/* With custom element - do not use div tag */}
                <div className='margin-top'>4:- With custom element</div>
                <Listbox accessibilityLabel="Listbox with custom element example">
                    <Listbox.Action value="ActionValue" divider>
                        Add item
                    </Listbox.Action>
                    <Listbox.Option value="UniqueValue-1">
                        <div>Item 1</div>
                    </Listbox.Option>
                    <Listbox.Option value="UniqueValue-2">
                        <div>Item 2</div>
                    </Listbox.Option>
                    <Listbox.Option value="UniqueValue-3">
                        <div>Item 3</div>
                    </Listbox.Option>
                    <Listbox.Loading accessibilityLabel="items are loading" />
                </Listbox>


                
            </LegacyCard>
        </Page>

    );
}

export default ListBoxEx