import { TextField, Select, Button, Page, LegacyCard } from '@shopify/polaris';
import { useState, useCallback } from 'react';

function WithConnectedFields() {
    const [textFieldValue, setTextFieldValue] = useState('10.6');
    const [selectValue, setSelectValue] = useState('kg');

    const handleTextFieldChange = useCallback(
        (value: string) => setTextFieldValue(value),
        [],
    );

    const handleSelectChange = useCallback(
        (value: string) => setSelectValue(value),
        [],
    );

    return (
        <Page>
            <LegacyCard sectioned>
                <TextField
                    label="Weight"
                    type="number"
                    value={textFieldValue}
                    onChange={handleTextFieldChange}
                    autoComplete="off"
                    connectedLeft={
                        <Select
                            value={selectValue}
                            label="Weight unit"
                            onChange={handleSelectChange}
                            labelHidden
                            options={['kg', 'lb']}
                        />
                    }
                    connectedRight={<Button>Submit</Button>}
                />
            </LegacyCard>
        </Page>

    );
}

export default WithConnectedFields