import {LegacyCard, LegacyTabs, Page} from '@shopify/polaris';
import {useState, useCallback} from 'react';

function LegacyTabsDefault() {
    const [selected, setSelected] = useState(0);

    const handleTabChange = useCallback(
      (selectedTabIndex: number) => setSelected(selectedTabIndex),
      [],
    );
  
    const tabs = [
      {
        id: 'all-customers-1',
        content: 'All',
        accessibilityLabel: 'All customers',
        panelID: 'all-customers-content-1',
      },
      {
        id: 'accepts-marketing-1',
        content: 'Accepts marketing',
        panelID: 'accepts-marketing-content-1',
      },
      {
        id: 'repeat-customers-1',
        content: 'Repeat customers',
        panelID: 'repeat-customers-content-1',
      },
      {
        id: 'prospects-1',
        content: 'Prospects',
        panelID: 'prospects-content-1',
      },
    ];
  
    return (
        <Page>
            <LegacyCard>
                <LegacyTabs tabs={tabs} selected={selected} onSelect={handleTabChange}>
                <LegacyCard.Section title={tabs[selected].content}>
                    <p>Tab {selected} selected</p>
                </LegacyCard.Section>
                </LegacyTabs>
            </LegacyCard>
        </Page>
    );
}

export default LegacyTabsDefault
