import React from 'react'
import {Frame, Loading} from '@shopify/polaris';

function Loadingex() {
  return (
  
        <div style={{height: '100px'}}>
            <Frame>
            <Loading />
            </Frame>
       </div>

  )
}

export default Loadingex
