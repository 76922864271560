import React from 'react'
import {SkeletonPage, Layout, LegacyCard, SkeletonBodyText, TextContainer, SkeletonDisplayText, Page} from '@shopify/polaris';

function SkeletonPageEx() {
  return (
    <Page>

         {/*With dynamic content - do not use div tag*/}
    <div className='margin-top'>1) With dynamic content</div>  
        <SkeletonPage primaryAction>
            <Layout>
                <Layout.Section>
                <LegacyCard sectioned>
                    <SkeletonBodyText />
                </LegacyCard>
                <LegacyCard sectioned>
                    <TextContainer>
                    <SkeletonDisplayText size="small" />
                    <SkeletonBodyText />
                    </TextContainer>
                </LegacyCard>
                <LegacyCard sectioned>
                    <TextContainer>
                    <SkeletonDisplayText size="small" />
                    <SkeletonBodyText />
                    </TextContainer>
                </LegacyCard>
                </Layout.Section>
                <Layout.Section secondary>
                <LegacyCard>
                    <LegacyCard.Section>
                    <TextContainer>
                        <SkeletonDisplayText size="small" />
                        <SkeletonBodyText lines={2} />
                    </TextContainer>
                    </LegacyCard.Section>
                    <LegacyCard.Section>
                    <SkeletonBodyText lines={1} />
                    </LegacyCard.Section>
                </LegacyCard>
                <LegacyCard subdued>
                    <LegacyCard.Section>
                    <TextContainer>
                        <SkeletonDisplayText size="small" />
                        <SkeletonBodyText lines={2} />
                    </TextContainer>
                    </LegacyCard.Section>
                    <LegacyCard.Section>
                    <SkeletonBodyText lines={2} />
                    </LegacyCard.Section>
                </LegacyCard>
                </Layout.Section>
            </Layout>
        </SkeletonPage>



        {/*With static content - do not use div tag*/}
    <div className='margin-top'>2) With static content</div> 
        <SkeletonPage title="Products" primaryAction>
            <Layout>
                <Layout.Section>
                    <LegacyCard sectioned>
                        <SkeletonBodyText />
                    </LegacyCard>
                    <LegacyCard sectioned title="Images">
                        <SkeletonBodyText />
                    </LegacyCard>
                    <LegacyCard sectioned title="Variants">
                        <SkeletonBodyText />
                    </LegacyCard>
                </Layout.Section>
                <Layout.Section secondary>
                    <LegacyCard title="Sales channels">
                        <LegacyCard.Section>
                        <SkeletonBodyText lines={2} />
                        </LegacyCard.Section>
                        <LegacyCard.Section>
                        <SkeletonBodyText lines={1} />
                        </LegacyCard.Section>
                    </LegacyCard>
                    <LegacyCard title="Organization" subdued>
                        <LegacyCard.Section>
                        <SkeletonBodyText lines={2} />
                        </LegacyCard.Section>
                        <LegacyCard.Section>
                        <SkeletonBodyText lines={2} />
                        </LegacyCard.Section>
                    </LegacyCard>
                </Layout.Section>
            </Layout>
        </SkeletonPage>
       
         
    </Page>
  )
}

export default SkeletonPageEx
