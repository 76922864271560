import React from 'react'
import {SkeletonBodyText, Page} from '@shopify/polaris';

function SkeletonBodyTextEx() {
  return (
    <Page>


    {/*Default - do not use div tag*/}
    <div className='margin-top'>1) Default</div>  
        <SkeletonBodyText />




    {/*Single line content - do not use div tag*/}
    <div className='margin-top'>2) Single line content</div>  
        <SkeletonBodyText lines={1} />     
    </Page>
  )
}

export default SkeletonBodyTextEx
