import React from 'react'
import {Page, SkeletonDisplayText} from '@shopify/polaris';

function SkeletonDisplayTextEx() {
  return (
   <Page>


   {/*Medium and large - do not use div tag*/}
   <div className='margin-top'>1) Medium and large</div>  
         <SkeletonDisplayText size="medium" />



  {/*Extra large - do not use div tag*/}
  <div className='margin-top'>2) Extra large</div>  
        <SkeletonDisplayText size="extraLarge" />  



  {/*Small - do not use div tag*/}
  <div className='margin-top'>2) Small</div>  
        <SkeletonDisplayText size="small" />        


   </Page>
  )
}

export default SkeletonDisplayTextEx
