import React from 'react'
import {Divider, Text, VerticalStack, Page} from '@shopify/polaris';

function DividerEx() {
  return (
    <Page>
        <div>
            <VerticalStack gap="5">
                <Text as="h1" variant="headingXs">
                    Default
                </Text>
                <Divider />
                <Text as="h1" variant="headingXs">
                    Border
                </Text>
                <Divider borderColor="border" />
                <Text as="h1" variant="headingXs">
                    Border inverse
                </Text>
                <Divider borderColor="border-inverse" />
                <Text as="h1" variant="headingXs">
                    Transparent
                </Text>
                <Divider borderColor="transparent" />
            </VerticalStack>
        </div>
    </Page>
  )
}

export default DividerEx
