import {
    ChoiceList,
    TextField,
    LegacyCard,
    Filters,
    DataTable,
  } from '@shopify/polaris';
  import {useState, useCallback} from 'react';
  
  function WithaDataTable() {
    const [availability, setAvailability] = useState([]);
    const [productType, setProductType] = useState([]);
    const [taggedWith, setTaggedWith] = useState('');
    const [queryValue, setQueryValue] = useState('');
  
    const handleAvailabilityChange = useCallback(
      (value: string[]) => setAvailability(value),
      [],
    );
    const handleProductTypeChange = useCallback(
      (value: string[]) => setProductType(value),
      [],
    );
    const handleTaggedWithChange = useCallback(
      (value: string) => setTaggedWith(value),
      [],
    );
    const handleFiltersQueryChange = useCallback(
      (value: string) => setQueryValue(value),
      [],
    );
    const handleAvailabilityRemove = useCallback(() => setAvailability([]), []);
    const handleProductTypeRemove = useCallback(() => setProductType([]), []);
    const handleTaggedWithRemove = useCallback(() => setTaggedWith(''), []);
    const handleQueryValueRemove = useCallback(() => setQueryValue(''), []);
    const handleFiltersClearAll = useCallback(() => {
      handleAvailabilityRemove();
      handleProductTypeRemove();
      handleTaggedWithRemove();
      handleQueryValueRemove();
    }, [
      handleAvailabilityRemove,
      handleQueryValueRemove,
      handleProductTypeRemove,
      handleTaggedWithRemove,
    ]);
  
    const filters = [
      {
        key: 'availability',
        label: 'Availability',
        filter: (
          <ChoiceList
            title="Availability"
            titleHidden
            choices={[
              {label: 'Online Store', value: 'Online Store'},
              {label: 'Point of Sale', value: 'Point of Sale'},
              {label: 'Buy Button', value: 'Buy Button'},
            ]}
            selected={availability || []}
            onChange={handleAvailabilityChange}
            allowMultiple
          />
        ),
        shortcut: true,
      },
      {
        key: 'productType',
        label: 'Product type',
        filter: (
          <ChoiceList
            title="Product type"
            titleHidden
            choices={[
              {label: 'T-Shirt', value: 'T-Shirt'},
              {label: 'Accessory', value: 'Accessory'},
              {label: 'Gift card', value: 'Gift card'},
            ]}
            selected={productType || []}
            onChange={handleProductTypeChange}
            allowMultiple
          />
        ),
      },
      {
        key: 'taggedWith',
        label: 'Tagged with',
        filter: (
          <TextField
            label="Tagged with"
            value={taggedWith}
            onChange={handleTaggedWithChange}
            autoComplete="off"
            labelHidden
          />
        ),
      },
    ];
  
    const appliedFilters = [];
    if (!isEmpty(availability)) {
      const key = 'availability';
      appliedFilters.push({
        key,
        label: disambiguateLabel(key, availability),
        onRemove: handleAvailabilityRemove,
      });
    }
    if (!isEmpty(productType)) {
      const key = 'productType';
      appliedFilters.push({
        key,
        label: disambiguateLabel(key, productType),
        onRemove: handleProductTypeRemove,
      });
    }
    if (!isEmpty(taggedWith)) {
      const key = 'taggedWith';
      appliedFilters.push({
        key,
        label: `Tagged with ${taggedWith}`,
        onRemove: handleTaggedWithRemove,
      });
    }
  
    return (
      <div style={{height: '568px'}}>
        <LegacyCard>
          <Filters
            queryValue={queryValue}
            queryPlaceholder="Search items"
            filters={filters}
            appliedFilters={appliedFilters}
            onQueryChange={handleFiltersQueryChange}
            onQueryClear={handleQueryValueRemove}
            onClearAll={handleFiltersClearAll}
          />
          <DataTable
            columnContentTypes={[
              'text',
              'numeric',
              'numeric',
              'numeric',
              'numeric',
            ]}
            headings={[
              'Product',
              'Price',
              'SKU Number',
              'Net quantity',
              'Net sales',
            ]}
            rows={[
              ['Emerald Silk Gown', '$875.00', 124689, 140, '$122,500.00'],
              ['Mauve Cashmere Scarf', '$230.00', 124533, 83, '$19,090.00'],
              [
                'Navy Merino Wool Blazer with khaki chinos and yellow belt',
                '$445.00',
                124518,
                32,
                '$14,240.00',
              ],
            ]}
            totals={['', '', '', 255, '$155,830.00']}
          />
        </LegacyCard>
      </div>
    );
  
    function disambiguateLabel(key: string, value: string[]): string {
      switch (key) {
        case 'taggedWith':
          return `Tagged with ${value}`;
        case 'availability':
          return value.map((val) => `Available on ${val}`).join(', ');
        case 'productType':
          return value.join(', ');
        default:
          return value.toString();
      }
    }
  
    function isEmpty(value: string | string[]): boolean {
      if (Array.isArray(value)) {
        return value.length === 0;
      } else {
        return value === '' || value == null;
      }
    }
  }

  export default WithaDataTable