import { TextContainer, Text, Page, LegacyCard } from '@shopify/polaris';
import React from 'react';

function TextContainerEx() {
    return (
        <Page>
            <LegacyCard sectioned>

                {/* Default - do not use div tag */}
                <div>1:- Default</div>
                <TextContainer>
                    <Text variant="headingMd" as="h2">
                        Install the Shopify POS App
                    </Text>
                    <p>
                        Shopify POS is the easiest way to sell your products in person.
                        Available for iPad, iPhone, and Android.
                    </p>
                </TextContainer>


                {/* Tight - do not use div tag */}
                <div className='margin-top'>2:- Tight</div>
                <TextContainer spacing="tight">
                    <Text variant="headingMd" as="h2">
                        Install the Shopify POS App
                    </Text>
                    <p>
                        Shopify POS is the easiest way to sell your products in person.
                        Available for iPad, iPhone, and Android.
                    </p>
                </TextContainer>


                {/* Loose - do not use div tag */}
                <div className='margin-top'>3:- Loose</div>
                <TextContainer spacing="loose">
                    <p>
                        Manage your Shopify store on-the-go with real-time notifications, access
                        to your dashboard, and order management, all from your smartphone.
                    </p>
                    <p>
                        Shopify POS is the fastest and easiest way to start accepting Visa,
                        Mastercard, American Express, and Discover right from your smartphone or
                        tablet.
                    </p>
                </TextContainer>

            </LegacyCard>
        </Page>
    );
}

export default TextContainerEx