import {DropZone} from '@shopify/polaris';
import React from 'react';

function SmallSized() {
  return (
    <div style={{width: 50, height: 50}}>
      <DropZone>
        <DropZone.FileUpload />
      </DropZone>
    </div>
  );
}

export default SmallSized