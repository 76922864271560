import {
    EmptySearchResult,
    IndexTable,
    LegacyCard,
    Text,
} from '@shopify/polaris';
import React from 'react';

function IndexTableWithEmptyState() {
    const customers: {
        id: string;
        name: string;
        location: string;
        orders: number;
        amountSpent: string;
    }[] = [];
    const resourceName = {
        singular: 'customer',
        plural: 'customers',
    };

    const emptyStateMarkup = (
        <EmptySearchResult
            title={'No customers yet'}
            description={'Try changing the filters or search term'}
            withIllustration
        />
    );

    const rowMarkup = customers.map(
        ({ id, name, location, orders, amountSpent }, index) => (
            <IndexTable.Row id={id} key={id} position={index}>
                <IndexTable.Cell>
                    <Text fontWeight="bold" as="span">
                        {name}
                    </Text>
                </IndexTable.Cell>
                <IndexTable.Cell>{location}</IndexTable.Cell>
                <IndexTable.Cell>
                    <Text as="span" alignment="end" numeric>
                        {orders}
                    </Text>
                </IndexTable.Cell>
                <IndexTable.Cell>
                    <Text as="span" alignment="end" numeric>
                        {amountSpent}
                    </Text>
                </IndexTable.Cell>
            </IndexTable.Row>
        ),
    );

    return (
        <LegacyCard>
            <IndexTable
                resourceName={resourceName}
                itemCount={customers.length}
                emptyState={emptyStateMarkup}
                headings={[
                    { title: 'Name' },
                    { title: 'Location' },
                    {
                        id: 'order-count',
                        title: (
                            <Text as="span" alignment="end">
                                Order count
                            </Text>
                        ),
                    },
                    {
                        id: 'amount-spent',
                        title: (
                            <Text as="span" alignment="end">
                                Amount spent
                            </Text>
                        ),
                    },
                ]}
            >
                {rowMarkup}
            </IndexTable>
        </LegacyCard>
    );
}

export default IndexTableWithEmptyState