import {LegacyCard, TextField, Page} from '@shopify/polaris';
import {useState, useCallback} from 'react';

function Email() {
  const [value, setValue] = useState('bernadette.lapresse@jadedpixel.com');

  const handleChange = useCallback(
    (newValue: string) => setValue(newValue),
    [],
  );

  return (
    <Page>
        <LegacyCard sectioned>
            <TextField
                label="Email"
                type="email"
                value={value}
                onChange={handleChange}
                autoComplete="email"
                />
        </LegacyCard>
    </Page>
    
  );
}

export default Email