import { LegacyCard, Page, TextField } from '@shopify/polaris';
import { useState, useCallback } from 'react';

function TextFieldWithValidationError() {
    const [textFieldValue, setTextFieldValue] = useState('');

    const handleTextFieldChange = useCallback(
        (value: string) => setTextFieldValue(value),
        [],
    );

    return (
        <Page>
            <LegacyCard sectioned>
                <TextField
                    label="Store name"
                    value={textFieldValue}
                    onChange={handleTextFieldChange}
                    error="Store name is required"
                    autoComplete="off"
                />
            </LegacyCard>
        </Page>
    );
}

export default TextFieldWithValidationError