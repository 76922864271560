import React from 'react'
import {MediaCard, Page, VideoThumbnail} from '@shopify/polaris';

function MediaCardEx() {
  return (
    <Page>

        {/*Default - do not use div tag*/}
        <div className='margin-top'>1) Default</div>
            <MediaCard
                title="Getting Started"
                primaryAction={{
                    content: 'Learn about getting started',
                    onAction: () => {},
                }}
                description="Discover how Shopify can power up your entrepreneurial journey."
                popoverActions={[{content: 'Dismiss', onAction: () => {}}]}
                >
                <img
                    alt=""
                    width="100%"
                    height="100%"
                    style={{
                    objectFit: 'cover',
                    objectPosition: 'center',
                    }}
                    src="https://burst.shopifycdn.com/photos/business-woman-smiling-in-office.jpg?width=1850"
                />
            </MediaCard>


        {/*With small visual - do not use div tag*/}
        <div className='margin-top'>2) With small visual</div>
            <MediaCard
                title="Getting Started"
                primaryAction={{
                    content: 'Learn about getting started',
                    onAction: () => {},
                }}
                description="Discover how Shopify can power up your entrepreneurial journey."
                popoverActions={[{content: 'Dismiss', onAction: () => {}}]}
                size="small"
                >
                <img
                    alt=""
                    width="100%"
                    height="100%"
                    style={{
                    objectFit: 'cover',
                    objectPosition: 'center',
                    }}
                    src="https://burst.shopifycdn.com/photos/business-woman-smiling-in-office.jpg?width=1850"
                />
            </MediaCard>



        {/*With secondary action - do not use div tag*/}
        <div className='margin-top'>3) With secondary action</div>
            <MediaCard
                title="Get closer to launching your store"
                primaryAction={{
                    content: 'Add a product',
                    onAction: () => {},
                }}
                secondaryAction={{
                    content: 'Learn more',
                    onAction: () => {},
                }}
                description="Start your business with eye-catching inventory."
                popoverActions={[{content: 'Dismiss', onAction: () => {}}]}
                >
                <img
                    alt=""
                    width="100%"
                    height="100%"
                    style={{objectFit: 'cover', objectPosition: 'center'}}
                    src="https://burst.shopifycdn.com/photos/business-woman-smiling-in-office.jpg?width=1850"
                />
            </MediaCard>


        {/*With no actions - do not use div tag*/}
        <div className='margin-top'>4) With no actions</div>
            <MediaCard
                title="Getting Started"
                description="Discover how Shopify can power up your entrepreneurial journey."
                popoverActions={[{content: 'Dismiss', onAction: () => {}}]}
                >
                <img
                    alt=""
                    width="100%"
                    height="100%"
                    style={{objectFit: 'cover', objectPosition: 'center'}}
                    src="https://burst.shopifycdn.com/photos/business-woman-smiling-in-office.jpg?width=1850"
                />
            </MediaCard>



        {/*Video card - do not use div tag*/}
        <div className='margin-top'>5) Video card</div>
            <MediaCard
                title="Turn your side-project into a business"
                primaryAction={{
                    content: 'Learn more',
                    onAction: () => {},
                }}
                description={`In this course, you’ll learn how the Kular family turned their mom’s recipe book into a global business.`}
                popoverActions={[{content: 'Dismiss', onAction: () => {}}]}
                >
                <VideoThumbnail
                    videoLength={80}
                    thumbnailUrl="https://burst.shopifycdn.com/photos/business-woman-smiling-in-office.jpg?width=1850"
                    onClick={() => console.log('clicked')}
                />
            </MediaCard>



        {/*Portrait video card - do not use div tag*/}
        <div className='margin-top'>6) Portrait video card</div>
            <MediaCard
                portrait
                title="Turn your side-project into a business"
                primaryAction={{
                    content: 'Learn more',
                    onAction: () => {},
                }}
                description="In this course, you’ll learn how the Kular family turned their mom’s recipe book into a global business."
                popoverActions={[{content: 'Dismiss', onAction: () => {}}]}
                >
                <VideoThumbnail
                    videoLength={80}
                    thumbnailUrl="https://burst.shopifycdn.com/photos/business-woman-smiling-in-office.jpg?width=1850"
                    onClick={() => console.log('clicked')}
                />
            </MediaCard>        

    </Page>
  )
}

export default MediaCardEx
