import {
    LegacyCard,
    LegacyStack,
    Button,
    Collapsible,
    TextContainer,
    Link,
    Page,
} from '@shopify/polaris';
import { useState, useCallback } from 'react';

function CollapsibleEx() {
    const [open, setOpen] = useState(true);

    const handleToggle = useCallback(() => setOpen((open) => !open), []);

    return (
        <Page>
            <div style={{ height: '200px' }}>
                <LegacyCard sectioned>
                    <LegacyStack vertical>
                        <Button
                            onClick={handleToggle}
                            ariaExpanded={open}
                            ariaControls="basic-collapsible"
                        >
                            Toggle
                        </Button>
                        <Collapsible
                            open={open}
                            id="basic-collapsible"
                            transition={{ duration: '500ms', timingFunction: 'ease-in-out' }}
                            expandOnPrint
                        >
                            <TextContainer>
                                <p>
                                    Your mailing list lets you contact customers or visitors who
                                    have shown an interest in your store. Reach out to them with
                                    exclusive offers or updates about your products.
                                </p>
                                <Link url="#">Test link</Link>
                            </TextContainer>
                        </Collapsible>
                    </LegacyStack>
                </LegacyCard>
            </div>
        </Page>
    );
}

export default CollapsibleEx