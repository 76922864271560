import {Button, Popover, ActionList, Page, LegacyCard} from '@shopify/polaris';
import {useState, useCallback} from 'react';

function ActionListWithHelpText() {
  const [active, setActive] = useState(true);

  const toggleActive = useCallback(() => setActive((active) => !active), []);

  const activator = (
    <Button onClick={toggleActive} disclosure>
      More actions
    </Button>
  );

  return (
    <Page>
      <LegacyCard sectioned>
      <div style={{height: '250px'}}>
      <Popover
        active={active}
        activator={activator}
        autofocusTarget="first-node"
        onClose={toggleActive}
      >
        <ActionList
          actionRole="menuitem"
          sections={[
            {
              items: [
                {
                  content: 'Blog posts',
                  helpText: 'Manage your blog articles',
                },
                {
                  content: 'Blogs',
                  helpText: 'Manage blogs published to your Online Store',
                },
              ],
            },
          ]}
        />
      </Popover>
    </div>
      </LegacyCard>
    </Page>
  );
}

export default ActionListWithHelpText