import React from 'react'
import {Page, Badge} from '@shopify/polaris';

function BadgeEx() {
  return (
    <Page>

           {/*Default - do not use div tag*/}
        <div className='margin-top'>1) Default</div>
            <Badge>Fulfilled</Badge>


           {/*Small - do not use div tag*/}
        <div className='margin-top'>2) Small</div>
            <Badge size="small">Fulfilled</Badge>


           {/*Informational - do not use div tag*/}
        <div className='margin-top'>3) Informational</div>
           <Badge status="info">Draft</Badge>


           {/*Success - do not use div tag*/}
        <div className='margin-top'>4) Success</div>
           <Badge status="success">Active</Badge> 



           {/*Attention - do not use div tag*/}
        <div className='margin-top'>5) Attention</div>
           <Badge status="attention">Open</Badge> 



           {/*Warning - do not use div tag*/}
        <div className='margin-top'>6) Warning</div>
           <Badge status="warning">On hold</Badge> 


           {/*Critical - do not use div tag*/}
        <div className='margin-top'>7) Critical</div>
           <Badge status="critical">Action required</Badge>       


            {/*Incomplete - do not use div tag*/}
        <div className='margin-top'>8) Incomplete</div>
            <Badge progress="incomplete" status="attention">
                Unfulfilled
            </Badge>       


            {/*Partially complete - do not use div tag*/}
        <div className='margin-top'>9) Partially complete</div>
            <Badge progress="partiallyComplete" status="warning">
                Partially fulfilled
            </Badge>


             {/*Complete - do not use div tag*/}
        <div className='margin-top'>10) Complete</div>
            <Badge progress="complete">Fulfilled</Badge>   


             {/*With statusAndProgressLabelOverride - do not use div tag*/}
        <div className='margin-top'>11) With statusAndProgressLabelOverride</div>
            <Badge
                status="success"
                progress="complete"
                statusAndProgressLabelOverride="Status: Published. Your online store is visible."
                >
                Published
            </Badge>                  
    </Page>
  )
}

export default BadgeEx
