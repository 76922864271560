import {
    LegacyCard,
    ResourceList,
    Avatar,
    ResourceItem,
    Text,
    Page,
} from '@shopify/polaris';
import React from 'react';

function WithItemShortcutActions() {
    return (
        <Page>
            <LegacyCard>
                <ResourceList
                    resourceName={{ singular: 'customer', plural: 'customers' }}
                    items={[
                        {
                            id: '109',
                            url: '#',
                            name: 'Mae Jemison',
                            location: 'Decatur, USA',
                            latestOrderUrl: '#',
                        },
                        {
                            id: '209',
                            url: '#',
                            name: 'Ellen Ochoa',
                            location: 'Los Angeles, USA',
                            latestOrderUrl: '#',
                        },
                    ]}
                    renderItem={(item) => {
                        const { id, url, name, location, latestOrderUrl } = item;
                        const media = <Avatar customer size="medium" name={name} />;
                        const shortcutActions = latestOrderUrl
                            ? [
                                {
                                    content: 'View latest order',
                                    accessibilityLabel: `View ${name}’s latest order`,
                                    url: latestOrderUrl,
                                },
                            ]
                            : undefined;

                        return (
                            <ResourceItem
                                id={id}
                                url={url}
                                media={media}
                                accessibilityLabel={`View details for ${name}`}
                                shortcutActions={shortcutActions}
                            >
                                <Text variant="bodyMd" fontWeight="bold" as="h3">
                                    {name}
                                </Text>
                                <div>{location}</div>
                            </ResourceItem>
                        );
                    }}
                />
            </LegacyCard>
        </Page>
    );
}

export default WithItemShortcutActions