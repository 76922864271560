import { LegacyCard, Tag, Page, LegacyStack, Icon } from "@shopify/polaris";
import { WandMinor } from "@shopify/polaris-icons";
import React from "react";

function TagEx() {
  return (
    <Page>
      <LegacyCard sectioned>
        {/* Default - do not use div tag */}
        <div>1:- Default</div>
        <Tag>Wholesale</Tag>

        {/* Clickable - do not use div tag */}
        <div className="margin-top">2:- Clickable</div>
        <Tag onClick={() => console.log("Clicked")}>Wholesale</Tag>

        {/* with link - do not use div tag */}
        <div className="margin-top">3:- With Link</div>
        <Tag url="#">Wholesale</Tag>

        {/* With custom content - do not use div tag */}
        <div className="margin-top">4:- With custom content</div>
        <Tag url="#">
          <LegacyStack spacing="extraTight">
            <Icon source={WandMinor} />
            <span>Wholesale</span>
          </LegacyStack>
        </Tag>
      </LegacyCard>
    </Page>
  );
}

export default TagEx;
