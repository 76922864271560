import { LegacyCard, Page, Thumbnail } from '@shopify/polaris';
import { NoteMinor } from '@shopify/polaris-icons';
import React from 'react';

function ThumbnailEx() {
    return (
        <Page>
            <LegacyCard sectioned>

                {/* Default - do not use div tag */}
                <div>1:- Default</div>
                <Thumbnail source={NoteMinor} size="large" alt="Small document" />


                {/* Extra small - do not use div tag */}
                <div className='margin-top'>2:- Extra small</div>
                <Thumbnail
                    source="https://burst.shopifycdn.com/photos/black-leather-choker-necklace_373x@2x.jpg"
                    size="extraSmall"
                    alt="Black choker necklace"
                />


                {/* Small - do not use div tag */}
                <div className='margin-top'>3:- Small</div>
                <Thumbnail
                    source="https://burst.shopifycdn.com/photos/black-leather-choker-necklace_373x@2x.jpg"
                    size="small"
                    alt="Black choker necklace"
                />


                {/* Large - do not use div tag */}
                <div className='margin-top'>4:- Large</div>
                <Thumbnail
                    source="https://burst.shopifycdn.com/photos/black-leather-choker-necklace_373x@2x.jpg"
                    size="large"
                    alt="Black choker necklace"
                />


                {/* With component source - do not use div tag */}
                <div className='margin-top'>4:- With component source</div>
                <Thumbnail source={NoteMinor} size="large" alt="Small document" />
            </LegacyCard>
        </Page>
    );
}

export default ThumbnailEx