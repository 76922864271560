import {ChoiceList, LegacyCard, Page} from '@shopify/polaris';
import {useState, useCallback} from 'react';

function ChoiceDefault() {
  const [selected, setSelected] = useState(['hidden']);

  const handleChange = useCallback((value: string[]) => setSelected(value), []);

  return (
    <Page>
        <LegacyCard sectioned>
            <ChoiceList
            title="Company name"
            choices={[
                {label: 'Hidden', value: 'hidden'},
                {label: 'Optional', value: 'optional'},
                {label: 'Required', value: 'required'},
            ]}
            selected={selected}
            onChange={handleChange}
            />
        </LegacyCard>
    </Page>
  );
}

export default ChoiceDefault