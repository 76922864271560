import { Tooltip, Text, Page, LegacyCard, ButtonGroup, Button, TextField, HorizontalStack } from '@shopify/polaris';
import React from 'react';

function TooltipEx() {
    return (
        <Page>
            <LegacyCard sectioned>
                {/* Default - do not use first div tag */}
                <div>1:- Default</div>
                <div>
                    <Tooltip active content="This order has shipping labels.">
                        <Text fontWeight="bold" as="span">
                            Order #1001
                        </Text>
                    </Tooltip>
                </div>


                {/* Visible only with child interaction - do not use first div tag */}
                <div className='margin-top'>2:- Visible only with child interaction</div>
                <div style={{ width: '200px' }}>
                    <ButtonGroup segmented fullWidth>
                        <Tooltip content="Bold" dismissOnMouseOut>
                            <Button>B</Button>
                        </Tooltip>
                        <Tooltip content="Italic" dismissOnMouseOut>
                            <Button>I</Button>
                        </Tooltip>
                        <Tooltip content="Underline" dismissOnMouseOut>
                            <Button>U</Button>
                        </Tooltip>
                        <Tooltip content="Strikethrough" dismissOnMouseOut>
                            <Button>S</Button>
                        </Tooltip>
                    </ButtonGroup>
                    <TextField
                        label="Product title"
                        autoComplete="off"
                        labelHidden
                        multiline
                    />
                </div>


                {/* With persistence on click - do not use first div tag */}
                <div className='margin-top'>3:- With persistence on click</div>
                <div>
                    <Tooltip persistOnClick active content="This order has shipping labels.">
                        <Text fontWeight="bold" as="span">
                            Order #1001
                        </Text>
                    </Tooltip>
                </div>


                {/* With suffix - do not use first div tag */}
                <div className='margin-top'>3:- With suffix</div>
                <div style={{ padding: '75px 0' }}>
                    <Tooltip
                        content={
                            <HorizontalStack gap="2">
                                Bold
                                <Text as="span" variant="bodyMd" color="subdued">
                                    ⌘B
                                </Text>
                            </HorizontalStack>
                        }
                    >
                        <Button>B</Button>
                    </Tooltip>
                </div>
                
            </LegacyCard>
        </Page>
    );
}

export default TooltipEx