import React from "react";
import { Link } from "react-router-dom";

function Sidebar() {
  return (
    <nav className="main-menu">
      <ul>
        <li className="nav-main-head">Actions</li>
        <li className="has-subnav">
          <Link to="/">
            <span className="nav-text">Account connection</span>
          </Link>
        </li>
        <li className="has-subnav">
          <Link to="/actions/button">
            <span className="nav-text">Button</span>
          </Link>
        </li>
        <li className="has-subnav">
          <Link to="/actions/button-group">
            <span className="nav-text">Button Group</span>
          </Link>
        </li>
        <li className="has-subnav">
          <Link to="/actions/page-actions">
            <span className="nav-text">Page actions</span>
          </Link>
        </li>

        <li className="nav-main-head">Layout and structure</li>
        <li className="has-subnav dropdown">
          <a href="#" className="dropbtn">
            <span className="nav-text">Bleed</span>
          </a>
          <div className="dropdown-content">
            <Link to="/layout-and-structure/bleed/horizontal">
              <span className="nav-text">Horizontal</span>
            </Link>
            <Link to="/layout-and-structure/bleed/vertical">
              <span className="nav-text">Vertical</span>
            </Link>
            <Link to="/layout-and-structure/bleed/specific-direction">
              <span className="nav-text">Specific direction</span>
            </Link>
          </div>
        </li>
        <li className="has-subnav dropdown">
          <a href="#" className="dropbtn">
            <span className="nav-text">Box</span>
          </a>
          <div className="dropdown-content">
            <Link to="/layout-and-structure/box/color">
              <span className="nav-text">Color</span>
            </Link>
            <Link to="/layout-and-structure/box/border-width">
              <span className="nav-text">Border width</span>
            </Link>
            <Link to="/layout-and-structure/box/border-radius">
              <span className="nav-text">Border radius</span>
            </Link>
            <Link to="/layout-and-structure/box/padding">
              <span className="nav-text">Padding</span>
            </Link>
            <Link to="/layout-and-structure/box/shadow">
              <span className="nav-text">Shadow</span>
            </Link>
          </div>
        </li>
        <li className="has-subnav">
          <Link to="/layout-and-structure/callout-card">
            <span className="nav-text">Callout card</span>
          </Link>
        </li>
        <li className="has-subnav">
          <Link to="/layout-and-structure/card">
            <span className="nav-text">Card</span>
          </Link>
        </li>
        <li className="has-subnav">
          <Link to="/layout-and-structure/divider">
            <span className="nav-text">Divider</span>
          </Link>
        </li>
        <li className="has-subnav">
          <Link to="/layout-and-structure/empty-state">
            <span className="nav-text">Empty state</span>
          </Link>
        </li>
        <li className="has-subnav">
          <Link to="/layout-and-structure/form-layout">
            <span className="nav-text">Form layout</span>
          </Link>
        </li>
        <li className="has-subnav">
          <Link to="/layout-and-structure/grid">
            <span className="nav-text">Grid</span>
          </Link>
        </li>
        <li className="has-subnav dropdown">
          <a href="#" className="dropbtn">
            <span className="nav-text">Horizontal grid</span>
          </a>
          <div className="dropdown-content">
            <Link to="/layout-and-structure/horizontal-grid/gap">
              <span className="nav-text">Gap</span>
            </Link>
            <Link to="/layout-and-structure/horizontal-grid/column-width">
              <span className="nav-text">Column width</span>
            </Link>
            <Link to="/layout-and-structure/horizontal-grid/number-of-columns">
              <span className="nav-text">Number of columns</span>
            </Link>
          </div>
        </li>
        <li className="has-subnav dropdown">
          <a href="#" className="dropbtn">
            <span className="nav-text">Horizontal stack</span>
          </a>
          <div className="dropdown-content">
            <Link to="/layout-and-structure/horizontal-stack/non-wrapping">
              <span className="nav-text">Non-wrapping</span>
            </Link>
            <Link to="/layout-and-structure/horizontal-stack/gap">
              <span className="nav-text">Gap</span>
            </Link>
            <Link to="/layout-and-structure/horizontal-stack/block-align">
              <span className="nav-text">Block align</span>
            </Link>
            <Link to="/layout-and-structure/horizontal-stack/align">
              <span className="nav-text">Align</span>
            </Link>
          </div>
        </li>
        <li className="has-subnav">
          <Link to="/layout-and-structure/layout">
            <span className="nav-text">Layout</span>
          </Link>
        </li>
        <li className="has-subnav">
          <Link to="/layout-and-structure/legacy-card">
            <span className="nav-text">Legacy card</span>
          </Link>
        </li>
        <li className="has-subnav">
          <Link to="/layout-and-structure/legacy-stack">
            <span className="nav-text">Legacy stack</span>
          </Link>
        </li>
        <li className="has-subnav">
          <Link to="/layout-and-structure/media-card">
            <span className="nav-text">Media card</span>
          </Link>
        </li>
        <li className="has-subnav">
          <Link to="/layout-and-structure/page">
            <span className="nav-text">Page</span>
          </Link>
        </li>
        <li className="has-subnav dropdown">
          <a href="#" className="dropbtn">
            <span className="nav-text">Vertical stack</span>
          </a>
          <div className="dropdown-content">
            <Link to="/layout-and-structure/vertical-stack/gap">
              <span className="nav-text">Gap</span>
            </Link>
            <Link to="/layout-and-structure/vertical-stack/align">
              <span className="nav-text">Align</span>
            </Link>
            <Link to="/layout-and-structure/vertical-stack/inline-align">
              <span className="nav-text">Inline align</span>
            </Link>
          </div>
        </li>

        <li className="nav-main-head">Selection and input</li>
        <li className="has-subnav dropdown">
          <a href="#" className="dropbtn">
            <span className="nav-text">Autocomplete</span>
          </a>
          <div className="dropdown-content">
            <Link to="/selection-and-input/autocomplete/default">
              <span className="nav-text">Default</span>
            </Link>
            <Link to="/selection-and-input/autocomplete/with-multiple-tags">
              <span className="nav-text">With multiple tags</span>
            </Link>
            <Link to="/selection-and-input/autocomplete/with-multiple-sections">
              <span className="nav-text">With multiple sections</span>
            </Link>
            <Link to="/selection-and-input/autocomplete/with-loading">
              <span className="nav-text">With loading</span>
            </Link>
            <Link to="/selection-and-input/autocomplete/with-lazy-loading">
              <span className="nav-text">With lazy loading</span>
            </Link>
            <Link to="/selection-and-input/autocomplete/with-empty-state">
              <span className="nav-text">With empty state</span>
            </Link>
            <Link to="/selection-and-input/autocomplete/with-action">
              <span className="nav-text">With action</span>
            </Link>
            <Link to="/selection-and-input/autocomplete/with-wrapping-action">
              <span className="nav-text">With wrapping action</span>
            </Link>
            <Link to="/selection-and-input/autocomplete/with-destructive-action">
              <span className="nav-text">With destructive action</span>
            </Link>
          </div>
        </li>
        <li className="has-subnav">
          <Link to="/selection-and-input/checkbox">
            <span className="nav-text">Checkbox</span>
          </Link>
        </li>
        <li className="has-subnav dropdown">
          <a href="#" className="dropbtn">
            <span className="nav-text">Choice list</span>
          </a>
          <div className="dropdown-content">
            <Link to="/selection-and-input/choice-list/default">
              <span className="nav-text">Default</span>
            </Link>
            <Link to="/selection-and-input/choice-list/with-error">
              <span className="nav-text">With error</span>
            </Link>
            <Link to="/selection-and-input/choice-list/with-multi-choice">
              <span className="nav-text">With multi-choice</span>
            </Link>
            <Link to="/selection-and-input/choice-list/with-children-content">
              <span className="nav-text">With children content</span>
            </Link>
            <Link to="/selection-and-input/choice-list/with-dynamic-children-content">
              <span className="nav-text">With dynamic children content</span>
            </Link>
          </div>
        </li>
        <li className="has-subnav dropdown">
          <a href="#" className="dropbtn">
            <span className="nav-text">Color picker</span>
          </a>
          <div className="dropdown-content">
            <Link to="/selection-and-input/color-picker/default">
              <span className="nav-text">Default</span>
            </Link>
            <Link to="/selection-and-input/color-picker/with-transparent-value">
              <span className="nav-text">With transparent value</span>
            </Link>
            <Link to="/selection-and-input/color-picker/with-transparent-value-full-width">
              <span className="nav-text">
                With transparent value full width
              </span>
            </Link>
          </div>
        </li>
        <li className="has-subnav dropdown">
          <a href="#" className="dropbtn">
            <span className="nav-text">Combobox</span>
          </a>
          <div className="dropdown-content">
            <Link to="/selection-and-input/combobox/default">
              <span className="nav-text">Default</span>
            </Link>
            <Link to="/selection-and-input/combobox/with-manual-selection">
              <span className="nav-text">With manual selection</span>
            </Link>
            <Link to="/selection-and-input/combobox/with-multi-select">
              <span className="nav-text">With multi-select</span>
            </Link>
            <Link to="/selection-and-input/combobox/with-multi-select-and-manual-selection">
              <span className="nav-text">
                With multi-select and manual selection
              </span>
            </Link>
            <Link to="/selection-and-input/combobox/with-multi-select-and-vertical-content">
              <span className="nav-text">
                With multi-select and vertical content
              </span>
            </Link>
            <Link to="/selection-and-input/combobox/with-loading">
              <span className="nav-text">With loading</span>
            </Link>
          </div>
        </li>
        <li className="has-subnav">
          <Link to="/selection-and-input/contextual-save-bar">
            <span className="nav-text">Contextual save bar</span>
          </Link>
        </li>
        <li className="has-subnav dropdown">
          <a href="#" className="dropbtn">
            <span className="nav-text">Date picker</span>
          </a>
          <div className="dropdown-content">
            <Link to="/selection-and-input/date-picker/default">
              <span className="nav-text">Default</span>
            </Link>
            <Link to="/selection-and-input/date-picker/ranged">
              <span className="nav-text">Ranged</span>
            </Link>
            <Link to="/selection-and-input/date-picker/multi-month-ranged">
              <span className="nav-text">Multi-month ranged</span>
            </Link>
            <Link to="/selection-and-input/date-picker/with-disabled-date-ranges">
              <span className="nav-text">With disabled date ranges</span>
            </Link>
            <Link to="/selection-and-input/date-picker/with-specific-disabled-dates">
              <span className="nav-text">With specific disabled dates</span>
            </Link>
          </div>
        </li>
        <li className="has-subnav dropdown">
          <a href="#" className="dropbtn">
            <span className="nav-text">Drop zone</span>
          </a>
          <div className="dropdown-content">
            <Link to="/selection-and-input/drop-zone/default">
              <span className="nav-text">Default</span>
            </Link>
            <Link to="/selection-and-input/drop-zone/with-a-label">
              <span className="nav-text">With a label</span>
            </Link>
            <Link to="/selection-and-input/drop-zone/with-image-file-upload">
              <span className="nav-text">With image file upload</span>
            </Link>
            <Link to="/selection-and-input/drop-zone/with-single-file-upload">
              <span className="nav-text">With single file upload</span>
            </Link>
            <Link to="/selection-and-input/drop-zone/with-drop-on-page">
              <span className="nav-text">With drop on page</span>
            </Link>
            <Link to="/selection-and-input/drop-zone/accepts-only-SVG-files">
              <span className="nav-text">Accepts only SVG files</span>
            </Link>
            <Link to="/selection-and-input/drop-zone/nested">
              <span className="nav-text">Nested</span>
            </Link>
            <Link to="/selection-and-input/drop-zone/medium-sized">
              <span className="nav-text">Medium-sized</span>
            </Link>
            <Link to="/selection-and-input/drop-zone/small-sized">
              <span className="nav-text">Small-sized</span>
            </Link>
            <Link to="/selection-and-input/drop-zone/with-custom-FileUpload-text">
              <span className="nav-text">With custom FileUpload text</span>
            </Link>
            <Link to="/selection-and-input/drop-zone/with-custom-file-dialog-trigger">
              <span className="nav-text">With custom file dialog trigger</span>
            </Link>
          </div>
        </li>
        <li className="has-subnav dropdown">
          <a href="#" className="dropbtn">
            <span className="nav-text">Filters</span>
          </a>
          <div className="dropdown-content">
            <Link to="/selection-and-input/filters/with-a-resource-list">
              <span className="nav-text">With a resource list</span>
            </Link>
            <Link to="/selection-and-input/filters/with-a-data-table">
              <span className="nav-text">With a data table</span>
            </Link>
            <Link to="/selection-and-input/filters/with-children-content">
              <span className="nav-text">With children content</span>
            </Link>
            <Link to="/selection-and-input/filters/disabled">
              <span className="nav-text">Disabled</span>
            </Link>
            <Link to="/selection-and-input/filters/with-some-disabled">
              <span className="nav-text">With some disabled</span>
            </Link>
            <Link to="/selection-and-input/filters/with-query-field-hidden">
              <span className="nav-text">With query field hidden</span>
            </Link>
            <Link to="/selection-and-input/filters/with-query-field-disabled">
              <span className="nav-text">With query field disabled</span>
            </Link>
          </div>
        </li>
        <li className="has-subnav dropdown">
          <a href="#" className="dropbtn">
            <span className="nav-text">Form</span>
          </a>
          <div className="dropdown-content">
            <Link to="/selection-and-input/form/custom-onsubmit">
              <span className="nav-text">Custom onSubmit</span>
            </Link>
            <Link to="/selection-and-input/form/without-native-validation">
              <span className="nav-text">Without native validation</span>
            </Link>
          </div>
        </li>
        <li className="has-subnav dropdown">
          <a href="#" className="dropbtn">
            <span className="nav-text">Index filters</span>
          </a>
          <div className="dropdown-content">
            <Link to="/selection-and-input/index-filters/default">
              <span className="nav-text">Default</span>
            </Link>
            <Link to="/selection-and-input/index-filters/with-pinned-filters">
              <span className="nav-text">With pinned filters</span>
            </Link>
            <Link to="/selection-and-input/index-filters/filtering-mode-default">
              <span className="nav-text">Filtering mode default</span>
            </Link>
            <Link to="/selection-and-input/index-filters/disabled">
              <span className="nav-text">Disabled</span>
            </Link>
            <Link to="/selection-and-input/index-filters/with-no-filters">
              <span className="nav-text">With no filters</span>
            </Link>
            <Link to="/selection-and-input/index-filters/with-no-search-or-filters">
              <span className="nav-text">With no search or filters</span>
            </Link>
          </div>
        </li>
        <li className="has-subnav">
          <Link to="/selection-and-input/inline-error">
            <span className="nav-text">Inline error</span>
          </Link>
        </li>
        <li className="has-subnav">
          <Link to="/selection-and-input/radio-button">
            <span className="nav-text">Radio button</span>
          </Link>
        </li>
        <li className="has-subnav dropdown">
          <a href="#" className="dropbtn">
            <span className="nav-text">Range slider</span>
          </a>
          <div className="dropdown-content">
            <Link to="/selection-and-input/range-slider/default">
              <span className="nav-text">Default</span>
            </Link>
            <Link to="/selection-and-input/range-slider/with-min-and-max">
              <span className="nav-text">With min and max</span>
            </Link>
            <Link to="/selection-and-input/range-slider/with-steps">
              <span className="nav-text">With steps</span>
            </Link>
            <Link to="/selection-and-input/range-slider/with-prefix-and-suffix">
              <span className="nav-text">With prefix and suffix</span>
            </Link>
            <Link to="/selection-and-input/range-slider/with-dual-thumb">
              <span className="nav-text">With dual thumb</span>
            </Link>
          </div>
        </li>
        <li className="has-subnav dropdown">
          <a href="#" className="dropbtn">
            <span className="nav-text">Select</span>
          </a>
          <div className="dropdown-content">
            <Link to="/selection-and-input/select/default">
              <span className="nav-text">Default</span>
            </Link>
            <Link to="/selection-and-input/select/with-inline-label">
              <span className="nav-text">With inline label</span>
            </Link>
            <Link to="/selection-and-input/select/disabled">
              <span className="nav-text">Disabled</span>
            </Link>
            <Link to="/selection-and-input/select/with-prefix">
              <span className="nav-text">With prefix</span>
            </Link>
            <Link to="/selection-and-input/select/with-validation-error">
              <span className="nav-text">With validation error</span>
            </Link>
            <Link to="/selection-and-input/select/with-separate-validation-error">
              <span className="nav-text">With separate validation error</span>
            </Link>
          </div>
        </li>
        <li className="has-subnav dropdown">
          <a href="#" className="dropbtn">
            <span className="nav-text">Tag</span>
          </a>
          <div className="dropdown-content">
            <Link to="/selection-and-input/tag/tag-example">
              <span className="nav-text">Tag example</span>
            </Link>
            <Link to="/selection-and-input/tag/removable">
              <span className="nav-text">Removable</span>
            </Link>
            <Link to="/selection-and-input/tag/removable-with-link">
              <span className="nav-text">Removable with link</span>
            </Link>
          </div>
        </li>
        <li className="has-subnav dropdown">
          <a href="#" className="dropbtn">
            <span className="nav-text">Text field</span>
          </a>
          <div className="dropdown-content">
            <Link to="/selection-and-input/text-field/default">
              <span className="nav-text">Default</span>
            </Link>
            <Link to="/selection-and-input/text-field/number">
              <span className="nav-text">Number</span>
            </Link>
            <Link to="/selection-and-input/text-field/email">
              <span className="nav-text">Email</span>
            </Link>
            <Link to="/selection-and-input/text-field/multiline">
              <span className="nav-text">Multiline</span>
            </Link>
            <Link to="/selection-and-input/text-field/with-hidden-label">
              <span className="nav-text">With hidden label</span>
            </Link>
            <Link to="/selection-and-input/text-field/with-label-action">
              <span className="nav-text">With label action</span>
            </Link>
            <Link to="/selection-and-input/text-field/with-right-aligned-text">
              <span className="nav-text">With right aligned text</span>
            </Link>
            <Link to="/selection-and-input/text-field/with-placeholder-text">
              <span className="nav-text">With placeholder text</span>
            </Link>
            <Link to="/selection-and-input/text-field/with-help-text">
              <span className="nav-text">With help text</span>
            </Link>
            <Link to="/selection-and-input/text-field/with-prefix-or-suffix">
              <span className="nav-text">With prefix or suffix</span>
            </Link>
            <Link to="/selection-and-input/text-field/with-vertical-content">
              <span className="nav-text">With vertical content</span>
            </Link>
            <Link to="/selection-and-input/text-field/with-connected-fields">
              <span className="nav-text">With connected fields</span>
            </Link>
            <Link to="/selection-and-input/text-field/with-validation-error">
              <span className="nav-text">With validation error</span>
            </Link>
            <Link to="/selection-and-input/text-field/with-separate-validation-error">
              <span className="nav-text">With separate validation error</span>
            </Link>
            <Link to="/selection-and-input/text-field/disabled">
              <span className="nav-text">Disabled</span>
            </Link>
            <Link to="/selection-and-input/text-field/with-character-count">
              <span className="nav-text">With character count</span>
            </Link>
            <Link to="/selection-and-input/text-field/with-clear-button">
              <span className="nav-text">With clear button</span>
            </Link>
            <Link to="/selection-and-input/text-field/with-monospaced-font">
              <span className="nav-text">With monospaced font</span>
            </Link>
            <Link to="/selection-and-input/text-field/with-value-selected-on-focus">
              <span className="nav-text">With value selected on focus</span>
            </Link>
            <Link to="/selection-and-input/text-field/with-inline-suggestion">
              <span className="nav-text">With inline suggestion</span>
            </Link>
          </div>
        </li>

        <li className="nav-main-head">Images and icons</li>
        <li className="has-subnav">
          <Link to="/images-and-icons/avatar">
            <span className="nav-text">Avatar</span>
          </Link>
        </li>
        <li className="has-subnav">
          <Link to="/images-and-icons/icon">
            <span className="nav-text">Icon</span>
          </Link>
        </li>
        <li className="has-subnav">
          <Link to="/images-and-icons/keyboard-key">
            <span className="nav-text">Keyboard key</span>
          </Link>
        </li>
        <li className="has-subnav">
          <Link to="/images-and-icons/thumbnail">
            <span className="nav-text">Thumbnail</span>
          </Link>
        </li>
        <li className="has-subnav">
          <Link to="/images-and-icons/video-thumbnail">
            <span className="nav-text">Video thumbnail</span>
          </Link>
        </li>

        <li className="nav-main-head">Feedback indicators</li>
        <li className="has-subnav">
          <Link to="/feedback-indicators/badge">
            <span className="nav-text">Badge</span>
          </Link>
        </li>
        <li className="has-subnav">
          <Link to="/feedback-indicators/banner">
            <span className="nav-text">Banner</span>
          </Link>
        </li>
        <li className="has-subnav">
          <Link to="/feedback-indicators/exception-list">
            <span className="nav-text">Exception list</span>
          </Link>
        </li>
        <li className="has-subnav">
          <Link to="/feedback-indicators/loading">
            <span className="nav-text">Loading</span>
          </Link>
        </li>
        <li className="has-subnav">
          <Link to="/feedback-indicators/progress-bar">
            <span className="nav-text">Progress bar</span>
          </Link>
        </li>
        <li className="has-subnav">
          <Link to="/feedback-indicators/skeleton-body-text">
            <span className="nav-text">Skeleton body text</span>
          </Link>
        </li>
        <li className="has-subnav">
          <Link to="/feedback-indicators/skeleton-display-text">
            <span className="nav-text">Skeleton display text</span>
          </Link>
        </li>
        <li className="has-subnav">
          <Link to="/feedback-indicators/skeleton-page">
            <span className="nav-text">Skeleton page</span>
          </Link>
        </li>
        <li className="has-subnav">
          <Link to="/feedback-indicators/skeleton-tabs">
            <span className="nav-text">Skeleton tabs</span>
          </Link>
        </li>
        <li className="has-subnav">
          <Link to="/feedback-indicators/skeleton-thumbnail">
            <span className="nav-text">Skeleton thumbnail</span>
          </Link>
        </li>
        <li className="has-subnav">
          <Link to="/feedback-indicators/spinner">
            <span className="nav-text">Spinner</span>
          </Link>
        </li>
        <li className="has-subnav dropdown">
          <a href="#" className="dropbtn">
            <span className="nav-text">Toast</span>
          </a>
          <div className="dropdown-content">
            <Link to="/feedback-indicators/toast/default">
              <span className="nav-text">Default</span>
            </Link>
            <Link to="/feedback-indicators/toast/multiple-messages">
              <span className="nav-text">Multiple messages</span>
            </Link>
            <Link to="/feedback-indicators/toast/with-custom-duration">
              <span className="nav-text">With custom duration</span>
            </Link>
            <Link to="/feedback-indicators/toast/with-action">
              <span className="nav-text">With action</span>
            </Link>
            <Link to="/feedback-indicators/toast/error">
              <span className="nav-text">Error</span>
            </Link>
          </div>
        </li>

        <li className="nav-main-head">Typography</li>
        <li className="has-subnav">
          <Link to="/typography/text">
            <span className="nav-text">Text</span>
          </Link>
        </li>

        <li className="nav-main-head">Tables</li>
        <li className="has-subnav dropdown">
          <a href="#" className="dropbtn">
            <span className="nav-text">Data table</span>
          </a>
          <div className="dropdown-content">
            <Link to="/tables/data-table/default">
              <span className="nav-text">Default</span>
            </Link>
            <Link to="/tables/data-table/sortable">
              <span className="nav-text">Sortable</span>
            </Link>
            <Link to="/tables/data-table/with-footer">
              <span className="nav-text">With footer</span>
            </Link>
            <Link to="/tables/data-table/with-custom-totals-heading">
              <span className="nav-text">With custom totals heading</span>
            </Link>
            <Link to="/tables/data-table/with-totals-in-footer">
              <span className="nav-text">With totals in footer</span>
            </Link>
            <Link to="/tables/data-table/with-row-heading-links">
              <span className="nav-text">With row heading links</span>
            </Link>
            <Link to="/tables/data-table/with-all-of-its-elements">
              <span className="nav-text">With all of its elements</span>
            </Link>
            <Link to="/tables/data-table/with-fixed-first-columns">
              <span className="nav-text">With fixed first columns</span>
            </Link>
            <Link to="/tables/data-table/with-increased-density-and-zebra-striping">
              <span className="nav-text">
                With increased density and zebra striping
              </span>
            </Link>
            <Link to="/tables/data-table/with-sticky-header-enabled">
              <span className="nav-text">With sticky header enabled</span>
            </Link>
          </div>
        </li>
        <li className="has-subnav dropdown">
          <a href="#" className="dropbtn">
            <span className="nav-text">Index table</span>
          </a>
          <div className="dropdown-content">
            <Link to="/tables/index-table/default">
              <span className="nav-text">Default</span>
            </Link>
            <Link to="/tables/index-table/with-saved-views-search-filtering-and-sorting">
              <span className="nav-text">
                With saved views, search, filtering, and sorting
              </span>
            </Link>
            <Link to="/tables/index-table/with-disabled-rows">
              <span className="nav-text">With disabled rows</span>
            </Link>
            <Link to="/tables/index-table/small-screen">
              <span className="nav-text">Small screen</span>
            </Link>
            <Link to="/tables/index-table/small-screen-with-saved-views-search-filtering-and-sorting">
              <span className="nav-text">
                Small screen with saved views, search, filtering, and sorting
              </span>
            </Link>
            <Link to="/tables/index-table/with-empty-state">
              <span className="nav-text">With empty state</span>
            </Link>
            <Link to="/tables/index-table/with-bulk-actions">
              <span className="nav-text">With bulk actions</span>
            </Link>
            <Link to="/tables/index-table/with-multiple-promoted-bulk-actions">
              <span className="nav-text">
                With multiple promoted bulk actions
              </span>
            </Link>
            <Link to="/tables/index-table/with-bulk-actions-and-selection-across-pages">
              <span className="nav-text">
                With bulk actions and selection across pages
              </span>
            </Link>
            <Link to="/tables/index-table/with-loading-state">
              <span className="nav-text">With loading state</span>
            </Link>
            <Link to="/tables/index-table/with-filtering">
              <span className="nav-text">With filtering</span>
            </Link>
            <Link to="/tables/index-table/with-sticky-last-column">
              <span className="nav-text">With sticky last column</span>
            </Link>
            <Link to="/tables/index-table/with-row-navigation-link">
              <span className="nav-text">With row navigation link</span>
            </Link>
            <Link to="/tables/index-table/without-checkboxes">
              <span className="nav-text">Without checkboxes</span>
            </Link>
          </div>
        </li>

        <li className="nav-main-head">Lists</li>
        <li className="has-subnav dropdown">
          <a href="#" className="dropbtn">
            <span className="nav-text">Action list</span>
          </a>
          <div className="dropdown-content">
            <Link to="/lists/action-list/in-a-popover">
              <span className="nav-text">In a popover</span>
            </Link>
            <Link to="/lists/action-list/with-icons-or-image">
              <span className="nav-text">With icons or image</span>
            </Link>
            <Link to="/lists/action-list/with-an-icon-and-a-suffix">
              <span className="nav-text">With an icon and a suffix</span>
            </Link>
            <Link to="/lists/action-list/with-sections">
              <span className="nav-text">With sections</span>
            </Link>
            <Link to="/lists/action-list/with-destructive-item">
              <span className="nav-text">With destructive item</span>
            </Link>
            <Link to="/lists/action-list/with-help-text">
              <span className="nav-text">With help text</span>
            </Link>
            <Link to="/lists/action-list/with-a-prefix-and-a-suffix">
              <span className="nav-text">With a prefix and a suffix</span>
            </Link>
          </div>
        </li>
        <li className="has-subnav">
          <Link to="/lists/description-list">
            <span className="nav-text">Description list</span>
          </Link>
        </li>
        <li className="has-subnav">
          <Link to="/lists/list">
            <span className="nav-text">List</span>
          </Link>
        </li>
        <li className="has-subnav dropdown">
          <a href="#" className="dropbtn">
            <span className="nav-text">Listbox</span>
          </a>
          <div className="dropdown-content">
            <Link to="/lists/listbox/listbox-example">
              <span className="nav-text">Listbox example</span>
            </Link>
            <Link to="/lists/listbox/with-search">
              <span className="nav-text">With search</span>
            </Link>
          </div>
        </li>
        <li className="has-subnav dropdown">
          <a href="#" className="dropbtn">
            <span className="nav-text">Option list</span>
          </a>
          <div className="dropdown-content">
            <Link to="/lists/option-list/option-list-example">
              <span className="nav-text">Option list example</span>
            </Link>
            <Link to="/lists/option-list/in-a-popover">
              <span className="nav-text">In a popover</span>
            </Link>
          </div>
        </li>
        <li className="has-subnav">
          <Link to="/lists/resource-item">
            <span className="nav-text">Resource item</span>
          </Link>
        </li>
        <li className="has-subnav dropdown">
          <a href="#" className="dropbtn">
            <span className="nav-text">Resource list</span>
          </a>
          <div className="dropdown-content">
            <Link to="/lists/resource-list/default">
              <span className="nav-text">Default</span>
            </Link>
            <Link to="/lists/resource-list/with-empty-state">
              <span className="nav-text">With empty state</span>
            </Link>
            <Link to="/lists/resource-list/with-selection-and-no-bulk-actions">
              <span className="nav-text">
                With selection and no bulk actions
              </span>
            </Link>
            <Link to="/lists/resource-list/with-bulk-actions">
              <span className="nav-text">With bulk actions</span>
            </Link>
            <Link to="/lists/resource-list/with-loading-state">
              <span className="nav-text">With loading state</span>
            </Link>
            <Link to="/lists/resource-list/with-total-count">
              <span className="nav-text">With total count</span>
            </Link>
            <Link to="/lists/resource-list/with-sorting">
              <span className="nav-text">With sorting</span>
            </Link>
            <Link to="/lists/resource-list/with-alternate-tool">
              <span className="nav-text">With alternate tool</span>
            </Link>
            <Link to="/lists/resource-list/with-filtering">
              <span className="nav-text">With filtering</span>
            </Link>
            <Link to="/lists/resource-list/with-a-custom-empty-search-result-state">
              <span className="nav-text">
                With a custom empty search result state
              </span>
            </Link>
            <Link to="/lists/resource-list/with-item-shortcut-actions">
              <span className="nav-text">With item shortcut actions</span>
            </Link>
            <Link to="/lists/resource-list/with-persistent-item-shortcut-actions">
              <span className="nav-text">
                With persistent item shortcut actions
              </span>
            </Link>
            <Link to="/lists/resource-list/with-multiselect">
              <span className="nav-text">With multiselect</span>
            </Link>
            <Link to="/lists/resource-list/with-all-of-its-elements">
              <span className="nav-text">With all of its elements</span>
            </Link>
          </div>
        </li>

        {/* Navigation */}
        <li className="nav-main-head">Navigation</li>
        <li className="has-subnav">
          <Link to="/navigation/footer-help">
            <span className="nav-text">Footer help</span>
          </Link>
        </li>
        <li className="has-subnav dropdown">
          <a href="#" className="dropbtn">
            <span className="nav-text">Fullscreen bar</span>
          </a>
          <div className="dropdown-content">
            <Link to="/navigation/fullscreen-bar/with-children">
              <span className="nav-text">With children</span>
            </Link>
            <Link to="/navigation/fullscreen-bar/no-children">
              <span className="nav-text">No children</span>
            </Link>
          </div>
        </li>
        <li className="has-subnav dropdown">
          <a href="#" className="dropbtn">
            <span className="nav-text">Legacy tabs</span>
          </a>
          <div className="dropdown-content">
            <Link to="/navigation/legacy-tabs/default">
              <span className="nav-text">Default</span>
            </Link>
            <Link to="/navigation/legacy-tabs/fitted">
              <span className="nav-text">Fitted</span>
            </Link>
            <Link to="/navigation/legacy-tabs/with-badge-content">
              <span className="nav-text">With badge content</span>
            </Link>
            <Link to="/navigation/legacy-tabs/with-custom-disclosure">
              <span className="nav-text">With custom disclosure</span>
            </Link>
          </div>
        </li>
        <li className="has-subnav">
          <Link to="/navigation/link">
            <span className="nav-text">Link</span>
          </Link>
        </li>
        <li className="has-subnav">
          <Link to="/navigation/navigations">
            <span className="nav-text">Navigation</span>
          </Link>
        </li>
        <li className="has-subnav">
          <Link to="/navigation/pagination">
            <span className="nav-text">Pagination</span>
          </Link>
        </li>
        <li className="has-subnav dropdown">
          <a href="#" className="dropbtn">
            <span className="nav-text">Tabs</span>
          </a>
          <div className="dropdown-content">
            <Link to="/navigation/tabs/default">
              <span className="nav-text">Default</span>
            </Link>
            <Link to="/navigation/tabs/inside-of-a-Card">
              <span className="nav-text">Inside of a Card</span>
            </Link>
            <Link to="/navigation/tabs/fitted">
              <span className="nav-text">Fitted</span>
            </Link>
            <Link to="/navigation/tabs/with-actions">
              <span className="nav-text">With actions</span>
            </Link>
            <Link to="/navigation/tabs/with-badge-content">
              <span className="nav-text">With badge content</span>
            </Link>
            <Link to="/navigation/tabs/with-custom-disclosure">
              <span className="nav-text">With custom disclosure</span>
            </Link>
          </div>
        </li>
        <li className="has-subnav">
          <Link to="/navigation/top-bar">
            <span className="nav-text">Top bar</span>
          </Link>
        </li>

        {/* Overlays */}
        <li className="nav-main-head">Overlays</li>
        <li className="has-subnav dropdown">
          <a href="#" className="dropbtn">
            <span className="nav-text">Modal</span>
          </a>
          <div className="dropdown-content">
            <Link to="/overlays/modal/default">
              <span className="nav-text">Default</span>
            </Link>
            <Link to="/overlays/modal/with-primary-action">
              <span className="nav-text">With primary action</span>
            </Link>
            <Link to="/overlays/modal/with-primary-and-secondary-actions">
              <span className="nav-text">
                With primary and secondary actions
              </span>
            </Link>
            <Link to="/overlays/modal/large">
              <span className="nav-text">Large</span>
            </Link>
            <Link to="/overlays/modal/small">
              <span className="nav-text">Smalls</span>
            </Link>
            <Link to="/overlays/modal/without-a-title">
              <span className="nav-text">Without a title</span>
            </Link>
            <Link to="/overlays/modal/with-scroll-listener">
              <span className="nav-text">With scroll listener</span>
            </Link>
            <Link to="/overlays/modal/with-activator-ref">
              <span className="nav-text">With activator ref</span>
            </Link>
            <Link to="/overlays/modal/without-an-activator-prop">
              <span className="nav-text">Without an activator prop</span>
            </Link>
          </div>
        </li>
        <li className="has-subnav dropdown">
          <a href="#" className="dropbtn">
            <span className="nav-text">Popover</span>
          </a>
          <div className="dropdown-content">
            <Link to="/overlays/popover/with-action-list">
              <span className="nav-text">With action list</span>
            </Link>
            <Link to="/overlays/popover/with-content-and-actions">
              <span className="nav-text">With content and actions</span>
            </Link>
            <Link to="/overlays/popover/with-form-components">
              <span className="nav-text">With form components</span>
            </Link>
            <Link to="/overlays/popover/with-lazy-loaded-list">
              <span className="nav-text">With lazy loaded list</span>
            </Link>
            <Link to="/overlays/popover/with-searchable-listbox">
              <span className="nav-text">With searchable listbox</span>
            </Link>
          </div>
        </li>
        <li className="has-subnav">
          <Link to="/overlays/tooltip">
            <span className="nav-text">Tooltip</span>
          </Link>
        </li>

        <li className="nav-main-head">Utilities</li>
        <li className="has-subnav">
          <Link to="/utilities/app-provider">
            <span className="nav-text">App provider</span>
          </Link>
        </li>
        <li className="has-subnav">
          <Link to="/utilities/collapsible">
            <span className="nav-text">Collapsible</span>
          </Link>
        </li>
        <li className="has-subnav dropdown">
          <a href="#" className="dropbtn">
            <span className="nav-text">Frame</span>
          </a>
          <div className="dropdown-content">
            <Link to="/utilities/frame/in-an-application">
              <span className="nav-text">In an application</span>
            </Link>
            <Link to="/utilities/frame/with-an-offset">
              <span className="nav-text">With an offset</span>
            </Link>
          </div>
        </li>
        <li className="has-subnav">
          <Link to="/utilities/scrollable">
            <span className="nav-text">Scrollable</span>
          </Link>
        </li>

        {/* Deprecated */}
        <li className="nav-main-head">Deprecated</li>
        <li className="has-subnav dropdown">
          <a href="#" className="dropbtn">
            <span className="nav-text">Legacy filters</span>
          </a>
          <div className="dropdown-content">
            <Link to="/deprecated/legacy-filters/with-a-resource-list">
              <span className="nav-text">With a resource list</span>
            </Link>
            <Link to="/deprecated/legacy-filters/with-a-data-table">
              <span className="nav-text">With a data table</span>
            </Link>
            <Link to="/deprecated/legacy-filters/with-children-content">
              <span className="nav-text">With children content</span>
            </Link>
            <Link to="/deprecated/legacy-filters/disabled">
              <span className="nav-text">Disabled</span>
            </Link>
            <Link to="/deprecated/legacy-filters/some-disabled">
              <span className="nav-text">Some disabled</span>
            </Link>
            <Link to="/deprecated/legacy-filters/without-clear-button">
              <span className="nav-text">Without clear button</span>
            </Link>
            <Link to="/deprecated/legacy-filters/with-help-text">
              <span className="nav-text">With help text</span>
            </Link>
            <Link to="/deprecated/legacy-filters/with-query-field-hidden">
              <span className="nav-text">With query field hidden</span>
            </Link>
            <Link to="/deprecated/legacy-filters/with-query-field-disabled">
              <span className="nav-text">With query field disabled</span>
            </Link>
          </div>
        </li>
        <li className="has-subnav dropdown">
          <a href="#" className="dropbtn">
            <span className="nav-text">Setting toggle</span>
          </a>
          <div className="dropdown-content">
            <Link to="/deprecated/setting-toggle/with-primitive-components">
              <span className="nav-text">With primitive components</span>
            </Link>
            <Link to="/deprecated/setting-toggle/with-deprecated-component">
              <span className="nav-text">With deprecated component</span>
            </Link>
          </div>
        </li>
        <li className="has-subnav dropdown">
          <a href="#" className="dropbtn">
            <span className="nav-text">Sheet</span>
          </a>
          <div className="dropdown-content">
            <Link to="/deprecated/sheet/default">
              <span className="nav-text">Default</span>
            </Link>
            <Link to="/deprecated/sheet/with-searchable-listbox">
              <span className="nav-text">With searchable listbox</span>
            </Link>
          </div>
        </li>
        <li className="has-subnav">
          <Link to="/deprecated/text-container">
            <span className="nav-text">Text container</span>
          </Link>
        </li>
      </ul>
    </nav>
  );
}

export default Sidebar;
