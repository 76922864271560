import {LegacyCard, Page, TextField} from '@shopify/polaris';
import {useState, useCallback} from 'react';

function Number() {
  const [value, setValue] = useState('1');

  const handleChange = useCallback(
    (newValue: string) => setValue(newValue),
    [],
  );

  return (
    <Page>
        <LegacyCard sectioned>
            <TextField
                label="Quantity"
                type="number"
                value={value}
                onChange={handleChange}
                autoComplete="off"
            />
        </LegacyCard>
    </Page>
  );
}

export default Number