import { LegacyCard, OptionList, Page } from '@shopify/polaris';
import { useState } from 'react';

function OptionListEx() {
    const [selected, setSelected] = useState([]);

    return (
        <Page>
            {/* Default - do not use div tag */}
            <div>1:- Default</div>
            <LegacyCard>
                <OptionList
                    title="Inventory Location"
                    onChange={setSelected}
                    options={[
                        { value: 'byward_market', label: 'Byward Market' },
                        { value: 'centretown', label: 'Centretown' },
                        { value: 'hintonburg', label: 'Hintonburg' },
                        { value: 'westboro', label: 'Westboro' },
                        { value: 'downtown', label: 'Downtown' },
                    ]}
                    selected={selected}
                />
            </LegacyCard>


            {/* Multiple - do not use div tag */}
            <div className='margin-top'>2:- Multiple</div>
            <LegacyCard>
                <OptionList
                    title="Manage sales channels availability"
                    onChange={setSelected}
                    options={[
                        { value: 'online_store', label: 'Online Store' },
                        { value: 'messenger', label: 'Messenger' },
                        { value: 'facebook', label: 'Facebook' },
                        { value: 'wholesale', label: 'Wholesale' },
                        { value: 'buzzfeed', label: 'BuzzFeed' },
                    ]}
                    selected={selected}
                    allowMultiple
                />
            </LegacyCard>


            {/* With sections - do not use div tag */}
            <div className='margin-top'>3:- With sections</div>
            <LegacyCard>
                <OptionList
                    onChange={setSelected}
                    sections={[
                        {
                            options: [
                                { value: 'type', label: 'Sale item type' },
                                { value: 'kind', label: 'Sale kind' },
                            ],
                        },
                        {
                            title: 'Traffic',
                            options: [
                                { value: 'source', label: 'Traffic referrer source' },
                                { value: 'host', label: 'Traffic referrer host' },
                                { value: 'path', label: 'Traffic referrer path' },
                            ],
                        },
                    ]}
                    selected={selected}
                    allowMultiple
                />
            </LegacyCard>
        </Page>
    );
}

export default OptionListEx