import {FullscreenBar, Button, Text} from '@shopify/polaris';
import {useState, useCallback} from 'react';

function FullscreenBarNoChildren() {
    const [isFullscreen, setFullscreen] = useState(true);

    const handleActionClick = useCallback(() => {
      setFullscreen(false);
    }, []);
  
    const fullscreenBarMarkup = <FullscreenBar onAction={handleActionClick} />;
  
    return (
      <div style={{height: '250px', width: '100%'}}>
        {isFullscreen && fullscreenBarMarkup}
        <div style={{padding: '1rem'}}>
          {!isFullscreen && (
            <Button onClick={() => setFullscreen(true)}>Go Fullscreen</Button>
          )}
          <Text variant="headingLg" as="p">
            Page content
          </Text>
        </div>
      </div>
    );
}

export default FullscreenBarNoChildren
