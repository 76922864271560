import { LegacyCard, TextField, Page } from '@shopify/polaris';
import { useState, useCallback } from 'react';

function WithPrefixOrSuffix() {
    const [textFieldValue, setTextFieldValue] = useState('2.00');

    const handleTextFieldChange = useCallback(
        (value: string) => setTextFieldValue(value),
        [],
    );

    return (
        <Page>
            <LegacyCard sectioned>
                <TextField
                    label="Price"
                    type="number"
                    value={textFieldValue}
                    onChange={handleTextFieldChange}
                    prefix="$"
                    autoComplete="off"
                />
            </LegacyCard>
        </Page>
    );
}

export default WithPrefixOrSuffix