import {InlineError, LegacyCard, Page} from '@shopify/polaris';
import React from 'react';

function InlineErrorDefault() {
  return (
    <Page>
        <LegacyCard sectioned>
            <InlineError message="Store name is required" fieldID="myFieldID" />
        </LegacyCard>
    </Page>
  );
}

export default InlineErrorDefault